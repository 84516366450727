import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';

import { store } from '../../store/DispatcherStore';

import OpenStreetmap from './OpenStreetmap';
import SystemPanel from './SystemPanel';
import GeoPanel from './GeoPanel';
import DispatcherNavbar from './DispatcherNavbar';
import VideoContainerDispatcher from '../Video/VideoContainerDispatcher';
import Snapshots from '../Dispatcher/Snapshots';
import GridBox from '../Globals/GridBox';
import WebRTCSwitcher from './WebRTCSwitcher';
import ChatViewDispatcher from '../Chat/ChatViewDispatcher';
import CameraChooser from './CameraChooser';
import SystemFooter from '../Globals/SystemFooter';

import './DispatcherDashboard.scss';
import { addLogDispatch } from '../../redux/actions/logs';
import {
    deactivateVideoDispatcherDispatch,
    deactivateChatDispatcherDispatch,
    deactivateGPSDispatch,
    deactivateSnapshotDispatch,
    disableSnapshotDispatch,
    disablePointerDispatch,
    deactivatePointerDispatcherDispatch,
    deactivateHDSendDispatch,
    deactivateDrawDispatcherDispatch,
    disableDrawDispatch,
} from '../../redux/actions/application';

import { DEBUG } from '../../config';
import { replaceText, setDispatcherLanguage, setWhiteLabelLogo } from '../../helper/helper';
import { SMSGuide } from './SMSGuide';
import TimeoutOverlay from './TimeoutOverlay';
import InvitationPanel from './InvitationPanel';
import ConferenceControls from './ConferenceControls';
import AudioPanel from './AudioPanel';
import { setIsDispatcherDispatch } from '../../redux/actions/conferencing';
import BidiPanel from './BidiPanel';
import NotesPanel from './NotesPanel';
import ExternalStreamPanel from './ExternalStreamPanel';

/**
 * Dispatcher Dashboard
 * main application hub for the dispatcher.
 * Provides access to all major functions for the dispatcher.
 *
 * @component OpenStreetmap - show the map
 * @component SystemPanel - shows the system infos of the bystander
 * @component GeoPanel - shows the geolocation infos of the bystander
 * @component DispatcherNavbar - Basic navigation bar with logo, state, session end
 * @component VideoContainerDispatcher - shows the video
 * @component Snapshots - take and view snapshots
 * @component Gridbox - Container open/close in the grid
 * @component WebRTCSwitcher - Switches for functions on the dashboard
 * @component ChatViewDispatcher - Shows the chat
 * @component CameraChooser - dropdown for the available cameras
 * @component SystemFooter - shows health, version and helpdesk
 */

class DispatcherDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            droneFeature: true,
        };

        this.systemPanelRef = React.createRef();
        this.mapRef = React.createRef();
    }

    initConnection() {
        store.initWebRTC(this.onInit);
    }

    onInit = () => {
        if (this.props.videoIsActive) deactivateVideoDispatcherDispatch();
        if (this.props.chatIsActive) deactivateChatDispatcherDispatch();
        if (this.props.gpsIsActive) deactivateGPSDispatch();
        if (this.props.hdSendIsActive) deactivateHDSendDispatch();
        if (this.props.snapshotIsActive) deactivateSnapshotDispatch();
        if (this.props.snapshotFeature) disableSnapshotDispatch();
        if (this.props.pointerFeature) disablePointerDispatch();
        if (this.props.pointerIsActive) deactivatePointerDispatcherDispatch();
        if (this.props.drawIsActive) deactivateDrawDispatcherDispatch();
        if (this.props.drawFeature) disableDrawDispatch();
        setIsDispatcherDispatch();
        setWhiteLabelLogo();
        setDispatcherLanguage();
    };

    languageChange = languageLabel => {
        this.systemPanelRef.current.updateLanguage(languageLabel);
    };

    mapResize = isClosed => {
        if (!isClosed && this.mapRef.current) {
            this.mapRef.current.resize();
        }
    };

    _keys = {};

    addKey = e => {
        if (e.keyCode === 116 || e.keyCode === 82 || e.keyCode === 17 || e.keyCode === 91) {
            this._keys[e.keyCode] = true;

            // F5
            // CTRL + R
            // COMMAND + R
            if (this._keys[116] || (this._keys[82] && this._keys[17]) || (this._keys[82] && this._keys[91])) {
                e.preventDefault();
                e.stopPropagation();
                Object.keys(this._keys).forEach(key => {
                    this._keys[key] = false;
                });
                alert(replaceText(this.props.texts, 'system.alertRefresh'));
            }
        }
    };

    removeKey = e => {
        if (e.keyCode === 116 || e.keyCode === 82 || e.keyCode === 17 || e.keyCode === 91) {
            this._keys[e.keyCode] = false;
            if (DEBUG) addLogDispatch(['keys', this._keys]);
        }
    };

    /* lifecycle */

    componentDidMount() {
        window.addEventListener('keydown', this.addKey);
        window.addEventListener('keyup', this.removeKey);
        this.initConnection();
    }

    componentWillUnmount() {
        store.clearCallCallbacks();
        window.removeEventListener('keydown', this.addKey);
        window.removeEventListener('keyup', this.removeKey);
    }

    render() {
        const classes = clsx('r', {
            'r--chat': this.props.chatFeature,
        });

        let dispatcherDashboardClasses = `dispatcherDashboard ${this.props.screenSharingIsActive ? 'dispatcherDashboard--isScreenSharing' : ''}`;

        return (
            <React.Fragment>
                {this.props.conferencingIsActive && <ConferenceControls />}

                <div className={dispatcherDashboardClasses}>
                    <DispatcherNavbar callerId={this.props.match.params.id} />

                    <div className="grid">
                        <div className={classes}>
                            <div className="c">
                                <GridBox isContainer={true} addClass="rtcSwitchBox" headline={replaceText(this.props.texts, 'info.function')}>
                                    <WebRTCSwitcher />
                                </GridBox>
                                <GridBox
                                    isClosed={true}
                                    isContainer={true}
                                    openOnFeatureActivation={this.props.videoIsActive}
                                    addClass="cameraChooser"
                                    headline={replaceText(this.props.texts, 'cameradropdown.title')}>
                                    <CameraChooser />
                                </GridBox>
                                {this.props.audioStreamFeature && (
                                    <GridBox
                                        isClosed={true}
                                        isContainer={true}
                                        openOnFeatureActivation={this.props.audioStreamIsActive}
                                        addClass="audioBox"
                                        headline={replaceText(this.props.texts, 'calleraudio.title')}>
                                        <AudioPanel />
                                    </GridBox>
                                )}
                                {this.props.sharingFeature && (
                                    <GridBox
                                        addClass="infoBox"
                                        isClosed={true}
                                        isContainer={true}
                                        openOnFeatureActivation={this.props.conferencingIsActive}
                                        headline={replaceText(this.props.texts, 'invitation.title')}>
                                        <InvitationPanel />
                                    </GridBox>
                                )}
                                <GridBox addClass="infoBox" isContainer={true} headline={replaceText(this.props.texts, 'info.system')}>
                                    <SystemPanel />
                                </GridBox>
                                <GridBox
                                    addClass="infoBox"
                                    isClosed={true}
                                    openOnFeatureActivation={this.props.gpsIsActive}
                                    isContainer={true}
                                    headline={replaceText(this.props.texts, 'info.position')}>
                                    <GeoPanel />
                                </GridBox>
                            </div>
                            <div className="c">
                                <GridBox
                                    isContainer={true}
                                    isClosed={true}
                                    openOnFeatureActivation={this.props.gpsIsActive}
                                    addClass="mapBox"
                                    headline={replaceText(this.props.texts, 'info.map')}>
                                    <OpenStreetmap />
                                </GridBox>
                                {this.props.externalStreamingFeature && (
                                    <GridBox
                                        isContainer={true}
                                        isClosed={true}
                                        openOnFeatureActivation={this.props.externalStreamIsActive}
                                        addClass="externalStreamBox"
                                        headline={replaceText(this.props.texts, 'toggle.externalStream')}>
                                        <ExternalStreamPanel />
                                    </GridBox>
                                )}
                                <GridBox
                                    isContainer={true}
                                    alwaysOpened={this.props.drawIsActive}
                                    addClass="videoBox"
                                    headline={replaceText(this.props.texts, 'info.video')}>
                                    <VideoContainerDispatcher />
                                </GridBox>
                                {(this.props.snapshotFeature || this.props.hdSendFeature) && (
                                    <GridBox
                                        isClosed={true}
                                        isContainer={true}
                                        openOnFeatureActivation={this.props.snapshotIsActive || this.props.drawIsActive}
                                        addClass="photoBox"
                                        headline={replaceText(this.props.texts, 'snapshot.boxHeader')}>
                                        <Snapshots />
                                    </GridBox>
                                )}
                            </div>
                            <div className="c">
                                {this.props.bidiFeature && (
                                    <GridBox
                                        isContainer={true}
                                        addClass="bidiBox"
                                        isClosed={true}
                                        openOnFeatureActivation={this.props.bidiIsActive}
                                        headline={replaceText(this.props.texts, 'info.bidi')}>
                                        <BidiPanel />
                                    </GridBox>
                                )}
                                {this.props.notesFeature && (
                                    <GridBox isContainer={true} addClass="noteBox" isClosed={true} headline={replaceText(this.props.texts, 'info.notes')}>
                                        <NotesPanel />
                                    </GridBox>
                                )}
                                {this.props.chatFeature && (
                                    <GridBox isContainer={true} addClass="chatBox" headline={replaceText(this.props.texts, 'info.chat')}>
                                        <ChatViewDispatcher
                                            handleMessageSent={this.activateChat}
                                            handleLanguageChange={this.languageChange}
                                            ref={this.chatRef}
                                        />
                                    </GridBox>
                                )}
                            </div>
                        </div>
                    </div>
                    <SystemFooter />
                    {this.props.smsGuideFeature && <SMSGuide />}
                    <TimeoutOverlay />
                    <div
                        id="incomingStreamToDispatcher"
                        style={{
                            display: 'none',
                            position: 'absolute',
                        }}></div>
                </div>
            </React.Fragment>
        );
    }
}

// PropTypes for this Component
DispatcherDashboard.propTypes = {
    match: PropTypes.any,
    snapshotFeature: PropTypes.bool,
    hdSendFeature: PropTypes.bool,
    chatFeature: PropTypes.bool,
    smsGuideFeature: PropTypes.bool,
    pointerFeature: PropTypes.bool,
    externalStreamingFeature: PropTypes.bool,
    texts: PropTypes.any,
    osName: PropTypes.string,
    gpsIsActive: PropTypes.bool,
    drawIsActive: PropTypes.bool,
    conferencingIsActive: PropTypes.bool,
    videoIsActive: PropTypes.bool,
    screenSharingIsActive: PropTypes.bool,
    audioStreamIsActive: PropTypes.bool,
    snapshotIsActive: PropTypes.bool,
    audioStreamFeature: PropTypes.bool,
    sharingFeature: PropTypes.bool,
    chatIsActive: PropTypes.bool,
    hdSendIsActive: PropTypes.bool,
    pointerIsActive: PropTypes.bool,
    drawFeature: PropTypes.bool,
    bidiFeature: PropTypes.bool,
    bidiIsActive: PropTypes.bool,
    notesFeature: PropTypes.bool,
    externalStreamIsActive: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        snapshotFeature: state.features.snapshotFeature,
        hdSendFeature: state.features.hdSendFeature,
        chatFeature: state.features.chatFeature,
        texts: state.texts.texts,
        smsGuideFeature: state.features.smsGuideFeature,
        pointerFeature: state.features.pointerFeature,
        drawFeature: state.features.drawFeature,
        externalStreamingFeature: state.features.externalStreamingFeature,
        osName: state.session.osName,
        gpsIsActive: state.application.gpsIsActive,
        drawIsActive: state.application.drawIsActive,
        conferencingIsActive: state.application.conferencingIsActive,
        videoIsActive: state.application.videoIsActive,
        chatIsActive: state.application.chatIsActive,
        hdSendIsActive: state.application.hdSendIsActive,
        pointerIsActive: state.application.pointerIsActive,
        screenSharingIsActive: state.conferencing.screenSharingIsActive,
        audioStreamIsActive: state.application.audioStreamIsActive,
        snapshotIsActive: state.application.snapshotIsActive,
        audioStreamFeature: state.features.audioStreamFeature,
        sharingFeature: state.features.sharingFeature,
        bidiFeature: state.features.bidiFeature,
        bidiIsActive: state.application.bidiIsActive,
        notesFeature: state.features.notesFeature,
        externalStreamIsActive: state.application.externalStreamIsActive,
    };
};

export default connect(mapStateToProps)(DispatcherDashboard);
