import React from 'react';

function Send() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg">
            <defs />
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
        </svg>
    );
}

export default Send;
