import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './ExternalStreamPanel.scss';
import { HLSPlayer } from './HLSPlayer';
import NoVideo from '../Icons/NoVideo';

const ExternalStreamPanel = ({ externalStreamIsActive, externalStreamUrl }) => {    
    const hlsStreamSource = externalStreamUrl;

    return (
        <div className="drone-panel">
            {!externalStreamIsActive ? (
                <div className="drone-panel__placeholder"><NoVideo /></div>
            ) : (
                <div>
                    {hlsStreamSource !== null && (<HLSPlayer source={hlsStreamSource} />)}                
                </div>
            )}
        </div>
    );
};

// PropTypes for this Component
ExternalStreamPanel.propTypes = {
    externalStreamIsActive: PropTypes.bool,
    externalStreamUrl: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        externalStreamIsActive: state.application.externalStreamIsActive,
        externalStreamUrl: state.streams.externalStreamUrl,
    };
};

export default connect(mapStateToProps)(ExternalStreamPanel);
