import { combineReducers } from 'redux';

import notifications from './notifications';
import logs from './logs';
import texts from './texts';
import application from './application';
import features from './features';
import connection from './connection';
import session from './session';
import phonenumbers from './phonenumbers';
import paint from './paint';
import conferencing from './conferencing';
import streams from './streams';
import permissions from './permissions';
import disclaimers from './disclaimers';

/**
 * combined root reducer
 * see https://redux.js.org/
 */

export default combineReducers({
    notifications,
    logs,
    texts,
    application,
    features,
    connection,
    session,
    phonenumbers,
    paint,
    conferencing,
    streams,
    permissions,
    disclaimers,
});
