import {
    ACTIVATE_CHAT,
    DEACTIVATE_CHAT,
    DISABLE_CHAT,
    ENABLE_CHAT,
    ACTIVATE_GPS,
    DISABLE_GPS,
    ENABLE_GPS,
    DEACTIVATE_GPS,
    ACTIVATE_VIDEO,
    DISABLE_VIDEO,
    ENABLE_VIDEO,
    DEACTIVATE_VIDEO,
    DEACTIVATE_SNAPSHOT,
    ACTIVATE_SNAPSHOT,
    ENABLE_SNAPSHOT,
    DISABLE_SNAPSHOT,
    DEACTIVATE_POINTER,
    ACTIVATE_POINTER,
    ENABLE_POINTER,
    DISABLE_POINTER,
    CALLER_CHAT_SHOW,
    CALLER_VIDEO_ACTIVATE_FULLSCREEN,
    CALLER_VIDEO_DEACTIVATE_FULLSCREEN,
    CALLER_CHAT_HIDE,
    ADD_VIDEO_STREAM,
    REMOVE_VIDEO_STREAM,
    RESET_ALL,
    ADD_MEDIA_DEVICES,
    CHANGE_DEVICE_ID,
    REMOVE_DEVICE_ID,
    SHOW_REFRESH,
    PAUSE_SNAPSHOT,
    UNPAUSE_SNAPSHOT,
    DEACTIVATE_HD_SEND,
    ACTIVATE_HD_SEND,
    CALLER_FILE_TRANSFER_STARTED,
    CALLER_FILE_TRANSFER_ENDED,
    CALLER_FILE_IS_BUSY,
    CALLER_FILE_IS_NOT_BUSY,
    ENABLE_DRAW,
    DISABLE_DRAW,
    DEACTIVATE_DRAW,
    ACTIVATE_DRAW,
    ACTIVATE_CONFERENCING,
    DEACTIVATE_CONFERENCING,
    ENABLE_CONFERENCING,
    DISABLE_CONFERENCING,
    ENABLE_AUDIO_STREAM,
    DISABLE_AUDIO_STREAM,
    DEACTIVATE_AUDIO_STREAM,
    ACTIVATE_AUDIO_STREAM,
    ENABLE_BIDI,
    DISABLE_BIDI,
    ACTIVATE_BIDI,
    DEACTIVATE_BIDI,
    ACTIVATE_BIDI_BLUR,
    DEACTIVATE_BIDI_BLUR,
    UNMUTE_AUDIO,
    MUTE_AUDIO,
    MUTE_CALLER_MIC,
    UNMUTE_CALLER_MIC,
    CALLER_PAGE_LOADED,
    CALLER_PAGE_NOT_LOADED,
    CALLER_END_PAGE_LOADED,
    CALLER_END_PAGE_NOT_LOADED,
    ACTIVATE_NOTES,
    DEACTIVATE_NOTES,
    ACTIVATE_EXTERNAL_STREAM,
    DEACTIVATE_EXTERNAL_STREAM,
} from '../actions/actionTypes';

export const initialState = {
    videoIsActive: false,
    gpsIsActive: false,
    snapshotIsActive: false,
    pointerIsActive: false,
    videoIsDisabled: false,
    gpsIsDisabled: false,
    snapshotIsDisabled: false,
    snapshotPaused: false,
    wasChatActiveBefore: false,
    chatIsActive: false,
    chatIsDisabled: false,
    pointerIsDisabled: false,
    hdSendIsActive: false,
    callerChatIsShown: false,
    callerVideoIsFullscreen: true,
    hasVideoStream: false,
    refreshButtonShown: false,
    callerFileIsBusy: false,
    callerFileTransfering: false,
    drawIsActive: false,
    drawIsDisabled: false,
    conferencingIsActive: false,
    conferencingIsDisabled: false,
    audioStreamIsActive: false,
    audioStreamIsDisabled: false,
    audioIsMuted: false,
    bidiIsActive: false,
    bidiIsDisabled: false,
    bidiBlurIsActive: false,
    externalStreamIsActive: false,
    callerMicIsMuted: false,
    callerPageLoaded: false,
    callerEndPageLoaded: false,
    notesIsActive: false,
    deviceId: null,
    devices: [],
};

/**
 * application reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const applicationReducer = (state = initialState, action) => {
    if (action.type === ENABLE_CHAT) {
        state = { ...state, chatIsDisabled: false };
    }

    if (action.type === DISABLE_CHAT) {
        state = { ...state, chatIsDisabled: true };
    }

    if (action.type === DEACTIVATE_CHAT) {
        state = {
            ...state,
            chatIsActive: false,
            wasChatActiveBefore: state.chatIsActive,
        };
    }

    if (action.type === ACTIVATE_CHAT) {
        state = { ...state, chatIsActive: true };
    }

    if (action.type === ENABLE_VIDEO) {
        state = { ...state, videoIsDisabled: false };
    }

    if (action.type === DISABLE_VIDEO) {
        state = { ...state, videoIsDisabled: true };
    }

    if (action.type === DEACTIVATE_VIDEO) {
        state = { ...state, videoIsActive: false };
    }

    if (action.type === ACTIVATE_VIDEO) {
        state = { ...state, videoIsActive: true };
    }

    if (action.type === ENABLE_AUDIO_STREAM) {
        state = { ...state, audioStreamIsDisabled: false };
    }

    if (action.type === DISABLE_AUDIO_STREAM) {
        state = { ...state, audioStreamIsDisabled: true };
    }

    if (action.type === DEACTIVATE_AUDIO_STREAM) {
        state = { ...state, audioStreamIsActive: false };
    }

    if (action.type === ACTIVATE_AUDIO_STREAM) {
        state = { ...state, audioStreamIsActive: true };
    }

    if (action.type === MUTE_AUDIO) {
        state = { ...state, audioIsMuted: true };
    }

    if (action.type === UNMUTE_AUDIO) {
        state = { ...state, audioIsMuted: false };
    }

    if (action.type === MUTE_CALLER_MIC) {
        state = { ...state, callerMicIsMuted: true };
    }

    if (action.type === UNMUTE_CALLER_MIC) {
        state = { ...state, callerMicIsMuted: false };
    }

    if (action.type === ENABLE_GPS) {
        state = { ...state, gpsIsDisabled: false };
    }

    if (action.type === DISABLE_GPS) {
        state = { ...state, gpsIsDisabled: true };
    }

    if (action.type === DEACTIVATE_GPS) {
        state = { ...state, gpsIsActive: false };
    }

    if (action.type === ACTIVATE_GPS) {
        state = { ...state, gpsIsActive: true };
    }

    if (action.type === ENABLE_SNAPSHOT) {
        state = { ...state, snapshotIsDisabled: false };
    }

    if (action.type === DISABLE_SNAPSHOT) {
        state = { ...state, snapshotIsDisabled: true };
    }

    if (action.type === DEACTIVATE_SNAPSHOT) {
        state = { ...state, snapshotIsActive: false };
    }

    if (action.type === ACTIVATE_SNAPSHOT) {
        state = { ...state, snapshotIsActive: true };
    }

    if (action.type === PAUSE_SNAPSHOT) {
        state = { ...state, snapshotPaused: true };
    }

    if (action.type === UNPAUSE_SNAPSHOT) {
        state = { ...state, snapshotPaused: false };
    }

    if (action.type === ENABLE_POINTER) {
        state = { ...state, pointerIsDisabled: false };
    }

    if (action.type === DISABLE_POINTER) {
        state = { ...state, pointerIsDisabled: true };
    }

    if (action.type === DEACTIVATE_POINTER) {
        state = { ...state, pointerIsActive: false };
    }

    if (action.type === ACTIVATE_POINTER) {
        state = { ...state, pointerIsActive: true };
    }

    if (action.type === ENABLE_DRAW) {
        state = { ...state, drawIsDisabled: false };
    }

    if (action.type === DISABLE_DRAW) {
        state = { ...state, drawIsDisabled: true };
    }

    if (action.type === DEACTIVATE_DRAW) {
        state = { ...state, drawIsActive: false };
    }

    if (action.type === ACTIVATE_DRAW) {
        state = { ...state, drawIsActive: true };
    }

    if (action.type === ENABLE_CONFERENCING) {
        state = { ...state, conferencingIsDisabled: false };
    }

    if (action.type === DISABLE_CONFERENCING) {
        state = { ...state, conferencingIsDisabled: true };
    }

    if (action.type === DEACTIVATE_CONFERENCING) {
        state = { ...state, conferencingIsActive: false };
    }

    if (action.type === ACTIVATE_CONFERENCING) {
        state = { ...state, conferencingIsActive: true };
    }

    if (action.type === ENABLE_BIDI) {
        state = { ...state, bidiIsDisabled: false };
    }

    if (action.type === DISABLE_BIDI) {
        state = { ...state, bidiIsDisabled: true };
    }

    if (action.type === ACTIVATE_BIDI) {
        state = { ...state, bidiIsActive: true };
    }

    if (action.type === DEACTIVATE_BIDI) {
        state = { ...state, bidiIsActive: false };
    }

    if (action.type === ACTIVATE_BIDI_BLUR) {
        state = { ...state, bidiBlurIsActive: true };
    }

    if (action.type === DEACTIVATE_BIDI_BLUR) {
        state = { ...state, bidiBlurIsActive: false };
    }

    if (action.type === DEACTIVATE_HD_SEND) {
        state = { ...state, hdSendIsActive: false };
    }

    if (action.type === ACTIVATE_HD_SEND) {
        state = { ...state, hdSendIsActive: true };
    }

    if (action.type === CALLER_VIDEO_ACTIVATE_FULLSCREEN) {
        state = { ...state, callerVideoIsFullscreen: true };
    }

    if (action.type === CALLER_VIDEO_DEACTIVATE_FULLSCREEN) {
        state = { ...state, callerVideoIsFullscreen: false };
    }

    if (action.type === CALLER_CHAT_SHOW) {
        state = { ...state, callerChatIsShown: true };
    }

    if (action.type === CALLER_CHAT_HIDE) {
        state = { ...state, callerChatIsShown: false };
    }

    if (action.type === ADD_VIDEO_STREAM) {
        state = { ...state, hasVideoStream: true };
    }

    if (action.type === REMOVE_VIDEO_STREAM) {
        state = { ...state, hasVideoStream: false };
    }

    if (action.type === ADD_MEDIA_DEVICES) {
        state = {
            ...state,
            devices: [...action.devices],
        };
    }

    if (action.type === CHANGE_DEVICE_ID) {
        state = {
            ...state,
            deviceId: action.deviceId,
        };
    }

    if (action.type === REMOVE_DEVICE_ID) {
        state = {
            ...state,
            deviceId: null,
        };
    }

    if (action.type === SHOW_REFRESH) {
        state = {
            ...state,
            refreshButtonShown: true,
        };
    }

    if (action.type === CALLER_FILE_IS_BUSY) {
        state = { ...state, callerFileIsBusy: true };
    }

    if (action.type === CALLER_FILE_IS_NOT_BUSY) {
        state = { ...state, callerFileIsBusy: false };
    }

    if (action.type === CALLER_FILE_TRANSFER_STARTED) {
        state = { ...state, callerFileTransfering: true };
    }

    if (action.type === CALLER_FILE_TRANSFER_ENDED) {
        state = { ...state, callerFileTransfering: false };
    }

    if (action.type === CALLER_PAGE_LOADED) {
        state = { ...state, callerPageLoaded: true };
    }

    if (action.type === CALLER_PAGE_NOT_LOADED) {
        state = { ...state, callerPageLoaded: false };
    }

    if (action.type === CALLER_END_PAGE_LOADED) {
        state = { ...state, callerEndPageLoaded: true };
    }

    if (action.type === CALLER_END_PAGE_NOT_LOADED) {
        state = { ...state, callerEndPageLoaded: false };
    }

    if (action.type === ACTIVATE_NOTES) {
        state = { ...state, notesIsActive: true };
    }

    if (action.type === DEACTIVATE_NOTES) {
        state = { ...state, notesIsActive: false };
    }

    if (action.type === ACTIVATE_EXTERNAL_STREAM) {
        state = { ...state, externalStreamIsActive: true };
    }

    if (action.type === DEACTIVATE_EXTERNAL_STREAM) {
        state = { ...state, externalStreamIsActive: false };
    }

    if (action.type === RESET_ALL) {
        state = { ...state, ...initialState };
    }

    return state;
};

export default applicationReducer;
