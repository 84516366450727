import {
    SET_DATA_DISCLAIMER_ACCEPTED,
    SET_DATA_DISCLAIMER_NOT_ACCEPTED,
    SET_HD_PHOTO_DISCLAIMER_CLICKED,
    SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED,
    SET_SNAPSHOT_DISCLAIMER_ACCEPTED,
    SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED,
} from '../actions/disclaimers';

export const initialState = {
    dataDisclaimerAccepted: null,
    snapshotDisclaimerAccepted: null,
    hdPhotoDisclaimerClicked: null,
};

const disclaimerReducer = (state = initialState, action) => {
    if (action.type === SET_DATA_DISCLAIMER_ACCEPTED) {
        return { ...state, dataDisclaimerAccepted: true };
    }

    if (action.type === SET_DATA_DISCLAIMER_NOT_ACCEPTED) {
        return { ...state, dataDisclaimerAccepted: false };
    }

    if (action.type === SET_SNAPSHOT_DISCLAIMER_ACCEPTED) {
        return { ...state, snapshotDisclaimerAccepted: true };
    }

    if (action.type === SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED) {
        return { ...state, snapshotDisclaimerAccepted: false };
    }

    if (action.type === SET_HD_PHOTO_DISCLAIMER_CLICKED) {
        return { ...state, hdPhotoDisclaimerClicked: true };
    }

    if (action.type === SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED) {
        return { ...state, hdPhotoDisclaimerClicked: false };
    }

    return state;
};

export default disclaimerReducer;
