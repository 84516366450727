import parse from 'platform';

import {
    DEBUG,
    SESSION_END_TIMEOUT,
    DEVICE_INFO_INTERVAL,
    BATTERY_INFO_INTERVAL,
    PING_INTERVAL,
    CALLER_THRESHOLD,
    RESET_TOGGLE_THRESHOLD,
    C_LOST_DURATION,
} from '../config';
import { errorLog } from '../helper/logging';
import { getSessionEndMessages, getAuthtoken, getDispatchCenterImage, getApizeeKey } from '../api/backendApi';
import { addLogDispatch } from '../redux/actions/logs';
import {
    activateVideoCallerDispatch,
    deactivateVideoCallerDispatch,
    activateCallerChat,
    deactivateCallerChat,
    showRefreshDispatch,
    activateHDSendCallerDispatch,
    deactivateHDSendCallerDispatch,
    dispatchCallerFileTransferEnded,
    dispatchCallerFileTransferStarted,
    activateDrawCallerDispatch,
    deactivateDrawCallerDispatch,
    activateAudioStreamCallerDispatch,
    deactivateAudioStreamCallerDispatch,
    unmuteAudioCallerDispatch,
    muteAudioCallerDispatch,
    dispatchCallerPageLoaded,
    deactivateBidiCallerDispatch,
} from '../redux/actions/application';
import { startGPSInterval, clearGPSInterval } from '../api/geplocationApi';
import { conversationErrorHandling, sessionErrorHandling } from '../helper/rtcErrorHandling';
import { connectionEstablishedDispatch, connectionLostDispatch, connectionStableDispatch, connectionUnstableDispatch } from '../redux/actions/connection';
import { hideAndRemoveNotificationsDispatch } from '../redux/actions/notifications';
import {
    dispatchAddPointsDispatcher,
    dispatchAllowPainting,
    dispatchDeletePaint,
    dispatchDisallowPainting,
    dispatchUndoLastPaint,
} from '../redux/actions/paint';
import { createUserDisplayName, enterConversation, getURLParams, loadEventListenersCaller, unpublishStreamAndRemoveFromRedux } from '../helper/rtcFlowHandling';
import { ONLY_AUDIO, ONLY_VIDEO } from '../redux/reducers/streams';
import {
    dispatchAddCallerAudioStream,
    dispatchRemoveCallerAudioStream,
    dispatchRemoveCallerStream,
    dispatchRemoveDispatcherBidiStream,
} from '../redux/actions/stream';
import reduxStore from '../redux/store';
import { muteMicCallerDispatch, unmuteMicCallerDispatch } from '../redux/actions/conferencing';

/*globals apiRTC*/

/**
 * CallerStore
 * contains all the functions needed to setup the caller webrtc connection and communication
 */

class CallerStore {
    authToken = null;
    messages = [];
    mediaDevices = [];
    userAgent = null;
    bystander = null;
    call = null;
    connectedSession = null;
    localStream = null;
    sessionId = null;
    bystanderId = null;
    currentLat = 0;
    currentLong = 0;
    closeCallCallbacks = [];
    newCallCallbacks = [];
    useGPS = false;
    useVideo = false;
    useChat = false;
    useSnapshot = false;
    photoPermission = false;
    cameraId = null;
    sessionEndMessages = [];
    imageLogo = null;
    gpsTimeout = null;
    batteryInterval = null;
    mediaInterval = null;
    acceptTimeout = null;
    cams = null;
    type = 'caller';
    isIOS = null;
    isIOsFirefox = null;
    isAndroid = null;
    isFirefox = null;
    osVersions = [];
    heartbeatActive = false;
    checkHeartbeatInterval = null;
    lastPing = null;

    // Conference related variables

    connectedConversation = null;
    conversationName = null;
    callerId = null;
    ongoingCall = null;

    audioStream = null;
    retryStream = 1;

    /**
     * handles the request token generation
     */
    async requestToken() {
        const { sessionId } = getURLParams();

        if (!this.authToken) {
            this.authToken = await getAuthtoken(sessionId);
            if (this.authToken) {
                // all fine
            } else {
                errorLog({
                    sessionId: sessionId,
                    message: `Error getting Authtoken`,
                    error: { stack: 'no authtoken available' },
                    eventId: 'ERROR_AUTHTOKEN',
                });
                throw new Error(`Error getting Authtoken`);
            }
        }
    }

    /**
     * get the current system version from the useragent
     */
    getSystemVersion() {
        const infos = parse.parse(navigator.userAgent);
        this.isIOS = infos.os.toString().toLowerCase().indexOf('ios') !== -1;
        this.isAndroid = infos.os.toString().toLowerCase().indexOf('android') !== -1;
        this.isIOsFirefox = navigator.userAgent.match('FxiOS');
        this.isFirefox = navigator.userAgent.match('firefox');
        if (this.isIOS) {
            this.osVersions = [
                parseInt(infos.os.version.split('.')[0], 10),
                parseInt(infos.os.version.split('.')[1], 10),
                parseInt(infos.os.version.split('.')[2], 10),
            ];
        }
    }

    // no need to test
    /**
     * initalizes the webrtc connection and then calls the callbacks
     * @param {function} initCallback
     * @param {function} errorCallback
     */
    initConnectionThen(initCallback, errorCallback) {
        this.requestToken()
            .then(async () => {
                this.sessionEndMessages = await getSessionEndMessages(this.authToken);
                this.imageLogo = await getDispatchCenterImage({ type: 'blob' });
                this.registerUser(initCallback);
            })
            .catch(error => {
                errorCallback(error);
            });
    }

    // no need to test external functionality
    /**
     * register the current useragent with apiRTC, then call the callback
     * @param {function} initCallback
     */
    async registerUser(initCallback) {
        const apiKey = await getApizeeKey(this.type);
        this.userAgent = new apiRTC.UserAgent({
            uri: 'apzkey:' + apiKey,
        });

        this.userAgent
            .register({
                cloudUrl: 'https://hds.apizee.com',
                token: this.authToken,
                id: this.sessionId,
            })
            .then(session => {
                if (DEBUG) addLogDispatch(['user agent registered session', session.id]);
                sessionErrorHandling(session, this);
                this.connectedSession = session;
                initCallback();
            });
    }

    // no need to test external functionality
    /**
     * initializes the webrtc connection then calls the callbacks
     * @param {object} callbacks
     */
    async initWebRTCThen({ connectCallback, disconnectCallback, snapshotToggleCallback }) {
        this.getSystemVersion();
        await this.handleInitSession({
            snapshotToggleCallback,
        }).catch(e => {
            if (DEBUG) addLogDispatch(['callBystanderError', e]);
        });

        if (this.connectedConversation !== null) {
            hideAndRemoveNotificationsDispatch();

            connectCallback();

            conversationErrorHandling(this.connectedConversation, callerStore);

            // make this globally available
            this.disconnectCallback = disconnectCallback;

            this.checkHeartbeatInterval = setInterval(() => {
                this.checkHeartbeat();
            }, PING_INTERVAL);

            this.acceptTimeout = setTimeout(() => {
                disconnectCallback();
            }, SESSION_END_TIMEOUT);
        } else {
            if (DEBUG) addLogDispatch(['Cannot establish call']);
        }
    }

    /**
     * call the bystander then call the callback
     * @param {object} callback
     */
    async handleInitSession({ snapshotToggleCallback }) {
        const { callerId } = getURLParams();
        this.dispatcher = this.connectedSession.getOrCreateContact(callerId);

        this.joinConversation();

        // TODO: Add necessary conversation related functions here

        // session message eventlisteners
        this.connectedSession.on('contactMessage', e => {
            if (e.sender === this.dispatcher) {
                const message = JSON.parse(e.content);
                if (message.data === 'toggleGPS') {
                    this.useGPS = message.state;
                    this.sendCoords();
                }
                if (message.data === 'toggleVideo') {
                    this.useVideo = message.state;
                    this.cameraId = message.id;

                    if (this.useVideo) {
                        // Release old stream if it exists
                        if (this.localStream !== null) {
                            this.ongoingCall = this.connectedConversation.getConversationCall(this.localStream);
                            // this.connectedConversation.unpublish(this.ongoingCall);
                            this.localStream.release();
                        }

                        activateVideoCallerDispatch();
                        dispatchRemoveCallerStream();
                        // reduxStore.getState().streams.callerStream && reduxStore.getState().streams.callerStream.release();
                        const streamOptions = {
                            ...ONLY_VIDEO,
                            videoInputId: this.cameraId,
                        };
                        const checkIfStreamResolvedMessage = {
                            data: 'checkIfStreamResolved',
                            id: this.cameraId,
                        };

                        let callbacks = {};
                        callbacks.getStream = () => {
                            return new Promise((resolve, reject) => {
                                this.userAgent
                                    .createStream(streamOptions)
                                    .then(stream => {
                                        this.localStream = stream;
                                        if (!reduxStore.getState().application.callerEndPageLoaded) {
                                            stream.removeFromDiv('videoContainer__inner', 'stream');

                                            stream.addInDiv('videoContainer__inner', 'stream', {}, true);

                                            if (this.ongoingCall === null) {
                                                this.connectedConversation.publish(stream);
                                            }

                                            this.sendMessage(checkIfStreamResolvedMessage, true);

                                            return resolve(stream);
                                            // Checks if promise resolved by messaging dispatcher for confirmation
                                        } else {
                                            this.removeStream();
                                            return reject();
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        let responseToFailedVideoMessage = {};
                                        if (
                                            err.message === 'Type error' ||
                                            err.message ===
                                                "Failed to execute 'getUserMedia' on 'MediaDevices': At least one of audio and video must be requested" ||
                                            err.message === 'audio and/or video is required'
                                        ) {
                                            responseToFailedVideoMessage = {
                                                data: 'deviceInfo',
                                                devices: [],
                                                error: err,
                                            };
                                        } else {
                                            responseToFailedVideoMessage = {
                                                data: 'requestStreamRetry',
                                                error: err,
                                            };
                                        }

                                        this.sendMessage(responseToFailedVideoMessage, true);
                                        return reject();
                                    });
                            });
                        };

                        if (this.ongoingCall !== null) {
                            //Switch the camera if call is ongoing
                            return this.ongoingCall
                                .replacePublishedStream(null, callbacks)
                                .then(function (stream) {
                                    console.info('replacePublishedStream OK');
                                })
                                .catch(function (err) {
                                    console.error('replacePublishedStream NOK');
                                });
                        } else {
                            return callbacks.getStream();
                        }
                    } else {
                        deactivateVideoCallerDispatch();
                        deactivateHDSendCallerDispatch();
                        dispatchRemoveCallerStream();
                        unpublishStreamAndRemoveFromRedux(this.localStream, callerStore);
                    }
                }
                if (message.data === 'toggleAudioStream') {
                    this.handleAudioStreamToggle(message.state);
                }
                if (message && message.data === 'chatMessage') {
                    this.useChat = true;
                    activateCallerChat();
                }
                if (message.data === 'toggleChat') {
                    this.useChat = message.state;
                    if (this.useChat) {
                        activateCallerChat();
                    } else {
                        deactivateCallerChat();
                    }
                }
                if (message.data === 'toggleSnapshot') {
                    this.useSnapshot = message.state;
                    snapshotToggleCallback(this.useSnapshot);
                }
                if (message.data === 'heartbeat - ping') {
                    if (!reduxStore.getState().application.callerEndPageLoaded) {
                        this.handlePing();
                    }
                }
                if (message.data === 'dispatcherLeft') {
                    if (reduxStore.getState().streams.callerAudioStream) {
                        unpublishStreamAndRemoveFromRedux(reduxStore.getState().streams.callerAudioStream, callerStore);
                    }
                    if (this.localStream) {
                        unpublishStreamAndRemoveFromRedux(this.localStream, callerStore);
                    }
                    this.connectedConversation.leave();
                    if (this.disconnectCallback) this.disconnectCallback();
                    this.clearAllTimeouts();

                    clearInterval(this.checkHeartbeatInterval);
                }
                if (message.data === 'toggleHDSend') {
                    this.useHDSend = message.state;
                    this.useHDSend ? activateHDSendCallerDispatch() : deactivateHDSendCallerDispatch();
                }
                if (message.data === 'hdFileTransferEnded') {
                    dispatchCallerFileTransferEnded();
                }
                if (message.data === 'dispatcherPaintPoints') {
                    dispatchAddPointsDispatcher(message.points);
                }
                if (message.data === 'undoLastPaintPoints') {
                    dispatchUndoLastPaint();
                }
                if (message.data === 'deleteAllPaintPoints') {
                    dispatchDeletePaint();
                }
                if (message.data === 'toggleDraw') {
                    this.useDraw = message.state;
                    snapshotToggleCallback(this.useDraw);
                    if (this.useDraw) {
                        activateDrawCallerDispatch();
                        deactivateHDSendCallerDispatch();
                    } else {
                        deactivateDrawCallerDispatch();
                    }
                }
                if (message.data === 'allowPainting') {
                    this.allowPainting = message.state;
                    this.allowPainting ? dispatchAllowPainting() : dispatchDisallowPainting();
                }

                if (message.data === 'toggleMicrophone') {
                    this.muteMic();
                    muteMicCallerDispatch();
                }

                if (message.data === 'receivedCallerPageLoaded') {
                    dispatchCallerPageLoaded();
                }

                if (message.data === 'bidiIsDeactivated') {
                    if (reduxStore.getState().application.bidiIsActive) {
                        deactivateBidiCallerDispatch();
                    }
                    if (reduxStore.getState().streams.dispatcherBidiStream) {
                        reduxStore
                            .getState()
                            .streams.dispatcherBidiStream.removeFromDiv(
                                'bidiContainerCaller__inner',
                                'bidiStream-' + reduxStore.getState().streams.dispatcherBidiStream.streamId
                            );
                        dispatchRemoveDispatcherBidiStream();
                        var bidiContainer = document.getElementById('bidiContainerCaller__inner');
                        bidiContainer.innerHTML = '';
                    }
                }
            }
        });

        if (this.connectedConversation) {
            this.sendMediaInfos();
            this.sendConnectionInfos();
            this.sendBatteryInfo();
            this.sendSystemInfo();

            clearTimeout(this.acceptTimeout);
            connectionEstablishedDispatch();
        }

        this.connectedConversation.on('joined', () => {
            clearTimeout(this.acceptTimeout);
            this.newCallCallbacks.forEach(currentNewCallCallback => {
                if (typeof currentNewCallCallback == 'function') {
                    currentNewCallCallback(this.call);
                }
            });

            connectionEstablishedDispatch();
        });

        this.connectedConversation.on('hangup', () => {
            clearTimeout(this.acceptTimeout);
            this.closeCallCallbacks.forEach(currentCloseCallCallback => {
                if (typeof currentCloseCallCallback == 'function') {
                    currentCloseCallCallback(this.call);
                }
            });

            connectionLostDispatch();
            this.clearAllTimeouts();
        });

        return true;
    }

    joinConversation() {
        const { callerId, conversationName } = getURLParams();
        createUserDisplayName(callerStore, callerId);
        callerStore.callerId = callerId;
        callerStore.conversationName = conversationName;
        enterConversation(callerStore);
        callerStore.connectedConversation.join();
        loadEventListenersCaller(callerStore);
    }

    handleAudioStreamToggle = activeState => {
        this.useAudioStream = activeState;
        if (this.useAudioStream) {
            if (reduxStore.getState().streams.callerAudioStream !== null) {
                this.connectedConversation.unpublish(reduxStore.getState().streams.callerAudioStream);
                reduxStore.getState().streams.callerAudioStream.release();
                dispatchRemoveCallerAudioStream();
            }

            if (reduxStore.getState().streams.callerAudioStream === null) {
                const streamOptions = { ...ONLY_AUDIO };
                this.userAgent
                    .createStream(streamOptions)
                    .then(stream => {
                        this.audioStream = stream;
                        this.connectedConversation.publish(stream);
                        dispatchAddCallerAudioStream(stream);
                    })
                    .finally(() => {
                        if (!reduxStore.getState().application.audioStreamIsActive || reduxStore.getState().application.callerEndPageLoaded) {
                            if (reduxStore.getState().streams.callerAudioStream !== null) {
                                this.connectedConversation.unpublish(reduxStore.getState().streams.callerAudioStream);
                                reduxStore.getState().streams.callerAudioStream.release();
                            }
                            deactivateAudioStreamCallerDispatch();
                            muteAudioCallerDispatch();
                            muteMicCallerDispatch();
                        }
                    });
            }
            activateAudioStreamCallerDispatch();
            unmuteAudioCallerDispatch();
            unmuteMicCallerDispatch();
        } else {
            if (reduxStore.getState().streams.callerAudioStream !== null) {
                this.connectedConversation.unpublish(reduxStore.getState().streams.callerAudioStream);
                reduxStore.getState().streams.callerAudioStream.release();
            }
            deactivateAudioStreamCallerDispatch();
            muteAudioCallerDispatch();
            muteMicCallerDispatch();
        }
    };

    muteMic() {
        if (this.audioStream !== null) {
            this.audioStream.disableAudio();
        }

        const message = {
            data: 'callerMutedMic',
        };

        this.sendMessage(message, true);
    }

    unmuteMic() {
        if (this.audioStream !== null) {
            this.audioStream.enableAudio();
        }

        const message = {
            data: 'callerUnmutedMic',
        };

        this.sendMessage(message, true);
    }

    removeStreamFromSubscriptions() {
        if (reduxStore.getState().streams.dispatcherAudioStream !== null) {
            this.connectedConversation.unsubscribeToStream(reduxStore.getState().streams.dispatcherAudioStream.streamId);
            console.log('Unsubscribe to dispatcher stream ran');
        }
    }

    handlePing() {
        this.heartbeatActive = true;
        this.lastPing = new Date().getTime();

        const message = {
            data: 'heartbeat - pong',
        };

        this.sendMessage(message, true);
    }

    checkHeartbeat() {
        // const acceptedThreshold = C_LOST_DURATION / PING_INTERVAL; // 3
        let now = new Date().getTime();
        if (this.heartbeatActive) {
            if (now - this.lastPing >= CALLER_THRESHOLD) {
                showRefreshDispatch();
                addLogDispatch([`hangup because of missed heartbeat for more than ${CALLER_THRESHOLD} seconds`]);
                this.connectedConversation.leave();
                if (this.disconnectCallback) this.disconnectCallback();
                this.clearAllTimeouts();

                clearInterval(callerStore.checkHeartbeatInterval);
            }

            if (now - this.lastPing >= C_LOST_DURATION) {
                // 3 seconds
                if (reduxStore.getState().connection.connectionIsUnstable === false) {
                    connectionUnstableDispatch();
                }
            }

            if (now - this.lastPing < C_LOST_DURATION) {
                // 3 seconds
                if (reduxStore.getState().connection.connectionIsUnstable === true) {
                    connectionStableDispatch();
                }
            }

            if (now - this.lastPing >= RESET_TOGGLE_THRESHOLD) {
                // 10 seconds
                if (reduxStore.getState().application.audioStreamIsActive) {
                    deactivateAudioStreamCallerDispatch();
                    this.connectedConversation.leave();
                }
            }
        }
    }

    /**
     * add new callback to call accepted
     * @param {function} callback
     */
    addNewCallCallback(callback) {
        this.newCallCallbacks.push(callback);
    }

    /**
     * add new callback to call ended
     * @param {function} callback
     */
    addCloseCallCallback(callback) {
        this.closeCallCallbacks.push(callback);
    }

    // no need to test
    /**
     * start sending gps coordinats
     */
    sendCoords() {
        if (this.useGPS) {
            startGPSInterval();
        } else {
            clearGPSInterval();
        }
    }

    // no need to test
    /**
     * start sending battery info
     */
    sendBatteryInfo() {
        this.getBatteryInfo();
        this.batteryInterval = window.setInterval(() => {
            this.getBatteryInfo();
        }, BATTERY_INFO_INTERVAL);
    }

    // tested
    /**
     * get battery info
     */
    getBatteryInfo() {
        if (this.call) {
            if ('getBattery' in navigator) {
                navigator.getBattery().then(battery => {
                    var level = battery.level;
                    const message = {
                        data: 'battery',
                        level: level,
                    };
                    this.sendMessage(message);
                });
            } else {
                const message = {
                    data: 'batteryError',
                    message: 'get battery api not available',
                };
                this.sendMessage(message);
            }
        }
    }

    /**
     * send a given message with webrtc
     * @param {object} message2Send
     * @param {boolean} ping - is heartbeat ping
     */
    sendMessage(message2Send, ping = false) {
        const message = JSON.stringify(message2Send);
        const { sessionId } = getURLParams();
        if (this.dispatcher) {
            this.dispatcher
                .sendMessage(message)
                .then(() => {
                    if (DEBUG) addLogDispatch(['Send message', message]);
                })
                .catch(error => {
                    if (DEBUG) addLogDispatch(['error sending messsage', message, error]);

                    if (!ping) {
                        errorLog({
                            sessionId: sessionId,
                            message: `Error sending message via rtc - caller - ${message}`,
                            error: error,
                            eventId: 'MESSAGE_SEND',
                        });
                    }
                });
        }
    }

    // no need to test
    /**
     * start sending media infos
     */
    sendMediaInfos() {
        this.getMediaInfos();
        this.mediaInterval = window.setInterval(() => {
            this.getMediaInfos();
        }, DEVICE_INFO_INTERVAL);
    }

    // tested
    /**
     * get media infos
     */
    getMediaInfos() {
        this.mediaDevices = this.userAgent.getUserMediaDevices();
        const cams = Object.values(this.mediaDevices.videoinput).map(function (videoInput) {
            return { label: videoInput.label, id: videoInput.id };
        });
        // only send if something changed
        if (JSON.stringify(cams) !== JSON.stringify(this.cams)) {
            this.cams = cams;
            const message = { data: 'deviceInfo', devices: cams };
            this.sendMessage(message);
        }
    }

    // tested
    /**
     * send connection infos
     */
    sendConnectionInfos() {
        const parent = this;
        this.userAgent.fetchNetworkInformation().then(function (data) {
            const message = {
                data: 'networkInfo',
                testServer: data.testServer,
                httpPing: data.httpPing,
                upload: data.upload,
                download: data.download,
            };
            parent.sendMessage(message);
        });
    }

    /**
     * Sends a message with client-system infos.
     */
    // tested
    sendSystemInfo() {
        const info = {
            data: 'system',
            timeOpened: new Date(),
            timezone: new Date().getTimezoneOffset() / 60,
            referrer: document.referrer,
            previousSites: window.history.length,
            browserName: navigator.appName,
            browserEngine: navigator.product,
            browserVersion1a: navigator.appVersion,
            browserVersion1b: navigator.userAgent,
            browserLanguage: navigator.language,
            browserOnline: navigator.onLine,
            browserPlatform: navigator.platform,
            javaEnabled: navigator.javaEnabled(),
            dataCookiesEnabled: navigator.cookieEnabled,
            dataCookies1: document.cookie,
            dataCookies2: decodeURIComponent(document.cookie.split(';')),
            dataStorage: localStorage,
            sizeScreenW: window.screen.width,
            sizeScreenH: window.screen.height,
            sizeDocW: document.width,
            sizeDocH: document.height,
            sizeInW: window.innerWidth,
            sizeInH: window.innerHeight,
            sizeAvailW: window.screen.availWidth,
            sizeAvailH: window.screen.availHeight,
            scrColorDepth: window.screen.colorDepth,
            scrPixelDepth: window.screen.pixelDepth,
        };
        this.sendMessage(info);
    }

    // tested
    /**
     * remove the current localstream
     */
    removeStream() {
        if (this.localStream !== null) {
            this.localStream.release();
        }
        if (typeof this.cameraId !== 'undefined' && this.cameraId !== null && (this.cameraId === '0' || this.cameraId.length === 0)) {
            this.localStream = null;
        }
    }

    /**
     * send video not allowed message
     */
    sendVideoNotAllowed() {
        this.sendMessage({
            data: 'videoNotAvail',
        });
    }

    // tested
    /**
     * send snapshot permission message
     * @param {boolean} permission
     */
    sendPhotoPermission(permission) {
        this.sendMessage({
            data: 'photoPermission',
            permission,
        });
    }

    /**
     * send file select start
     * @returns
     */
    sendFileIsBusy() {
        this.sendMessage({
            data: 'hdFileCallerIsBusy',
        });
    }

    /**
     * send file start
     * @returns
     */
    sendFileStart() {
        this.sendMessage({
            data: 'hdFileTransferStarted',
        });
    }

    /**
     * send file
     * @param {object} file
     */
    sendFile(file) {
        const { callerId } = getURLParams();
        const contact = this.connectedSession.getContact(callerId);
        const fileInfo = { name: file.name, type: file.type };

        this.sendFileStart();
        dispatchCallerFileTransferStarted();
        const fileTransfer = contact.sendFile(fileInfo, file);

        if (DEBUG) addLogDispatch(['fileTransfer', fileTransfer]);
    }

    /**
     * send caller paint points
     */
    sendCallerPaintPoints(points) {
        this.sendMessage({
            data: 'callerPaintPoints',
            points,
        });
    }

    /**
     * allowPainting
     * @param {boolean} activeState
     */
    sendAllowPainting(activeState) {
        const message = {
            data: 'allowPainting',
            state: activeState,
        };
        this.sendMessage(message);
    }

    /**
     * delete caller paint points
     * @param {boolean} activeState
     */
    sendDeletePaintPointsCaller() {
        const message = {
            data: 'deletePaintPointsCaller',
        };
        this.sendMessage(message);
    }

    // no need to test
    /**
     * clear all open timeouts and intervals
     */
    clearAllTimeouts() {
        clearTimeout(this.gpsTimeout);
        clearInterval(this.batteryInterval);
        clearInterval(this.mediaInterval);
        clearGPSInterval();
    }
}
export let callerStore = new CallerStore();
