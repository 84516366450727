import { oauthStore } from '../store/OAuthStore';
import { getData, postData, deleteData, forceLogout } from './common';
import { DEBUG, IMAGE_FORMAT, IMAGE_TARGET } from '../config';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import { callerStore } from '../store/CallerStore';
import { store } from '../store/DispatcherStore';
import { timeLog, errorLog } from '../helper/logging';
import { addLogDispatch } from '../redux/actions/logs';
import { disableSessionDownloadDispatch } from '../redux/actions/session';
import { convertBase64ToBlob, convertBlobToBase64, createKpiLog, obscurePhonenumber } from '../helper/helper';
import { getURLParams } from '../helper/rtcFlowHandling';
import { conferenceStore } from '../store/ConferenceStore';

const welcomeMessageApi = process.env.REACT_APP_WELCOMEMESSAGE_URL;
const systemHealthApi = process.env.REACT_APP_SYSTEMHEALTH_URL;
const sessionEndApi = process.env.REACT_APP_SESSIONEND_URL;
const sessionEndConferenceApi = process.env.REACT_APP_SESSIONENDCONFERENCE_URL;
const newLocationAPI = process.env.REACT_APP_NEWLOCATION_URL;
let isCaller = false;

/**
 * backendApi
 * all connections to the backend
 */

/**
 * gets the system health from the backend
 * @returns {object} system health
 */
export const getSystemHealth = async () => {
    const results = await getData(systemHealthApi, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getSystemHealth() error getting data from backend. - code: GT HLTH',
            error: e,
            eventId: 'SYSTEM_HEALTH',
        });
    });
    if (results) {
        if (DEBUG) addLogDispatch(['getSystemHealth', results]);
        return results;
    }
};

/**
 * gets the version from the backend
 * @returns {string} version
 */
export const getVersion = async () => {
    const results = await getData(process.env.REACT_APP_VERSION_POLLING_API, oauthStore.authToken, 'text').catch(e => {
        errorLog({
            message: 'getVersion() error getting data from backend. - code: GT VRSN',
            error: e,
            eventId: 'VERSION',
        });
    });
    if (results) {
        if (DEBUG) addLogDispatch(['getVersion', results]);
        return results;
    }
};

/**
 * gets the welcome messages from the backend
 * @returns {array} welcome messages
 */
export const getWelcomeMessages = async () => {
    const results = await getData(welcomeMessageApi, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getWelcomeMessages() error getting data from backend. - code: GT WLCM',
            error: e,
            eventId: 'SESSION_END',
        });
    });
    if (results) {
        if (DEBUG) addLogDispatch(['getWelcomeMessages', results]);
        return results;
    }
};

/**
 * gets the login endpoint from the backend
 * @returns {string} url login endpoint
 */
export const getLoginEndpoint = async () => {
    const endpoint = await getData(process.env.REACT_APP_LOGIN_URL_ENDPOINT)
        .then(response => {
            return response.url;
        })
        .catch(e => {
            errorLog({
                message: 'getLoginEndpoint() error getting data from backend - code: LGN USR',
                error: e,
                eventId: 'LOGIN_USER',
            });
            addNotificationAndShowDispatch('error.lgn_usr', 'error');
        });

    if (endpoint) {
        if (DEBUG) addLogDispatch(['endpointURL', endpoint]);
        return endpoint;
    }
};

/**
 * creates a bystander user in the backend for a given phonenumber
 * @param {string} phoneNumber
 */
export const createBystander = async phoneNumber => {
    return await postData(process.env.REACT_APP_CREATE_BYSTANDER_URL, { phoneNumber: phoneNumber }, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'createBystander() error getting data from authserver. - code: CRT BYST',
            error: e,
            eventId: 'CREATE_BYSTANDER',
        });
        addNotificationAndShowDispatch('error.crt_bystndr', 'error');
    });
};

/**
 * sends a sms via the backend to the given number
 * @param {object} data object with phonenumber
 * @returns {object} sms response data
 */
export const sendSMSAPI = async data => {
    if (data.isResendSMS) {
        const additionalStates = {
            0: obscurePhonenumber(store.phone),
        };

        createKpiLog('infoSmsResent', '', additionalStates);
    } else {
        const additionalStates = {
            0: obscurePhonenumber(store.phone),
        };

        createKpiLog('infoSmsSent', '', additionalStates);

        timeLog('session');
    }

    return await postData(process.env.REACT_APP_SEND_SMS_URL, data, oauthStore.authToken).catch(e => {
        // addNotificationAndShowDispatch('error.snd_sms', 'error');
        if (data.isResendSMS) {
            errorLog({
                message: 'sendSMSAPI() error posting data to backend - code: RESEND SMS',
                error: e,
                eventId: 'RESEND_SMS',
            });
        } else {
            errorLog({
                message: 'sendSMSAPI() error posting data to backend - code: SND SMS',
                error: e,
                eventId: 'SEND_SMS',
            });
            throw new Error('smsError');
        }
    });
};

/**
 * delete a bystander user from the backend
 * @param {string} phoneNumber phonenumber of the user
 * @returns {object} response object
 */
export const deleteBystander = async phoneNumber => {
    return postData(process.env.REACT_APP_DELETE_BYSTANDER_URL, { phoneNumber: phoneNumber }, oauthStore.authToken, false).catch(e => {
        errorLog({
            message: 'deleteBystander error posting data to backend - code: DLT BYST',
            error: e,
            eventId: 'DELETE_BYSTANDER',
        });
        addNotificationAndShowDispatch('error.dlt_bystndr', 'error');
    });
};

/**
 * get phonenumber from backend instead of input
 * @returns {object} response object
 */
export const getPhoneNumber = async () => {
    return getData(process.env.REACT_APP_POLLING_API, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getPhoneNumber() error getting data from backend - code: GT PHNNMBR',
            error: e,
            eventId: 'GET_PHONENUMBER',
        });
        // addNotificationAndShowDispatch('error.gt_phn', 'error');
        throw Error('POLLING ERROR');
    });
};

/**
 * consume a given phonenumber to stop the polling
 * @param {string} phoneNumber given phonenumber
 * @returns {object} response object
 */
export const phoneNumberConsumed = async phoneNumber => {
    return postData(process.env.REACT_APP_POLLING_CONSUMED + encodeURIComponent(phoneNumber), {}, oauthStore.authToken, false).catch(e => {
        errorLog({
            message: 'phoneNumberConsumed() error getting data from backend - code: PST PHNNMBR',
            error: e,
            eventId: 'POST_PHONENUMBER',
        });
    });
};

/**
 * get a chat message from backend instead of user input
 * @param {string} phoneNumber given phonenumber
 * @returns {object} chat message
 */
export const getChatMessage = async phoneNumber => {
    return postData(process.env.REACT_APP_CHAT_POLLING_API, { phoneNumber: phoneNumber }, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getChatMessage() error getting data from backend - code: GT CHTMSG',
            error: e,
            eventId: 'GT CHTMSG',
        });
        throw Error('POLLING ERROR');
    });
};

/**
 * get a chat blocks from the backend
 * @returns {object} chat blocks
 */
export const getChatBlocks = async () => {
    const results = await getData(process.env.REACT_APP_CHATBLOCKS_URL, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getChatBlocks() error getting data from backend. - code: CHT BLKS',
            error: e,
            eventId: 'CHAT_BLOCKS',
        });
    });
    if (results) {
        if (DEBUG) addLogDispatch(['getChatBlocks', results]);
        return results;
    }
};

/**
 * consume a chat message for the given phonenumber
 * @param {string} phoneNumber given phonenumber
 * @returns {object} response object
 */
export const chatMessageConsumed = async phoneNumber => {
    return deleteData(process.env.REACT_APP_CHAT_POLLING_API, oauthStore.authToken, true, { phoneNumber: phoneNumber }).catch(e => {
        errorLog({
            message: 'chatMessageConsumed() error getting deleting data from backend - code: DLT CHTMSG',
            error: e,
            eventId: 'DLT CHTMSG',
        });
    });
};

/**
 * create a log event for the backend to pass to the logging system
 * @param {object} data log data
 * @returns {object} response data
 */
let postLogEventError = false;
export const postLogEvent = async data => {
    let token = oauthStore.authToken;
    const { sessionId } = getURLParams();
    if (!postLogEventError && !oauthStore.authToken) {
        isCaller = true;
        await getAuthtoken(sessionId);
        if (callerStore.authToken) token = callerStore.authToken;
    }

    return postData(process.env.REACT_APP_LOG_API, data, token, false)
        .then(response => {
            postLogEventError = false;
            return response;
        })
        .catch(e => {
            postLogEventError = true;
            errorLog({
                message: data.message,
                sessionId: sessionId,
                error: e,
                eventId: 'PST_LG_EVNT',
            });
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
        });
};

/**
 * create a error log event for the backend to pass to the logging system
 * @param {object} data log data
 * @returns {object} response data
 */
export const postLogError = async data => {
    return postData(process.env.REACT_APP_LOG_API, data, null, false)
        .then(response => {
            return response;
        })
        .catch(e => {
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
        });
};

/**
 * refresh the current auth token for another period of time
 * @returns {object} response data
 */
export const getTokenRefresh = async () => {
    return getData(process.env.REACT_APP_REFRESH_TOKEN_URL, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'getTokenRefresh() error refreshing auth token - code: GT TKN RFRSH',
            error: e,
            eventId: 'GT_TKN_RFRSH',
        });

        forceLogout();
    });
};

/**
 * get texts in a specific language
 * @param {string} lang language to get
 * @return {object} texts in specific language
 */

export const getLanguageFile = async (lang, type) => {
    try {
        let defaultFile = `/texts/${lang}/eex.dispatcher.json`;
        if (type === 'caller') {
            defaultFile = `/texts/${lang}/eex.caller.json`;
        }
        if (type === 'conference') {
            defaultFile = `/texts/${lang}/default.conference.json`;
        }

        const defaultTexts = await getData(defaultFile, oauthStore.authToken, 'text');

        if (process.env.REACT_APP_LANGUAGE_PACK) {
            let overwriteFile = `/texts/${lang}/${process.env.REACT_APP_LANGUAGE_PACK}.dispatcher.json`;
            if (type === 'caller') {
                overwriteFile = `/texts/${lang}/${process.env.REACT_APP_LANGUAGE_PACK}.caller.json`;
            }
            if (type === 'conference') {
                overwriteFile = `/texts/${lang}/${process.env.REACT_APP_LANGUAGE_PACK}.conference.json`;
            }

            try {
                const overwriteTexts = await getData(overwriteFile, oauthStore.authToken, 'text');
                return {
                    ...JSON.parse(defaultTexts),
                    ...JSON.parse(overwriteTexts),
                };
            } catch {
                return {
                    ...JSON.parse(defaultTexts),
                };
            }
        }

        return { ...JSON.parse(defaultTexts) };
    } catch (e) {
        errorLog({
            message: 'getLanguageFile() error getting ' + lang + ' - code: GT LNG ',
            error: e,
            eventId: 'GT_LNG',
        });
    }
};

// CALLER ONLY
/**
 * invalidate the current token data when logging out
 * @returns {object} response data
 */
export const postLogout = async () => {
    return postData(process.env.REACT_APP_LOGOUT_TOKEN_ENDPOINT, {}, oauthStore.authToken, false).catch(e => {
        errorLog({
            message: 'postLogout() error invalidating auth token - code: PST LGT',
            error: e,
            eventId: 'PST_LGT',
        });
    });
};

/**
 * sends a sms with the location data givin
 * @param {object} data location data and phonenumber
 * @returns {object} response data
 */
export const sendGEOSMSAPI = async data => {
    const additionalStates = {
        0: obscurePhonenumber(data.phone),
    };

    createKpiLog('infoGeoSmsSent', '', additionalStates);

    let response = await postData(process.env.REACT_APP_SEND_GEO_SMS_URL, data, oauthStore.authToken).catch(e => {
        errorLog({
            message: 'sendGEOSMSAPI() error posting data to backend - code: SND GEO SMS',
            error: e,
            eventId: 'SEND_GEO_SMS',
        });
        addNotificationAndShowDispatch('error.snd_geo_sms', 'error');
        throw new Error('smsError');
    });
    console.log(response);
    return response;
};

/**
 * get all application features available for the current user
 * @returns {array} enabled features
 */
export const getApplicationFeaturesDispatcher = async () => {
    const config = await getData(process.env.REACT_APP_APPLICATION_DISPATCHER, oauthStore.authToken).catch(e => {
        if (DEBUG) addLogDispatch(['getApplicationFeaturesDispatcher', e]);
    });

    if (config) {
        return config.enabledFeatures;
    }
    return [];
};

/**
 * generate a pdf for the session log
 */
export const createSessionReport = async ({ data }, bystanderToken) => {
    const additionalStates = {
        0: `session_${store.userId}_${bystanderToken}.pdf`,
        sessionId: bystanderToken,
    };

    createKpiLog('infoPdfReportCreated', '', additionalStates);

    return await postData(process.env.REACT_APP_SESSION_LOG_PDF, data, oauthStore.authToken, false).catch(e => {
        if (DEBUG) addLogDispatch(['createSessionReport', e]);
        errorLog({
            message: 'createSessionReport() error - code: GNRT_SSSN_LG',
            error: e,
            eventId: 'GNRT_SSSN_LG',
        });
        addNotificationAndShowDispatch('error.gnrt_sssn_lg', 'error');

        disableSessionDownloadDispatch();
    });
};

/**
 * get pdf data from given url
 */

export const getSessionReport = async url => {
    return await getData(url, oauthStore.authToken, 'blob').catch(e => {
        if (DEBUG) addLogDispatch(['getSessionReport', e]);
        errorLog({
            message: 'getSessionReport() error - code: GET_SSSN_LG',
            error: e,
            eventId: 'GET_SSSN_LG',
        });
        addNotificationAndShowDispatch('error.get_sssn_lg', 'error');

        disableSessionDownloadDispatch();
    });
};

/**
 * publish a chat message to the backend
 */

export const publishChatMessage = async ({ message, translation, from, sessionId }) => {
    const transformedData = {
        message,
        source: from,
        sessionId: store.connectedSession.id + '|' + store.bystanderToken,
    };

    await postData(process.env.REACT_APP_PUBLISH_CHAT_MESSAGE_ENDPOINT, transformedData, oauthStore.authToken, false).catch(e => {
        if (DEBUG) addLogDispatch(['publishChatMessage', e]);
        errorLog({
            message: 'publishChatMessage() error - code: PSH CHT MSG',
            error: e,
            eventId: 'PSH_CHT_MSG',
        });
        addNotificationAndShowDispatch('error.psh_cht_msg', 'error');
    });
};

/**
 * upload a image to the backend
 */

export const uploadImage = async ({ image, type }) => {
    const formData = new FormData();

    let file = convertBase64ToBlob(image.replace('application/octet-stream', type));

    formData.append('file', file);
    formData.append('sessionId', store.connectedSession.id + '|' + store.bystanderToken);

    const params = {
        method: 'POST',
        withCredentials: true,
        body: formData,
        headers: {
            Authorization: `Bearer ${oauthStore.authToken}`,
        },
    };

    await fetch(process.env.REACT_APP_UPLOAD_FILE_ENDPOINT, params).catch(e => {
        if (DEBUG) addLogDispatch(['uploadImage error', e]);
        errorLog({
            message: 'uploadImage() error - code: UPLD_IMG',
            error: e,
            eventId: 'UPLD_IMG',
        });
    });
};

// CALLER ONLY
/**
 * gets the authoken for a given session id
 * @param {string} sessionId current session id to get the toekn for
 * @returns {string} auth token
 */
export const getAuthtoken = async sessionId => {
    const token = await getData(process.env.REACT_APP_RESOLVE_TOKEN_URL + sessionId)
        .then(response => {
            return response.token;
        })
        .catch(e => {
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
            errorLog({
                message: 'getAuthtoken() error getting data from backend - code: TKN RQST',
                sessionId: sessionId,
                error: e,
                eventId: 'TOKEN_REQUEST',
            });
        });

    if (token) {
        if (DEBUG) addLogDispatch(['getAuthtoken', token]);
        return token;
    }
};

// CALLER ONLY
/**
 * sends a new location to the backend
 * @param {object} location new location data
 * @returns {object} response data
 */
export const sendNewLocation = async location => {
    const token = callerStore.authToken;
    const { sessionId } = getURLParams();
    await postData(newLocationAPI, location, token, false, false).catch(e => {
        errorLog({
            message: 'sendingLocations() error getting data from backend. - code: GEO SEND',
            error: e,
            sessionId: sessionId,
            eventId: 'GEO_SEND',
        });
    });
};

// CALLER ONLY
/**
 * gets the session end messages
 * @returns {array} session end messages
 */
export const getSessionEndMessages = async token => {
    const { sessionId } = getURLParams();
    const results = await getData(sessionEndApi, token, 'json', false).catch(e => {
        errorLog({
            message: 'getSessionEndMessages() error getting data from backend. - code: SSSN ND',
            error: e,
            sessionId: sessionId,
            eventId: 'SESSION_END',
        });
    });
    if (results && results[0]) {
        if (DEBUG) addLogDispatch(['getSessionEndMessages', results[0]]);
        return results[0].split('\n');
    }
};

// CONFERENCE USERS ONLY
/**
 * gets the session end messages
 * @returns {array} session end messages
 */
// TODO: Uncomment this when backend API exists
export const getSessionEndMessagesConference = async token => {
    const { sessionId } = getURLParams();
    const results = await getData(sessionEndConferenceApi, token, 'text', false).catch(e => {
        errorLog({
            message: 'getSessionEndMessagesConference() error getting data from backend. - code: SSSN ND',
            error: e,
            sessionId: sessionId,
            eventId: 'SESSION_END',
        });
    });
    if (results && results[0]) {
        if (DEBUG) addLogDispatch(['getSessionEndMessages', results.split()]);
        return results.split();
    }
    return '';
};

// CALLER ONLY
/**
 * consume the short token after receiving a auth token
 * @returns {object} response data
 */
export const postShortTokenComsumed = async () => {
    const { sessionId } = getURLParams();
    return postData(process.env.REACT_APP_SHORT_TOKEN_CONSUMED + sessionId, {}, callerStore.authToken, false)
        .then(response => {
            return response;
        })
        .catch(e => {
            if (e.message.indexOf('NOT_FOUND') !== -1) return;
            if (!isCaller) addNotificationAndShowDispatch('ERROR !!!!', 'error');
        });
};

// CALLER ONLY
/**
 * Gets the chat labels in a specific language
 * @param {string} lang language to get
 */
export const getChatLabels = async lang => {
    try {
        const defaultFile = `/texts/${lang}/eex.chat.json`;

        const defaultTexts = await getData(defaultFile, oauthStore.authToken, 'text');

        if (process.env.REACT_APP_LANGUAGE_PACK) {
            const overwriteFile = `/texts/${lang}/${process.env.REACT_APP_LANGUAGE_PACK}.chat.json`;

            const overwriteTexts = await getData(overwriteFile, oauthStore.authToken, 'text');

            return {
                ...JSON.parse(defaultTexts),
                ...JSON.parse(overwriteTexts),
            };
        }

        return { ...JSON.parse(defaultTexts) };
    } catch (e) {
        errorLog({
            message: 'getChatLabels() error getting ' + lang + ' - code: GT CHT LBL',
            error: e,
            eventId: 'GT_CHT_LBL',
        });
    }
};

// CALLER ONLY
/**
 * get the application features available for the current user
 * @returns {object} application config
 */
export const getApplicationFeaturesCaller = async () => {
    const config = await getData(process.env.REACT_APP_APPLICATION_CALLER, callerStore.authToken, 'json', false).catch(e => {
        if (DEBUG) addLogDispatch(['getApplicationFeaturesCaller', e]);
    });

    if (config) {
        return config.enabledFeatures;
    }
    return [];
};

/**
 * get the saved language for the current dispatch user
 * @returns {string} language handle
 */

export const getLanguageForDispatcher = async () => {
    return await getData(process.env.REACT_APP_LANGUAGE_GET, oauthStore.authToken, 'text').catch(e => {
        if (DEBUG) addLogDispatch(['getLanguageForDispatcher', e]);
        errorLog({
            message: 'getLanguageForDispatcher() error getting data from backend. - code: GT LNGG',
            error: e,
            eventId: 'GET_LANGUAGE',
        });
    });
};

/**
 * sets the language for the current dispatch user
 * @param {string} languageHandle language handle
 */

export const setLanguageForDispatcher = async languageHandle => {
    return await postData(process.env.REACT_APP_LANGUAGE_SET, { language: languageHandle }, oauthStore.authToken, false).catch(e => {
        if (DEBUG) addLogDispatch(['setLanguageForDispatcher', e]);
        errorLog({
            message: 'setLanguageForDispatcher() error setting data. - code: ST LNGG',
            error: e,
            eventId: 'SET_LANGUAGE',
        });
    });
};

/**
 * get the dispatch center image
 * @returns {FormData} image
 */
export const getDispatchCenterImage = async ({ type = 'blob' }) => {
    let token = oauthStore.authToken || callerStore.authToken;

    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const image = await fetch(process.env.REACT_APP_IMAGE_DISPLAY_ENDPOINT, params).catch(e => {
        console.log(e);
        if (DEBUG) addLogDispatch(['getDispatchCenterImage', e]);
    });

    if (image && image.ok) {
        const imageData = await image.blob();

        switch (true) {
            case type === 'base64':
                return await convertBlobToBase64(imageData);
            case type === 'blob':
            default:
                return URL.createObjectURL(imageData);
        }
    }
};

/**
 * get the dispatch center image for conference users
 * @returns {FormData} image
 */
export const getConferenceDispatchCenterImage = async ({ type = 'blob' }) => {
    let token = oauthStore.authToken || conferenceStore.authToken;

    const params = {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const image = await fetch(process.env.REACT_APP_CONFERENCE_LOGO_ENDPOINT, params).catch(e => {
        console.log(e);
        if (DEBUG) addLogDispatch(['getDispatchCenterImage', e]);
    });

    if (image && image.ok) {
        const imageData = await image.blob();

        switch (true) {
            case type === 'base64':
                return await convertBlobToBase64(imageData);
            case type === 'blob':
            default:
                return URL.createObjectURL(imageData);
        }
    }
};

/**
 * get all the contacts available for the current user
 * @returns {array} enabled features
 */
export const getContactsDispatcher = async () => {
    const contacts = await getData(process.env.REACT_APP_CONTACTS_ENDPOINT, oauthStore.authToken).catch(e => {
        if (DEBUG) addLogDispatch(['getContactsDispatcher', e]);
    });

    if (contacts) {
        return contacts;
    }
    return [];
};

/**
 * get the mimetype for the snapshot function
 * @returns {string|null} mimetype
 */

export const getSnapshotMimeType = async () => {
    const mimeType = await getData(process.env.REACT_APP_MIMETYPE_ENDPOINT, oauthStore.authToken, 'text').catch(e => {
        if (DEBUG) addLogDispatch('getSnapshotMimeType', e);
    });

    if (mimeType) {
        return mimeType;
    }
    return IMAGE_FORMAT;
};

/**
 * convert an image to a certain format
 * @param {object} image
 * @param {string} targetFormat
 * @returns {object} convertedImage
 */

export const convertImageToFormat = async (image, targetFormat) => {
    const formData = new FormData();

    const file = convertBase64ToBlob(image.image);

    formData.append('file', file);
    formData.append('targetFormat', targetFormat);

    const params = {
        method: 'POST',
        withCredentials: true,
        body: formData,
        headers: {
            Authorization: `Bearer ${oauthStore.authToken}`,
        },
    };

    const result = await fetch(process.env.REACT_APP_IMAGE_CONVERT_ENDPOINT, params);
    const convertedImage = await result.blob();
    const target = IMAGE_TARGET[targetFormat];
    return {
        image: await convertBlobToBase64(convertedImage),
        time: Date.now(),
        type: `image/${target}`,
    };
};

/**
 * convert an image to a certain format
 * @param {object} image
 * @param {string} targetFormat
 * @returns {object} convertedImage
 */

export const overlayTwoImages = async (backImage, overlayImage) => {
    const formData = new FormData();

    const backFile = convertBase64ToBlob(backImage);
    const overlayFile = convertBase64ToBlob(overlayImage);

    formData.append('file', backFile);
    formData.append('overlay', overlayFile);

    const params = {
        method: 'POST',
        withCredentials: true,
        body: formData,
        headers: {
            Authorization: `Bearer ${oauthStore.authToken}`,
        },
    };

    const result = await fetch(process.env.REACT_APP_IMAGE_OVERLAY_ENDPOINT, params);
    const mergedImage = await result.blob();

    return {
        image: await convertBlobToBase64(mergedImage),
        time: Date.now(),
        type: `image/png`,
    };
};

// DISPATCHER ONLY
/**
 * send an email from specified address to recipient
 * @returns {object} response data
 */
export const postSendEmail = async ({ to, subject, message }) => {
    return postData(
        process.env.REACT_APP_EMAIL_ENDPOINT,
        {
            to,
            subject,
            message,
        },
        oauthStore.authToken,
        false
    )
        .then(() => {
            return true;
        })
        .catch(e => {
            //TODO: Need error message translation for failed email
            // addNotificationAndShowDispatch(e.status, 'error');
            console.log(e);

            // TODO: Send message to graylog
            throw new Error();
        });
};

/**
 * get current dispatch center id
 * @returns {array} dispatch center id
 */
export const getCurrentDispatchCenterId = async () => {
    const config = await getData(process.env.REACT_APP_APPLICATION_DISPATCHER, oauthStore.authToken).catch(e => {
        if (DEBUG) addLogDispatch(['getCurrentDispatchCenterId', e]);
    });

    if (config) {
        return config.dispatchCenter.id;
    }
    return [];
};

/**
 * get invite email text for current dispatch center
 * @returns
 */
export const getCurrentInviteEmailText = async () => {
    return await getData(process.env.REACT_APP_INVITE_EMAIL_TEXT, oauthStore.authToken, 'text').catch(e => {
        if (DEBUG) addLogDispatch(['getCurrentInviteEmailText', e]);
        errorLog({
            message: 'getCurrentInviteEmailText() error getting data from backend. - code: GT CIET',
            error: e,
            eventId: 'GET_EMAIL_TEXT',
        });
    });
};

/**
 * send an invite from specified address to recipient
 * @returns {object} response data
 */
export const sendInvitation = async ({ to, message, conference }) => {
    return postData(
        process.env.REACT_APP_INVITE,
        {
            to,
            message,
            conference,
        },
        oauthStore.authToken,
        false
    )
        .then(() => {
            return true;
        })
        .catch(e => {
            //TODO: Need error message translation for failed email
            // addNotificationAndShowDispatch(e.status, 'error');
            console.log(e);

            // TODO: Send message to graylog
            throw new Error();
        });
};

/**
 * get apizee api key from backend
 * @returns {object} response object
 */
export const getApizeeKey = async type => {
    let tokenType = null;
    if (type === 'dispatcher') tokenType = oauthStore.authToken;
    if (type === 'caller') tokenType = callerStore.authToken;
    if (type === 'conference') tokenType = conferenceStore.authToken;
    const key = await getData(process.env.REACT_APP_APIKEY_ENDPOINT, tokenType, 'text').catch(e => {
        errorLog({
            message: 'getApizeeKey() error getting data from backend - code: GT APIZKEY',
            error: e,
            eventId: 'GET_APIZKEY',
        });

        throw new Error();
    });
    if (key) {
        return key;
    }
};

/**
 * post external stream name to backend
 */
export const postExternalStream = async () => {
    let streamName = store.bystanderToken.slice(store.bystanderToken.length - 3, store.bystanderToken.length);
    let streamUrl = process.env.REACT_APP_EXTERNAL_STREAM_ENDPOINT + streamName;

    return postData(streamUrl, {}, oauthStore.authToken, false)
        .then(response => {
            return response.ok;
        })
        .catch(e => {
            console.log(e);
            throw new Error();
        });
};

/**
 * delete external stream name from backend
 */
export const deleteExternalStream = async () => {
    let streamName = store.bystanderToken.slice(store.bystanderToken.length - 3, store.bystanderToken.length);
    let streamUrl = process.env.REACT_APP_EXTERNAL_STREAM_ENDPOINT + streamName;

    return deleteData(streamUrl, oauthStore.authToken)
        .then(() => {
            return true;
        })
        .catch(e => {
            console.log(e);
            throw new Error();
        });
};
