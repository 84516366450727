import {
    ADD_NOTIFICATION,
    HIDE_ERRORS,
    HIDE_INFOS,
    HIDE_WARNINGS,
    REMOVE_ERRORS,
    REMOVE_INFOS,
    REMOVE_WARNINGS,
    REMOVE_NOTIFICATION,
    REMOVE_NOTIFICATIONS,
    SHOW_ERRORS,
    SHOW_INFOS,
    SHOW_WARNINGS,
} from '../actions/actionTypes';

export const initialState = {
    currentNotifications: [],
    showErrors: false,
    showInfos: false,
    showWarnings: false,
};

/**
 * notification reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const notificationReducer = (state = initialState, action) => {
    if (action.type === ADD_NOTIFICATION) {
        const filteredDuplicates = state.currentNotifications.filter(notification => notification.message !== action.notification.message);
        return {
            ...state,
            currentNotifications: [...filteredDuplicates, action.notification],
        };
    }
    if (action.type === SHOW_ERRORS) {
        return { ...state, showErrors: true };
    }
    if (action.type === SHOW_INFOS) {
        return { ...state, showInfos: true };
    }
    if (action.type === SHOW_WARNINGS) {
        return { ...state, showWarnings: true };
    }
    if (action.type === HIDE_ERRORS) {
        return { ...state, showErrors: false };
    }
    if (action.type === HIDE_INFOS) {
        return { ...state, showInfos: false };
    }
    if (action.type === HIDE_WARNINGS) {
        return { ...state, showWarnings: false };
    }
    if (action.type === REMOVE_NOTIFICATIONS) {
        return { ...state, currentNotifications: [] };
    }
    if (action.type === REMOVE_ERRORS) {
        return {
            ...state,
            currentNotifications: state.currentNotifications.filter(notification => notification.type !== 'error'),
        };
    }
    if (action.type === REMOVE_INFOS) {
        return {
            ...state,
            currentNotifications: state.currentNotifications.filter(notification => notification.type !== 'info'),
        };
    }
    if (action.type === REMOVE_WARNINGS) {
        return {
            ...state,
            currentNotifications: state.currentNotifications.filter(notification => notification.type !== 'warning'),
        };
    }
    if (action.type === REMOVE_NOTIFICATION) {
        return {
            ...state,
            currentNotifications: state.currentNotifications.filter(notification => notification.id !== action.id),
        };
    }
    return state;
};

export default notificationReducer;
