import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import './HLSPlayer.scss';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { DEBUG, DISPLAY_ONLY_IN_SESSION } from '../../config';

export const HLSPlayer = ({ source }) => {
    const [streamIsAvailable, setStreamIsAvailable] = useState(true);
    const videoRef = useRef(null);
    const streamingSource = source + '/index.m3u8';

    let hlsRef = useRef(null);
    let isTryToLoadManifestInterval = useRef(null);

    const handleStreamAvailable = () => {
        setStreamIsAvailable(true);
        clearInterval(isTryToLoadManifestInterval.current);
        addNotificationAndShowDispatch("external.stream.available", 'info', DISPLAY_ONLY_IN_SESSION)
        videoRef.current.muted = true;
        videoRef.current.play();
    };

    const handleStreamNotAvailable = () => {
        setStreamIsAvailable(false);
        if (isTryToLoadManifestInterval !== null) clearInterval(isTryToLoadManifestInterval.current);
        isTryToLoadManifestInterval.current = setInterval(initializeHlsInstance, 1000);
    };

    const initializeHlsInstance = () => {
        if (hlsRef.current !== null) {
            hlsRef.current.destroy();
            hlsRef.current = null;
        }

        let config = {
            autoStartLoad: true,
            enableWorker: true,
            maxBufferLength: 1,
            liveBackBufferLength: 0,
            liveSyncDuration: 0,
            liveMaxLatencyDuration: 5,
            liveDurationInfinity: true,
            highBufferWatchdogPeriod: 1,
        };

        if (Hls.isSupported()) {
            const video = videoRef.current;
            hlsRef.current = new Hls(config);
            hlsRef.current.loadSource(streamingSource);
            hlsRef.current.attachMedia(video);
            hlsRef.current.on(Hls.Events.MANIFEST_PARSED, handleStreamAvailable);
            hlsRef.current.on(Hls.Events.ERROR, (event, data) => {
                if (DEBUG) console.log(event, data);
                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            hlsRef.current.startLoad();
                            if (streamIsAvailable) {
                                handleStreamNotAvailable();
                            }
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            hlsRef.current.recoverMediaError();
                            break;
                        default:
                            console.error('Fatal error occurred:', data);
                            break;
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (!streamIsAvailable) {
            addNotificationAndShowDispatch("external.stream.unavailable", 'info', DISPLAY_ONLY_IN_SESSION)
        }
    }, [streamIsAvailable])

    useEffect(() => {
        initializeHlsInstance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            hlsRef.current.destroy();
            hlsRef.current = null;
            clearInterval(isTryToLoadManifestInterval.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="hls-stream-container">
            <video ref={videoRef} id="hls-stream" controls></video>
        </div>
    );
};

// PropTypes for this Component
HLSPlayer.propTypes = {
    source: PropTypes.string.isRequired,
};
