import { DEFAULT_LANGUAGE, IMAGE_FORMAT, SESSION_DOWNLOAD_LOG_FILTER } from '../../config';
import { cssVariables, logo } from 'theme';
import {
    ADD_SESSION_CHATMESSAGE,
    ADD_SESSION_GPS,
    ADD_SESSION_IMAGE,
    ADD_SESSION_INFOS,
    ADD_SESSION_LANGUAGE,
    ADD_SESSION_LOG,
    ADD_SESSION_MAP,
    ADD_SESSION_WHITELABEL_LOGO,
    ALLOW_PHOTO_PERMISSION,
    CHANGE_SESSION_IMAGE_FORMAT,
    DISABLE_SESSION_DOWNLOAD,
    DISALLOW_PHOTO_PERMISSION,
    RESET_SESSION,
    START_SESSION,
    STOP_SESSION,
    ADD_SESSION_NOTES,
} from '../actions/actionTypes';

export const initialState = {
    whiteLabeling: {
        logo: logo,
        color: cssVariables.primary,
        title: process.env.REACT_APP_TITLE,
    },
    userId: '',
    bystanderToken: '',
    dispatchCenter: '',
    user: '',
    phone: '',
    sessionStart: '',
    sessionEnd: '',
    geoMap: null,
    osName: '',
    osVersion: '',
    browser: '',
    long: '',
    lat: '',
    images: [],
    imageFormat: IMAGE_FORMAT,
    chatHistory: [],
    notes: '',
    logs: [],
    sessionOpen: false,
    downloadable: false,
    language: DEFAULT_LANGUAGE,
    photoPermission: false,
};

/**
 * session reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const logsReducer = (state = initialState, action) => {
    // STARTS WHEN CALLER ACCEPTS INVITATION
    if (action.type === START_SESSION) {
        state = {
            ...state,
            sessionStart: action.start,
            userId: action.userId,
            bystanderToken: action.bystanderToken,
            dispatchCenter: action.dispatchCenter,
            user: decodeURIComponent(action.user),
            phone: action.phone,
            sessionOpen: true,
        };
    }

    if (action.type === ADD_SESSION_LOG) {
        if (state.sessionOpen) {
            if (SESSION_DOWNLOAD_LOG_FILTER.indexOf(action.entry.eventId) === -1) {
                state = { ...state, logs: [...state.logs, action.entry] };
            }
        }
    }

    if (action.type === ADD_SESSION_CHATMESSAGE) {
        state = {
            ...state,
            chatHistory: [...state.chatHistory, action.entry],
        };
    }

    if (action.type === ADD_SESSION_IMAGE) {
        state = { ...state, images: [...state.images, action.image] };
    }

    if (action.type === CHANGE_SESSION_IMAGE_FORMAT) {
        state = { ...state, imageFormat: action.imageFormat };
    }

    if (action.type === ADD_SESSION_MAP) {
        state = { ...state, geoMap: action.map };
    }

    if (action.type === ADD_SESSION_INFOS) {
        state = {
            ...state,
            browser: action.browser,
            osName: action.osName,
            osVersion: action.osVersion,
        };
    }

    if (action.type === ADD_SESSION_GPS) {
        state = {
            ...state,
            long: action.long,
            lat: action.lat,
        };
    }

    if (action.type === ADD_SESSION_LANGUAGE) {
        state = {
            ...state,
            language: action.lang,
        };
    }

    if (action.type === ADD_SESSION_NOTES) {
        state = { ...state, notes: action.notes };
    }

    if (action.type === ADD_SESSION_WHITELABEL_LOGO) {
        state = {
            ...state,
            whiteLabeling: {
                ...state.whiteLabeling,
                logo: action.logo,
            },
        };
    }

    // STOPS WHEN DISPATCHER CLOSES SESSION
    if (action.type === STOP_SESSION) {
        if (state.sessionOpen) {
            state = {
                ...state,
                sessionEnd: action.end,
                sessionOpen: false,
                downloadable: true,
            };
        }
    }

    if (action.type === DISABLE_SESSION_DOWNLOAD) {
        state = {
            ...state,
            downloadable: false,
        };
    }

    if (action.type === ALLOW_PHOTO_PERMISSION) {
        state = {
            ...state,
            photoPermission: true,
        };
    }

    if (action.type === DISALLOW_PHOTO_PERMISSION) {
        state = {
            ...state,
            photoPermission: false,
        };
    }

    if (action.type === RESET_SESSION) {
        state = { ...initialState };
    }

    return state;
};

export default logsReducer;
