import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { store } from '../../store/DispatcherStore';

import ToggleSwitch from '../Globals/ToggleSwitch';
import ConnectionOverlay from '../Globals/ConnectionOverlay';

import './WebRTCSwitcher.scss';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import {
    enableSnapshotDispatch,
    enableVideoDispatch,
    disableSnapshotDispatch,
    activateChatDispatcherDispatch,
    activateVideoDispatcherDispatch,
    deactivateChatDispatcherDispatch,
    deactivateGPSDispatch,
    deactivateSnapshotDispatch,
    deactivateVideoDispatcherDispatch,
    activateSnapshotDispatch,
    activateGPSDispatch,
    disablePointerDispatch,
    deactivatePointerDispatcherDispatch,
    activatePointerDispatcherDispatch,
    enablePointerDispatch,
    deactivateHDSendDispatch,
    activateHDSendDispatch,
    enableDrawDispatch,
    disableDrawDispatch,
    deactivateDrawDispatcherDispatch,
    activateDrawDispatcherDispatch,
    disableChatDispatch,
    enableChatDispatch,
    removeVideoStreamDispatch,
    dispatchCallerFileTransferEnded,
    dispatchCallerFileIsNotBusy,
    deactivateConferencingDispatch,
    activateConferencingDispatch,
    deactivateAudioStreamDispatcherDispatch,
    activateAudioStreamDispatcherDispatch,
    unmuteAudioDispatch,
    muteAudioDispatch,
    deactivateBidiDispatch,
    activateBidiDispatch,
    deactivateBidiBlurDispatch,
    activateBidiBlurDispatch,
    deactivateExternalStreamDispatch,
    activateExternalStreamDispatch,
} from '../../redux/actions/application';

import { C_LOST, DISPLAY_ONLY_IN_SESSION } from '../../config';
import { createKpiLog, replaceText } from '../../helper/helper';
import { dispatchDisallowPaintingDispatcher, dispatchShowScreenshotDialogueThen } from '../../redux/actions/paint';
import { removeStreamContainer } from '../../helper/streamHandling';
import {
    activateScreenSharingDispatcherDispatch,
    deactivateScreenSharingDispatcherDispatch,
    muteMicDispatcherDispatch,
    unmuteMicDispatcherDispatch,
} from '../../redux/actions/conferencing';
import { unpublishStreamAndRemoveFromRedux } from '../../helper/rtcFlowHandling';
import { denyVideoStreamPermissionDispatch, grantVideoStreamPermissionDispatch, resetAudioStreamPermissionDispatch } from '../../redux/actions/permissions';
import { postExternalStream } from '../../api/backendApi';
import { dispatchAddExternalStreamUrl } from '../../redux/actions/stream';

/**
 * WebRTCSwitcher
 * ToggleSwitchers for all functions available.
 * Handles any dependencies on one another.
 *
 * @component ConnectionOverlay - shows waiting for connection until webRTC connection is established
 * @component ToggleSwitch - Simple toggle switch (on/off)
 */

class WebRTCSwitcher extends PureComponent {
    _audioToggleTimeout = 0;
    _bidiToggleTimeout = 0;
    _audioPermissionInterval = 0;

    constructor(props) {
        super(props);
        this.state = {
            deactivateGPSDispatchHasRun: false,
            deactivateVideoDispatchHasRun: false,
            audioStreamLoading: false,
            bidiLoading: false,
        };

        if (store.connectedSession) {
            store.connectedSession.removeListener('contactMessage', this.handleContactMessage);
            store.connectedSession.on('contactMessage', this.handleContactMessage);
        }
    }

    handleContactMessage = e => {
        const message = JSON.parse(e.content);

        if (message && (message.data === 'coordsError' || message.data === 'coordsNotAvail' || message.data === 'coordsPermissionDenied')) {
            if (!this.state.deactivateGPSDispatchHasRun) {
                deactivateGPSDispatch();
                this.setState({
                    deactivateGPSDispatchHasRun: true,
                });
            }
        }
        if (message && message.data === 'coordsError') {
            addNotificationAndShowDispatch('error.gps_err', 'error', DISPLAY_ONLY_IN_SESSION);
        }
        if (message && message.data === 'coordsNotAvail') {
            addNotificationAndShowDispatch('error.gps_nt_avlbl', 'error', DISPLAY_ONLY_IN_SESSION);
        }
        if (message && message.data === 'coordsPermissionDenied') {
            addNotificationAndShowDispatch('error.gps_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
        }
        if (message && message.data === 'deviceInfo' && message.devices.length === 0) {
            addNotificationAndShowDispatch('error.vd_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
            deactivateVideoDispatcherDispatch();
            disablePointerDispatch();
            disableSnapshotDispatch();
            disableDrawDispatch();
        }

        if (message && message.data === 'videoNotAvail') {
            addNotificationAndShowDispatch('error.vd_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
            if (!this.state.deactivateVideoDispatchHasRun) {
                deactivateVideoDispatcherDispatch();
                this.setState({
                    deactivateVideoDispatchHasRun: true,
                });
            }
            removeStreamContainer();
            removeVideoStreamDispatch();
        }

        if (message && message.data === 'deviceInfo' && message.devices.length > 0) {
            enableVideoDispatch();
        }

        if (message && message.data === 'photoPermission' && message.permission === false) {
            deactivateSnapshotDispatch();
        }
    };

    deactivateAllSwitches = () => {
        deactivateVideoDispatcherDispatch();
        deactivateChatDispatcherDispatch();
        deactivateGPSDispatch();
        deactivateSnapshotDispatch();
        deactivatePointerDispatcherDispatch();
    };

    toggleChat = () => {
        const currentActiveState = this.props.chatIsActive;
        currentActiveState ? deactivateChatDispatcherDispatch() : activateChatDispatcherDispatch();
    };
    toggleVideo = () => {
        const currentActiveState = this.props.videoIsActive;
        if (currentActiveState) {
            deactivateVideoDispatcherDispatch();
        } else {
            activateVideoDispatcherDispatch();
            createKpiLog('stateLiveVideoFeature', 'activated');
            this.setState({
                deactivateVideoDispatchHasRun: false,
            });
        }

        if (currentActiveState) {
            deactivateSnapshotDispatch();
            disableSnapshotDispatch();
            deactivatePointerDispatcherDispatch();
            disablePointerDispatch();
            disableDrawDispatch();
            deactivateDrawDispatcherDispatch();
            dispatchDisallowPaintingDispatcher();
            enableChatDispatch();
        } else {
            enableSnapshotDispatch();
            enablePointerDispatch();
            enableDrawDispatch();
        }
    };
    toggleSnapshot = () => {
        const currentActiveState = this.props.snapshotIsActive;
        if (currentActiveState) {
            deactivateSnapshotDispatch();
            deactivateHDSendDispatch();
        } else {
            activateSnapshotDispatch();
        }
    };
    toggleGPS = () => {
        const currentActiveState = this.props.gpsIsActive;
        if (currentActiveState) {
            deactivateGPSDispatch();
        } else {
            activateGPSDispatch();
            this.setState({
                deactivateGPSDispatchHasRun: false,
            });
        }
    };

    togglePointer = () => {
        const currentActiveState = this.props.pointerIsActive;
        currentActiveState ? deactivatePointerDispatcherDispatch() : activatePointerDispatcherDispatch();
    };

    toggleDraw = () => {
        const currentActiveState = this.props.drawIsActive;
        if (!currentActiveState) {
            activateDrawDispatcherDispatch();
        }

        if (currentActiveState) {
            if (this.props.photoPermission && this.props.snapshotFeature && this.props.isPaintingAllowed) {
                dispatchShowScreenshotDialogueThen(deactivateDrawDispatcherDispatch);
            } else {
                deactivateDrawDispatcherDispatch();
            }

            enableChatDispatch();
            if (this.props.wasChatActiveBefore) {
                activateChatDispatcherDispatch();
            }
            enablePointerDispatch();
            enableSnapshotDispatch();
        } else {
            disableChatDispatch();
            deactivateChatDispatcherDispatch();
            disablePointerDispatch();
            disableSnapshotDispatch();
            deactivatePointerDispatcherDispatch();
            deactivateSnapshotDispatch();
            dispatchDisallowPaintingDispatcher();
            dispatchCallerFileTransferEnded();
            dispatchCallerFileIsNotBusy();
        }
    };

    toggleHDSend = () => {
        const currentActiveState = this.props.hdSendIsActive;
        currentActiveState ? deactivateHDSendDispatch() : activateHDSendDispatch();
    };

    toggleAudioStream = () => {
        const currentActiveState = this.props.audioStreamIsActive;
        this.setState({
            audioStreamLoading: true,
        });
        if (currentActiveState) {
            deactivateAudioStreamDispatcherDispatch();
            this.startToggleTimeout('audioStream');
        } else {
            if (this.props.audioStreamPermission === null || this.props.audioStreamPermission === false) {
                // Permission needs to be reset to check if dispatcher has reset browser permissions
                resetAudioStreamPermissionDispatch();
                this._audioPermissionInterval = setInterval(this.checkForAudioPermission, 500);
            } else {
                this.startToggleTimeout('audioStream');
            }
            activateAudioStreamDispatcherDispatch();
        }
    };

    checkForAudioPermission = () => {
        if (this.props.audioStreamPermission === false) {
            if (this.props.audioStreamIsActive) deactivateAudioStreamDispatcherDispatch();
            clearInterval(this._audioPermissionInterval);
            this.startToggleTimeout('audioStream');
        }
        if (this.props.audioStreamPermission === true) {
            clearInterval(this._audioPermissionInterval);
            this.startToggleTimeout('audioStream');
        }
    };

    startToggleTimeout = feature => {
        if (feature === 'audioStream') {
            this._audioToggleTimeout = setTimeout(() => {
                this.setState({
                    audioStreamLoading: false,
                });
            }, 250);
        }
        if (feature === 'bidi') {
            this._bidiToggleTimeout = setTimeout(() => {
                this.setState({
                    bidiLoading: false,
                });
            }, 250);
        }
    };

    toggleConferencing = () => {
        const currentActiveState = this.props.conferencingIsActive;
        if (currentActiveState) {
            store.sendDispatcherLeftToConferenceUsers();
            if (this.props.screenSharingIsActive) deactivateScreenSharingDispatcherDispatch();
            deactivateConferencingDispatch();
            if (!this.props.audioStreamIsActive) {
                if (!this.props.micIsMuted) muteMicDispatcherDispatch();
                if (!this.props.audioIsMuted) muteAudioDispatch();
                unpublishStreamAndRemoveFromRedux(this.props.dispatcherAudioStream, store);
            }
        } else {
            // Notify dispatcher that mic has been unmuted on activation of conferencing
            if (this.props.audioStreamIsActive && this.props.micIsMuted) {
                addNotificationAndShowDispatch('info.mic_unmtd', 'info', DISPLAY_ONLY_IN_SESSION);
            }
            activateConferencingDispatch();
            activateScreenSharingDispatcherDispatch();
            if (this.props.micIsMuted) unmuteMicDispatcherDispatch();
            if (this.props.audioIsMuted) unmuteAudioDispatch();
        }
    };

    toggleBidi = async () => {
        const currentActiveState = this.props.bidiIsActive;
        this.setState({
            bidiLoading: true,
        });
        if (currentActiveState) {
            deactivateBidiDispatch();
            if (this.props.bidiBlurIsActive) deactivateBidiBlurDispatch();
            store.sendBidiIsDeactivated();
            this.startToggleTimeout('bidi');
        } else {
            activateBidiDispatch();
            activateBidiBlurDispatch();
            await store
                .startBidiBlurred()
                .then(() => {
                    // Success
                    if (!this.props.videoStreamPermission) {
                        createKpiLog('permissionBidi', 'granted');
                        grantVideoStreamPermissionDispatch();
                    }
                })
                .catch(err => {
                    addNotificationAndShowDispatch('error.bidiCamera.access', 'error', DISPLAY_ONLY_IN_SESSION);
                    if (this.props.videoStreamPermission === true || this.props.videoStreamPermission === null) {
                        createKpiLog('permissionBidi', 'denied');
                        denyVideoStreamPermissionDispatch();
                    }
                    deactivateBidiDispatch();
                    if (this.props.bidiBlurIsActive) deactivateBidiBlurDispatch();
                });
            if (!this.props.videoStreamPermission) {
                createKpiLog('permissionBidi', 'granted');
                grantVideoStreamPermissionDispatch();
            }
            this.startToggleTimeout('bidi');
        }
    };

    toggleExternalStream = async () => {
        const currentActiveState = this.props.externalStreamIsActive;
        if (currentActiveState) {
            deactivateExternalStreamDispatch();
        } else {
            if (this.props.externalStreamUrl === null) {
                if (await postExternalStream()) {
                    dispatchAddExternalStreamUrl(this.props.bystanderToken);
                }
            }
            activateExternalStreamDispatch();
        }
    };

    componentWillUnmount() {
        this.deactivateAllSwitches();
        clearTimeout(this._audioToggleTimeout);
        clearTimeout(this._bidiToggleTimeout);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.videoIsActive !== this.props.videoIsActive && this.props.videoIsActive) {
            enableSnapshotDispatch();
            enablePointerDispatch();
            enableDrawDispatch();
        } else if (prevProps.videoIsActive !== this.props.videoIsActive && this.props.videoIsActive === false) {
            disableSnapshotDispatch();
            disablePointerDispatch();
            disableDrawDispatch();
        }
    }

    render() {
        const disabledNoConnection = !this.props.isConnected || this.props.connectionStatus === C_LOST;

        return (
            <div className="webRTCSwitcher">
                <ConnectionOverlay force={true} />
                <ToggleSwitch
                    id="toggleLocation"
                    changeHandler={this.toggleGPS}
                    label={replaceText(this.props.texts, 'toggle.gps')}
                    isDisabled={this.props.gpsIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                    isActive={this.props.gpsIsActive}
                />
                <ToggleSwitch
                    id="toggleVideo"
                    changeHandler={this.toggleVideo}
                    label={replaceText(this.props.texts, 'toggle.video')}
                    isDisabled={this.props.videoIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                    isActive={this.props.videoIsActive}
                />
                {(this.props.snapshotFeature || this.props.hdSendFeature) && (
                    <ToggleSwitch
                        id="toggleSnapshot"
                        changeHandler={this.toggleSnapshot}
                        label={replaceText(this.props.texts, 'toggle.snapshot')}
                        isDisabled={this.props.snapshotIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.snapshotIsActive}
                        sub={true}
                        title={this.props.drawIsActive ? replaceText(this.props.texts, 'toggle.disabled.by.draw') : ''}
                    />
                )}
                {this.props.pointerFeature && (
                    <ToggleSwitch
                        id="togglePointer"
                        changeHandler={this.togglePointer}
                        label={replaceText(this.props.texts, 'toggle.pointer')}
                        isDisabled={this.props.pointerIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.pointerIsActive}
                        sub={true}
                        title={this.props.drawIsActive ? replaceText(this.props.texts, 'toggle.disabled.by.draw') : ''}
                    />
                )}
                {this.props.drawFeature && (
                    <ToggleSwitch
                        id="toggleDraw"
                        changeHandler={this.toggleDraw}
                        label={replaceText(this.props.texts, 'toggle.draw')}
                        isDisabled={this.props.drawIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.drawIsActive}
                        sub={true}
                    />
                )}
                {this.props.chatFeature && (
                    <ToggleSwitch
                        id="toggleChat"
                        changeHandler={this.toggleChat}
                        label={replaceText(this.props.texts, 'toggle.chat')}
                        isActive={this.props.chatIsActive}
                        isDisabled={this.props.chatIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                        title={this.props.drawIsActive ? replaceText(this.props.texts, 'toggle.disabled.by.draw') : ''}
                    />
                )}
                {this.props.audioStreamFeature && (
                    <ToggleSwitch
                        id="toggleAudio"
                        changeHandler={this.toggleAudioStream}
                        label={replaceText(this.props.texts, 'toggle.audio')}
                        isActive={this.props.audioStreamIsActive}
                        isDisabled={this.props.audioStreamIsDisabled || disabledNoConnection || this.state.audioStreamLoading || !this.props.callerPageLoaded}
                    />
                )}
                {this.props.bidiFeature && (
                    <ToggleSwitch
                        id="toggleBidi"
                        changeHandler={this.toggleBidi}
                        label={replaceText(this.props.texts, 'toggle.bidi')}
                        isActive={this.props.bidiIsActive}
                        isDisabled={this.props.bidiIsDisabled || disabledNoConnection || this.state.bidiLoading || !this.props.callerPageLoaded}
                    />
                )}
                {this.props.sharingFeature && (
                    <ToggleSwitch
                        id="toggleConferencing"
                        changeHandler={this.toggleConferencing}
                        label={replaceText(this.props.texts, 'toggle.sharing')}
                        isActive={this.props.conferencingIsActive}
                        isDisabled={this.props.conferencingIsDisabled || !this.props.callerPageLoaded || disabledNoConnection}
                    />
                )}
                {this.props.externalStreamingFeature && (
                    <ToggleSwitch
                        id="toggleExternalStream"
                        changeHandler={this.toggleExternalStream}
                        label={replaceText(this.props.texts, 'toggle.externalStream')}
                        isActive={this.props.externalStreamIsActive}
                        isDisabled={!this.props.callerPageLoaded || disabledNoConnection}
                    />
                )}
            </div>
        );
    }
}

// PropTypes for this Component
WebRTCSwitcher.propTypes = {
    videoIsDisabled: PropTypes.bool,
    gpsIsDisabled: PropTypes.bool,
    snapshotIsDisabled: PropTypes.bool,
    videoIsActive: PropTypes.bool,
    gpsIsActive: PropTypes.bool,
    snapshotIsActive: PropTypes.bool,
    chatIsActive: PropTypes.bool,
    pointerIsDisabled: PropTypes.bool,
    pointerIsActive: PropTypes.bool,
    pointerFeature: PropTypes.bool,
    snapshotFeature: PropTypes.bool,
    chatFeature: PropTypes.bool,
    chatIsDisabled: PropTypes.bool,
    hdSendFeature: PropTypes.bool,
    hdSendIsActive: PropTypes.bool,
    drawFeature: PropTypes.bool,
    drawIsActive: PropTypes.bool,
    drawIsDisabled: PropTypes.bool,
    connectionStatus: PropTypes.string,
    hasVideoStream: PropTypes.bool,
    isConnected: PropTypes.bool,
    wasChatActiveBefore: PropTypes.bool,
    photoPermission: PropTypes.bool,
    isPaintingAllowed: PropTypes.bool,
    texts: PropTypes.any,
    sharingFeature: PropTypes.bool,
    conferencingIsDisabled: PropTypes.bool,
    conferencingIsActive: PropTypes.bool,
    audioStreamFeature: PropTypes.bool,
    audioStreamIsDisabled: PropTypes.bool,
    audioStreamIsActive: PropTypes.bool,
    micIsMuted: PropTypes.bool,
    callerPageLoaded: PropTypes.bool,
    dispatcherAudioStream: PropTypes.object,
    bidiFeature: PropTypes.bool,
    bidiIsDisabled: PropTypes.bool,
    bidiIsActive: PropTypes.bool,
    bidiBlurIsActive: PropTypes.bool,
    dispatcherBidiStream: PropTypes.object,
    videoStreamPermission: PropTypes.bool,
    screenSharingIsActive: PropTypes.bool,
    audioIsMuted: PropTypes.bool,
    snapshotDisclaimerAccepted: PropTypes.bool,
    audioStreamPermission: PropTypes.bool,
    externalStreamingFeature: PropTypes.bool,
    externalStreamIsActive: PropTypes.bool,
    externalStreamUrl: PropTypes.string,
    bystanderToken: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        videoIsDisabled: state.application.videoIsDisabled,
        gpsIsDisabled: state.application.gpsIsDisabled,
        snapshotIsDisabled: state.application.snapshotIsDisabled,
        pointerIsDisabled: state.application.pointerIsDisabled,
        videoIsActive: state.application.videoIsActive,
        gpsIsActive: state.application.gpsIsActive,
        snapshotIsActive: state.application.snapshotIsActive,
        chatIsActive: state.application.chatIsActive,
        pointerIsActive: state.application.pointerIsActive,
        pointerFeature: state.features.pointerFeature,
        snapshotFeature: state.features.snapshotFeature,
        hdSendFeature: state.features.hdSendFeature,
        hdSendIsActive: state.application.hdSendIsActive,
        drawFeature: state.features.drawFeature,
        drawIsActive: state.application.drawIsActive,
        drawIsDisabled: state.application.drawIsDisabled,
        chatFeature: state.features.chatFeature,
        chatIsDisabled: state.application.chatIsDisabled,
        connectionStatus: state.connection.status,
        hasVideoStream: state.application.hasVideoStream,
        wasChatActiveBefore: state.application.wasChatActiveBefore,
        isConnected: state.connection.isConnected,
        photoPermission: state.session.photoPermission,
        isPaintingAllowed: state.paint.isPaintingAllowed,
        texts: state.texts.texts,
        sharingFeature: state.features.sharingFeature,
        conferencingIsDisabled: state.application.conferencingIsDisabled,
        conferencingIsActive: state.application.conferencingIsActive,
        audioStreamFeature: state.features.audioStreamFeature,
        audioStreamIsDisabled: state.application.audioStreamIsDisabled,
        audioStreamIsActive: state.application.audioStreamIsActive,
        micIsMuted: state.conferencing.micIsMuted,
        callerPageLoaded: state.application.callerPageLoaded,
        dispatcherAudioStream: state.streams.dispatcherAudioStream,
        bidiFeature: state.features.bidiFeature,
        bidiIsDisabled: state.application.bidiIsDisabled,
        bidiIsActive: state.application.bidiIsActive,
        bidiBlurIsActive: state.application.bidiBlurIsActive,
        dispatcherBidiStream: state.streams.dispatcherBidiStream,
        videoStreamPermission: state.permissions.videoStreamPermission,
        screenSharingIsActive: state.conferencing.screenSharingIsActive,
        audioIsMuted: state.application.audioIsMuted,
        snapshotDisclaimerAccepted: state.disclaimers.snapshotDisclaimerAccepted,
        audioStreamPermission: state.permissions.audioStreamPermission,
        externalStreamingFeature: state.features.externalStreamingFeature,
        externalStreamIsActive: state.application.externalStreamIsActive,
        externalStreamUrl: state.streams.externalStreamUrl,
        bystanderToken: state.session.bystanderToken,
    };
};

export default connect(mapStateToProps)(WebRTCSwitcher);
