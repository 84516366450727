import store from '../store';

export const ADD_CALLER_STREAM = 'ADD_CALLER_STREAM';
export const ADD_CALLER_AUDIO_STREAM = 'ADD_CALLER_AUDIO_STREAM';
export const REMOVE_CALLER_STREAM = 'REMOVE_CALLER_STREAM';
export const REMOVE_CALLER_AUDIO_STREAM = 'REMOVE_CALLER_AUDIO_STREAM';

export const ADD_DISPATCHER_STREAM = 'ADD_DISPATCHER_STREAM';
export const REMOVE_DISPATCHER_STREAM = 'REMOVE_DISPATCHER_STREAM';
export const ADD_DISPATCHER_AUDIO_STREAM = 'ADD_DISPATCHER_AUDIO_STREAM';
export const REMOVE_DISPATCHER_AUDIO_STREAM = 'REMOVE_DISPATCHER_AUDIO_STREAM';
export const ADD_DISPATCHER_BIDI_STREAM = 'ADD_DISPATCHER_BIDI_STREAM';
export const REMOVE_DISPATCHER_BIDI_STREAM = 'REMOVE_DISPATCHER_BIDI_STREAM';

export const ADD_CONFERENCE_USER_STREAM = 'ADD_CONFERENCE_USER_STREAM';
export const REMOVE_CONFERENCE_USER_STREAM = 'REMOVE_CONFERENCE_USER_STREAM';

export const ADD_OWN_STREAM_CONFERENCE_USER = 'ADD_OWN_STREAM_CONFERENCE_USER';
export const REMOVE_OWN_STREAM_CONFERENCE_USER = 'REMOVE_OWN_STREAM_CONFERENCE_USER';

export const ADD_EXTERNAL_STREAM_URL = 'ADD_EXTERNAL_STREAM_URL';
export const REMOVE_EXTERNAL_STREAM_URL = 'REMOVE_EXTERNAL_STREAM_URL';

export const RESET_ALL_STREAMS = 'RESET_ALL_STREAMS';

// CALLER VIDEO
const addCallerStream = stream => {
    return {
        stream,
        type: ADD_CALLER_STREAM,
    };
};

export const dispatchAddCallerStream = stream => {
    store.dispatch(addCallerStream(stream));
};

const removeCallerStream = stream => {
    return {
        stream,
        type: REMOVE_CALLER_STREAM,
    };
};

export const dispatchRemoveCallerStream = stream => {
    store.dispatch(removeCallerStream(stream));
};

// CALLER AUDIO
const addCallerAudioStream = stream => {
    return {
        stream,
        type: ADD_CALLER_AUDIO_STREAM,
    };
};

export const dispatchAddCallerAudioStream = stream => {
    store.dispatch(addCallerAudioStream(stream));
};

const removeCallerAudioStream = stream => {
    return {
        stream,
        type: REMOVE_CALLER_AUDIO_STREAM,
    };
};

export const dispatchRemoveCallerAudioStream = stream => {
    store.dispatch(removeCallerAudioStream(stream));
};

// DISPATCHER VIDEO
const addDispatcherStream = stream => {
    return {
        stream,
        type: ADD_DISPATCHER_STREAM,
    };
};

export const dispatchAddDispatcherStream = stream => {
    store.dispatch(addDispatcherStream(stream));
};

const removeDispatcherStream = () => {
    return {
        type: REMOVE_DISPATCHER_STREAM,
    };
};

export const dispatchRemoveDispatcherStream = () => {
    store.dispatch(removeDispatcherStream());
};

// DISPATCHER BIDI STREAM
const addDispatcherBidiStream = stream => {
    return {
        stream,
        type: ADD_DISPATCHER_BIDI_STREAM,
    };
};

export const dispatchAddDispatcherBidiStream = stream => {
    store.dispatch(addDispatcherBidiStream(stream));
};

const removeDispatcherBidiStream = () => {
    return {
        type: REMOVE_DISPATCHER_BIDI_STREAM,
    };
};

export const dispatchRemoveDispatcherBidiStream = () => {
    store.dispatch(removeDispatcherBidiStream());
};

// DISPATCHER AUDIO
const addDispatcherAudioStream = stream => {
    return {
        stream,
        type: ADD_DISPATCHER_AUDIO_STREAM,
    };
};

export const dispatchAddDispatcherAudioStream = stream => {
    store.dispatch(addDispatcherAudioStream(stream));
};

const removeDispatcherAudioStream = () => {
    return {
        type: REMOVE_DISPATCHER_AUDIO_STREAM,
    };
};

export const dispatchRemoveDispatcherAudioStream = () => {
    store.dispatch(removeDispatcherAudioStream());
};

// CONFERENCE_USER
const addConferenceUserStream = stream => {
    return {
        stream,
        type: ADD_CONFERENCE_USER_STREAM,
    };
};

export const dispatchAddConferenceUserStream = stream => {
    store.dispatch(addConferenceUserStream(stream));
};

const removeConferenceUserStream = streamId => {
    return {
        streamId,
        type: REMOVE_CONFERENCE_USER_STREAM,
    };
};

export const dispatchRemoveConferenceUserStream = streamId => {
    store.dispatch(removeConferenceUserStream(streamId));
};

export const addOwnStreamConferenceUser = stream => {
    return {
        stream,
        type: ADD_OWN_STREAM_CONFERENCE_USER,
    };
};

export const dispatchAddOwnStreamConferenceUser = stream => {
    store.dispatch(addOwnStreamConferenceUser(stream));
};

export const removeOwnStreamConferenceUser = streamId => {
    return {
        streamId,
        type: REMOVE_OWN_STREAM_CONFERENCE_USER,
    };
};

export const dispatchRemoveOwnStreamConferenceUser = streamId => {
    store.dispatch(removeOwnStreamConferenceUser(streamId));
};

const removeAllStreams = () => {
    return {
        type: RESET_ALL_STREAMS,
    };
};

export const removeAllStreamsDispatch = () => {
    store.dispatch(removeAllStreams());
};

// EXTERNAL STREAM

const addExternalStreamUrl = streamUrl => {
    return {
        streamUrl,
        type: ADD_EXTERNAL_STREAM_URL,
    };
};

export const dispatchAddExternalStreamUrl = bystanderToken => {
    let streamUrl = process.env.REACT_APP_EXTERNAL_STREAM_URL + bystanderToken.slice(bystanderToken.length - 3, bystanderToken.length);
    store.dispatch(addExternalStreamUrl(streamUrl));
};

const removeExternalStreamUrl = () => {
    return {
        type: REMOVE_EXTERNAL_STREAM_URL,
    };
};

export const dispatchRemoveExternalStreamUrl = () => {
    store.dispatch(removeExternalStreamUrl());
};
