import { createKpiLog } from '../../helper/helper';
import store from '../store';

export const SET_DATA_DISCLAIMER_ACCEPTED = 'SET_DATA_DISCLAIMER_ACCEPTED';
export const SET_DATA_DISCLAIMER_NOT_ACCEPTED = 'SET_DATA_DISCLAIMER_NOT_ACCEPTED';

export const SET_SNAPSHOT_DISCLAIMER_ACCEPTED = 'SET_SNAPSHOT_DISCLAIMER_ACCEPTED';
export const SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED = 'SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED';

export const SET_HD_PHOTO_DISCLAIMER_CLICKED = 'SET_HD_PHOTO_DISCLAIMER_CLICKED';
export const SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED = 'SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED';

// Dispatcher disclaimers

// DATA DISCLAMIER

const setDataDisclaimerAccepted = () => {
    return {
        type: SET_DATA_DISCLAIMER_ACCEPTED,
    };
};

export const setDataDisclaimerAcceptedDispatch = () => {
    store.dispatch(setDataDisclaimerAccepted());
};

const setDataDisclaimerNotAccepted = () => {
    return {
        type: SET_DATA_DISCLAIMER_NOT_ACCEPTED,
    };
};

export const setDataDisclaimerNotAcceptedDispatch = () => {
    store.dispatch(setDataDisclaimerNotAccepted());
};

// SNAPSHOT DISCLAIMER

const setSnapshotDisclaimerAccepted = () => {
    return {
        type: SET_SNAPSHOT_DISCLAIMER_ACCEPTED,
    };
};

export const setSnapshotDisclaimerAcceptedDispatch = () => {
    store.dispatch(setSnapshotDisclaimerAccepted());
};

const setSnapshotDisclaimerNotAccepted = () => {
    return {
        type: SET_SNAPSHOT_DISCLAIMER_NOT_ACCEPTED,
    };
};

export const setSnapshotDisclaimerNotAcceptedDispatch = () => {
    store.dispatch(setSnapshotDisclaimerNotAccepted());
};

// HD PHOTO DISCLAIMER

const setHdPhotoDisclaimerClicked = () => {
    return {
        type: SET_HD_PHOTO_DISCLAIMER_CLICKED,
    };
};

export const setHdPhotoDisclaimerClickedDispatch = () => {
    createKpiLog('infoHdPhotoDisclaimer', 'clicked');

    store.dispatch(setHdPhotoDisclaimerClicked());
};

const setHdPhotoDisclaimerNotClicked = () => {
    return {
        type: SET_HD_PHOTO_DISCLAIMER_NOT_CLICKED,
    };
};

export const setHdPhotoDisclaimerNotClickedDispatch = () => {
    store.dispatch(setHdPhotoDisclaimerNotClicked());
};
