import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './BidiPanel.scss';
import { connect } from 'react-redux';
import NoVideo from '../Icons/NoVideo';
import Blur from '../Icons/Blur';
import NoBlur from '../Icons/NoBlur';
import { store } from '../../store/DispatcherStore';
import { activateBidiBlurDispatch, deactivateBidiBlurDispatch } from '../../redux/actions/application';

const BidiPanel = ({ bidiIsActive, bidiBlurIsActive }) => {
    const [videoProcessorIsLoading, setVideoProcessorIsLoading] = useState(false);
    let videoProcessorToggleTimeout = 0;
    let containerRef = useRef();

    let classes = `bidi-panel ${bidiIsActive ? 'bidi-panel--active' : ''}`;
    let innerWwrapperClasses = `bidiContainer__inner-wrapper ${videoProcessorIsLoading ? 'blur-transition--isLoading' : ''}`;

    const toggleBlur = async () => {
        if (bidiBlurIsActive) {
            await store.stopBidi();
            await store.startBidi();
            startToggleTimeout();
            deactivateBidiBlurDispatch();
        } else {
            await store.stopBidi();
            await store.startBidiBlurred();
            startToggleTimeout();
            activateBidiBlurDispatch();
        }
    };

    const startToggleTimeout = () => {
        setVideoProcessorIsLoading(true);
        videoProcessorToggleTimeout = setTimeout(() => {
            setVideoProcessorIsLoading(false);
        }, 2000);
    };

    useEffect(() => {
        return () => {
            clearTimeout(videoProcessorToggleTimeout);
        };
    }, [videoProcessorToggleTimeout]);

    return (
        <div className={classes}>
            <div className="bidi-panel__placeholder">
                <NoVideo />
            </div>
            <div className={innerWwrapperClasses}>
                <div ref={containerRef} id="bidiContainer__inner"></div>
                {bidiIsActive && <span onClick={toggleBlur}>{bidiBlurIsActive ? <NoBlur /> : <Blur />}</span>}
            </div>
        </div>
    );
};

// PropTypes for this Component
BidiPanel.propTypes = {
    bidiIsActive: PropTypes.bool,
    bidiBlurIsActive: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        bidiIsActive: state.application.bidiIsActive,
        bidiBlurIsActive: state.application.bidiBlurIsActive,
    };
};

export default connect(mapStateToProps)(BidiPanel);
