import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import clsx from 'clsx';

import { parseTime } from '../../helper/helper';

import './ChatMessage.scss';

/**
 * ChatMessage
 * Displays one chat message with timestamp and translation or original.
 */

class ChatMessage extends PureComponent {
    state = { showTranslated: true };
    toggleTranslated = () => {
        this.setState({ showTranslated: !this.state.showTranslated });
    };
    render() {
        const qrcodeRegex = new RegExp('^rtmp://[^/]*/rtmp/[A-Za-z0-9]{3}$');
        const messageUser = this.props.id.split('_')[0];
        const messageFromCurrentUser = this.props.currentUser === messageUser;
        const classes = clsx('chatMessage', {
            'chatMessage--right': messageFromCurrentUser,
            'chatMessage--isInit': this.props.isInit,
        });
        const classesOriginal = clsx('chatMessage__showOriginal', {
            'chatMessage__showOriginal--strike': !this.state.showTranslated,
        });

        let url = window.location.href;
        url = window.location.href.replace('disptchr', 'caller'); // eslint-disable-line

        return (
            <div className={classes}>
                <div className="chatMessage__meta">
                    <span className="chatMessage__time">{parseTime(this.props.timestamp)}</span>
                </div>
                <div className="chatMessage__message">
                    {qrcodeRegex.test(this.props.originalChatMessage) || this.props.originalChatMessage === url ? (
                        <QRCode value={this.props.originalChatMessage} />
                    ) : this.state.showTranslated ? (
                        this.props.chatMessage
                    ) : (
                        this.props.originalChatMessage
                    )}
                    {!messageFromCurrentUser && this.props.chatMessage !== this.props.originalChatMessage && (
                        <div className={classesOriginal} onClick={this.toggleTranslated}>
                            {this.props.showOriginal}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

// PropTypes for this Component
ChatMessage.propTypes = {
    chatMessage: PropTypes.string,
    originalChatMessage: PropTypes.string,
    timestamp: PropTypes.number,
    id: PropTypes.string,
    currentUser: PropTypes.string,
    showOriginal: PropTypes.string,
    isInit: PropTypes.bool,
};

export default ChatMessage;
