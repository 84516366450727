import store from '../store/index';
import {
    ENABLE_FEATURE_SNAPSHOT,
    ENABLE_FEATURE_GEOSMS,
    ENABLE_FEATURE_POINTER,
    ENABLE_FEATURE_SESSION_DOWNLOAD,
    ENABLE_FEATURE_LOGO_UPLOAD,
    ENABLE_FEATURE_PHONEBOOK,
    ENABLE_FEATURE_SMS_GUIDE,
    ENABLE_FEATURE_HD_SEND,
    ENABLE_FEATURE_DRAW,
    ENABLE_FEATURE_SHARE,
    ENABLE_FEATURE_AUDIO,
    ENABLE_FEATURE_BIDI,
    ENABLE_FEATURE_NOTES,
    ENABLE_FEATURE_EXTERNAL_STREAMING,
} from './actionTypes';

/**
 * All feature actions
 *
 * Actions always return the type
 * Dispatch always dispatches the action
 */

const enableSnapshotFeature = () => {
    return { type: ENABLE_FEATURE_SNAPSHOT };
};

const enableGeoSMSFeature = () => {
    return { type: ENABLE_FEATURE_GEOSMS };
};

const enablePointerFeature = () => {
    return { type: ENABLE_FEATURE_POINTER };
};
const enableSessionDownloadFeature = () => {
    return { type: ENABLE_FEATURE_SESSION_DOWNLOAD };
};

const enableLogoUploadFeature = () => {
    return { type: ENABLE_FEATURE_LOGO_UPLOAD };
};
const enablePhonebookFeature = () => {
    return { type: ENABLE_FEATURE_PHONEBOOK };
};
const enableSMSGuide = () => {
    return { type: ENABLE_FEATURE_SMS_GUIDE };
};
const enableHDSend = () => {
    return { type: ENABLE_FEATURE_HD_SEND };
};
const enableDraw = () => {
    return { type: ENABLE_FEATURE_DRAW };
};
const enableAudio = () => {
    return { type: ENABLE_FEATURE_AUDIO };
};
const enableShare = () => {
    return { type: ENABLE_FEATURE_SHARE };
};
const enableBidi = () => {
    return { type: ENABLE_FEATURE_BIDI };
};
const enableNotes = () => {
    return { type: ENABLE_FEATURE_NOTES };
};
const enableExternalStreaming = () => {
    return { type: ENABLE_FEATURE_EXTERNAL_STREAMING };
};

export const enableFeaturesDispatch = (features = []) => {
    if (features.indexOf('PHOTO') !== -1) {
        store.dispatch(enableSnapshotFeature());
    }
    if (features.indexOf('GEOSMS') !== -1) {
        store.dispatch(enableGeoSMSFeature());
    }
    if (features.indexOf('CURSOR') !== -1) {
        store.dispatch(enablePointerFeature());
    }
    if (features.indexOf('CASE_DOWNLOAD') !== -1) {
        store.dispatch(enableSessionDownloadFeature());
    }
    if (features.indexOf('LOGO_UPLOAD') !== -1) {
        store.dispatch(enableLogoUploadFeature());
    }
    if (features.indexOf('PHONEBOOK') !== -1) {
        store.dispatch(enablePhonebookFeature());
    }
    if (features.indexOf('SMS_GUIDE') !== -1) {
        store.dispatch(enableSMSGuide());
    }
    if (features.indexOf('HD_SEND') !== -1) {
        store.dispatch(enableHDSend());
    }
    if (features.indexOf('DRAW') !== -1) {
        store.dispatch(enableDraw());
    }
    if (features.indexOf('AUDIO_STREAM') !== -1) {
        store.dispatch(enableAudio());
    }
    if (features.indexOf('INVITE') !== -1) {
        store.dispatch(enableShare());
    }
    if (features.indexOf('BIDI') !== -1) {
        store.dispatch(enableBidi());
    }
    if (features.indexOf('NOTES') !== -1) {
        store.dispatch(enableNotes());
    }
    if (features.indexOf('EXTERNAL_STREAMING') !== -1) {
        store.dispatch(enableExternalStreaming());
    }
};
