import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Invitation.scss';
import { createKpiLog, replaceText } from '../../helper/helper';
import { connect } from 'react-redux';
import Loading from '../Icons/LoadingSpinner';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { DISPLAY_ONLY_IN_SESSION } from '../../config';
import Send from '../Icons/Send';
import { Copy } from '../Icons/Copy';
import { getCurrentInviteEmailText, sendInvitation } from '../../api/backendApi';
import { getURLParams } from '../../helper/rtcFlowHandling';

const Invitation = ({ texts, closePanelHandler }) => {
    const [sendTo, setSendTo] = useState('');
    const [emailText, setEmailText] = useState('');
    const [conferenceId, setConferenceId] = useState('');
    const [url, setUrl] = useState(null);
    const [sendButtonIsDisabled, setSendButtonIsDisabled] = useState(false);
    const [linkCopiedSuccessfully, setLinkCopiedSuccessfully] = useState(false);
    const [showEmailSentSuccessfully, setShowEmailSentSuccessfully] = useState(false);
    const [showEmailSendingFailed, setShowEmailSendingFailed] = useState(false);
    const [emailFieldHasError, setEmailFieldHasError] = useState(true);
    const [multipleEmails, setMultipleEmails] = useState([]);
    const [error, setError] = useState(false);

    let classes = `sendEmail`;
    let emailTextClasses = `invitation__addDescriptionField ${error ? 'invitation__addDescriptionField--hasError' : ''}`;

    let sendToRef = useRef();
    let emailTextRef = useRef();
    let showEmailSentTimeout = 0;

    const handleRecipientInput = e => {
        if (e.target.value.includes('@') && e.target.value.includes('.')) {
            setEmailFieldHasError(false);
            if (e.target.value.includes(';') || e.target.value.includes(',')) {
                let emailAddresses = e.target.value.replace(/,/g, ';');

                let updatedAddresses = emailAddresses.split(';');

                setMultipleEmails(updatedAddresses);
            }
        } else {
            setEmailFieldHasError(true);
        }
        setSendTo(e.target.value);
    };

    const handleEmailTextInput = e => {
        setSendTo(sendToRef.current.value);
        setEmailText(emailTextRef.current.value);
    };

    const calculateConferenceId = () => {
        const { callerId, sessionId } = getURLParams();
        const conferenceId = callerId + '/' + sessionId;
        setConferenceId(conferenceId);
    };

    const copyLink = e => {
        e.preventDefault();
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            try {
                navigator.clipboard.writeText(url);
                setLinkCopiedSuccessfully(true);
                setTimeout(() => {
                    setLinkCopiedSuccessfully(false);
                }, 2000);
            } catch {
                addNotificationAndShowDispatch('error.cpy_lnk_err', 'error', DISPLAY_ONLY_IN_SESSION);
            }
        }
    };

    const validateEmailText = () => {
        if (/[/\\:<>]/g.test(emailText)) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const sendEmail = async e => {
        e.preventDefault();

        if (validateEmailText()) {
            return;
        }

        if (!error) {
            let result = null;
            if (multipleEmails.length > 0) {
                let emailRegex = new RegExp('[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.(?:[A-Z]{2})');

                for (const email of multipleEmails) {
                    if (emailRegex.test(email) === false) {
                        setSendButtonIsDisabled(true);

                        result = await sendInvitation({
                            to: email.replace(/ /g, ''),
                            message: emailText,
                            conference: conferenceId,
                        }).catch(err => {
                            console.log(err);
                            setSendButtonIsDisabled(false);
                        });

                        if (result) {
                            const additionalStates = {
                                0: email,
                                1: emailText,
                            };

                            createKpiLog('infoShareInviteSent', '', additionalStates);

                            setSendButtonIsDisabled(false);
                            setShowEmailSentSuccessfully(true);
                            showEmailSentTimeout = setTimeout(() => {
                                setShowEmailSentSuccessfully(false);
                            }, 2000);
                        } else {
                            setShowEmailSendingFailed(true);
                            showEmailSentTimeout = setTimeout(() => {
                                setShowEmailSendingFailed(false);
                            }, 2000);
                        }
                    }
                }
                closePanelHandler();
            } else {
                setSendButtonIsDisabled(true);
                result = await sendInvitation({
                    to: sendTo,
                    message: emailText,
                    conference: conferenceId,
                }).catch(err => {
                    console.log(err);
                    setSendButtonIsDisabled(false);
                });

                if (result) {
                    const additionalStates = {
                        0: sendTo,
                        1: emailText,
                    };

                    createKpiLog('infoShareInviteSent', '', additionalStates);
                    setSendButtonIsDisabled(false);
                    setShowEmailSentSuccessfully(true);
                    showEmailSentTimeout = setTimeout(() => {
                        setShowEmailSentSuccessfully(false);
                        closePanelHandler();
                    }, 2000);
                } else {
                    setShowEmailSendingFailed(true);
                    showEmailSentTimeout = setTimeout(() => {
                        setShowEmailSendingFailed(false);
                    }, 2000);
                }
            }
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(showEmailSentTimeout);
        };
    }, [showEmailSentTimeout]);

    useEffect(() => {
        const getAndSetEmailText = async () => {
            const emailText = await getCurrentInviteEmailText();
            if (emailText) {
                setEmailText(emailText);
            }
        };

        const calculateLinkConference = () => {
            let conferenceUrl = window.location.href.replace('disptchr', 'conference'); // eslint-disable-line
            setUrl(conferenceUrl);
        };

        getAndSetEmailText();
        calculateConferenceId();
        calculateLinkConference();
    }, []);

    return (
        <div className="invitation__container">
            <form onChange={handleEmailTextInput}>
                <div className="invitation__addRecipientField">
                    <span>{replaceText(texts, 'invitation.description')}</span>
                    <div className="addRecipient__container">
                        <input id="phone" type="text" ref={sendToRef} onChange={handleRecipientInput} value={sendTo}></input>
                    </div>
                </div>
                <div className={emailTextClasses}>
                    <textarea
                        placeholder={replaceText(texts, 'invitation.placeholder')}
                        ref={emailTextRef}
                        onChange={() => {}}
                        onKeyUp={validateEmailText}
                        value={emailText}></textarea>
                    {error && <span>{replaceText(texts, 'invitation.email.error')}</span>}
                    <div className="bottom__container">
                        <div className={classes}>
                            {emailFieldHasError || error ? (
                                <button disabled className="btn btn--primary" onClick={sendEmail}>
                                    <Send />
                                    {replaceText(texts, 'invitation.send.button')}
                                </button>
                            ) : !sendButtonIsDisabled ? (
                                <button className="btn btn--primary" onClick={sendEmail}>
                                    <Send />
                                    {replaceText(texts, 'invitation.send.button')}
                                </button>
                            ) : (
                                <div className="sendEmail__textWrapper">
                                    {replaceText(texts, 'invitation.email.info')}
                                    <Loading />
                                </div>
                            )}
                            <p className={`emailSent alert alert--success ${showEmailSentSuccessfully ? '' : 'emailSent--isHidden'}`}>
                                {replaceText(texts, 'email.success.info')}
                            </p>
                            <p className={`emailSent alert alert--error ${showEmailSendingFailed ? '' : 'emailSent--isHidden'}`}>
                                {replaceText(texts, 'email.failed.info')}
                            </p>
                        </div>
                        <div className={classes}>
                            <button className="btn btn--primary" onClick={copyLink}>
                                <Copy />
                                {replaceText(texts, 'invitation.copy.button')}
                            </button>
                        </div>
                        <p className={`copyLink alert alert--success ${linkCopiedSuccessfully ? '' : 'copyLink--isHidden'}`}>
                            {replaceText(texts, 'invitation.copy.info')}
                        </p>
                    </div>
                </div>
            </form>
        </div>
    );
};

// PropTypes for this Component
Invitation.propTypes = {
    texts: PropTypes.any,
    closePanelHandler: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(Invitation);
