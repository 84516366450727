import React from 'react';

export const QrCodeSend = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
            <path d="M3.75 37.5C1.68 37.5 0 35.82 0 33.75V13.12C0 5.89 5.89 0 13.12 0h20.62c2.07 0 3.75 1.68 3.75 3.75S35.81 7.5 33.74 7.5H13.12c-3.1 0-5.62 2.52-5.62 5.62v20.62c0 2.07-1.68 3.75-3.75 3.75ZM116.25 37.5c-2.07 0-3.75-1.68-3.75-3.75V13.12c0-3.1-2.52-5.62-5.62-5.62H86.26c-2.07 0-3.75-1.68-3.75-3.75S84.19 0 86.26 0h20.62C114.12 0 120 5.89 120 13.12v20.62c0 2.07-1.68 3.75-3.75 3.75ZM3.75 82.5C1.68 82.5 0 84.18 0 86.25v20.62c0 7.24 5.89 13.12 13.12 13.12h20.62c2.07 0 3.75-1.68 3.75-3.75s-1.68-3.75-3.75-3.75H13.12c-3.1 0-5.62-2.52-5.62-5.62V86.25c0-2.07-1.68-3.75-3.75-3.75ZM116.25 82.5c-2.07 0-3.75 1.68-3.75 3.75v20.62c0 3.1-2.52 5.62-5.62 5.62H86.26c-2.07 0-3.75 1.68-3.75 3.75s1.68 3.75 3.75 3.75h20.62c7.24 0 13.12-5.89 13.12-13.12V86.25c0-2.07-1.68-3.75-3.75-3.75Z" />
            <path d="M47.27 23c1.03 0 1.88.84 1.88 1.88v22.5c0 1.03-.84 1.88-1.88 1.88h-22.5c-1.03 0-1.88-.84-1.88-1.88v-22.5c0-1.03.84-1.88 1.88-1.88h22.5m0-7.5h-22.5c-5.17 0-9.38 4.21-9.38 9.38v22.5c0 5.17 4.21 9.38 9.38 9.38h22.5c5.17 0 9.38-4.21 9.38-9.38v-22.5c0-5.17-4.21-9.38-9.38-9.38ZM95.23 23c1.03 0 1.88.84 1.88 1.88v22.5c0 1.03-.84 1.88-1.88 1.88h-22.5c-1.03 0-1.88-.84-1.88-1.88v-22.5c0-1.03.84-1.88 1.88-1.88h22.5m0-7.5h-22.5c-5.17 0-9.38 4.21-9.38 9.38v22.5c0 5.17 4.21 9.38 9.38 9.38h22.5c5.17 0 9.38-4.21 9.38-9.38v-22.5c0-5.17-4.21-9.38-9.38-9.38ZM49.14 94.26v.87c0 1.03-.84 1.88-1.88 1.88h-22.5c-1.03 0-1.88-.84-1.88-1.88v-22.5c0-1.03.84-1.88 1.88-1.88h15.88c.14-2.59.59-5.11 1.31-7.5h-17.2c-5.17 0-9.38 4.21-9.38 9.38v22.5c0 5.17 4.21 9.38 9.38 9.38h22.5c3.57 0 6.67-2 8.26-4.94-2.35-1.49-4.5-3.27-6.38-5.3Z" />
            <path d="M72.61 40.5c-14.46 0-26.67 9.59-30.64 22.75-.72 2.39-1.17 4.91-1.31 7.5-.03.58-.05 1.16-.05 1.75 0 8.4 3.24 16.04 8.54 21.75 1.88 2.03 4.03 3.81 6.38 5.3a31.81 31.81 0 0 0 17.08 4.94c17.67 0 32-14.33 32-32s-14.33-32-32-32Zm18.36 22.99L70.03 91.48c-.15.2-.34.37-.56.5-.87.51-1.99.21-2.5-.67l-4.26-7.34c-.46-.8-.26-1.82.47-2.39l20.81-16-24.28 9.99c-.85.36-1.84.03-2.3-.78l-.76-1.33-3.46-6.03a1.818 1.818 0 0 1 .11-1.98c.29-.4.74-.69 1.27-.75l34.71-4.14c.47-.06.95.07 1.33.36.81.61.98 1.77.37 2.58Z" />
        </svg>
    );
};
