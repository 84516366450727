import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { store } from './store/DispatcherStore';
import DispatcherDashboard from './components/Dispatcher/DispatcherDashboard';
import LoginContainer from './components/Login/LoginContainer';
import StartDashboard from './components/New/StartDashboard';
import { changeCSSVariablesTo, getBrowserTitle } from './helper/helper';
import OAuthLogin from './components/OAuthLogin';

import './helper/polyfills.js';
import './App.scss';
import PrivateRoute from './components/Globals/PrivateRoute';
import { API_RTC_TIMEOUT, DEBUG } from './config';
import { errorLog } from './helper/logging';

/**
 * application entry
 * takes care of routing
 * loads external resources from apiRTC
 *
 * @component PrivateRoute - Private Route guard, checking for authentification
 * @component DispatcherDashboard - Dashboard for dispatcher
 * @component StartDashboard - Dashboard to enter the Phonenumber and download session file
 * @component LoginContainer - Redirect component before login
 * @component OAuthLogin - Redirect component after login
 */

class App extends React.Component {
    timeoutTimer = 0;
    loadInterval = 0;

    constructor() {
        super();

        this.state = {
            apiLoaded: false,
        };

        document.title = getBrowserTitle();
        changeCSSVariablesTo();
    }

    resourceNotAvailable = e => {
        errorLog({
            message: 'apiRTC not a available - dispatcher',
            error: e,
            eventId: 'APIRTC_SCRIPT_LOADING_DISPATCHER',
        });
        window.setTimeout(() => {
            window.location.href =
                '/error.html?error=apiRTC script currently not a available';
        }, 100);
    };

    // TIMEOUT for loading, if loading takes too long, show error page
    startTimer = () => {
        this.timeoutTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading apiRTC script');

            this.resourceNotAvailable({ stack: 'TIMEOUT LOADING' });
        }, API_RTC_TIMEOUT);
    };

    // callback after apiRTC script is loaded
    handleScriptLoad = () => {
        clearTimeout(this.timeoutTimer);
        if (DEBUG) console.log('timeout cleared');
        store.apiRTC = apiRTC; // eslint-disable-line
        this.setState({ apiLoaded: true });
    };

    checkLoaded = () => {
        if (window.apiRTC) {
            clearInterval(this.loadInterval);
            this.handleScriptLoad();
        }
    };

    componentDidMount() {
        this.startTimer();
        this.loadInterval = setInterval(this.checkLoaded, 100);
    }

    render() {
        return (
            <Router>
                <PrivateRoute exact path="/" />
                <PrivateRoute
                    path="/disptchr/:id/:session"
                    component={DispatcherDashboard}
                />
                <PrivateRoute
                    exact
                    path="/new"
                    component={StartDashboard}
                    apiLoaded={this.state.apiLoaded}
                />
                <PrivateRoute
                    exact
                    path="/new/:phone"
                    component={StartDashboard}
                    apiLoaded={this.state.apiLoaded}
                />
                <Route
                    path="/login"
                    render={() => (
                        <LoginContainer apiLoaded={this.state.apiLoaded} />
                    )}
                />
                <Route path="/oauth" component={OAuthLogin} />
            </Router>
        );
    }
}

export default App;
