import store from '../store/index';
import { conferenceStore } from '../../store/ConferenceStore';
import { store as dispatcherStore } from '../../store/DispatcherStore';
import { callerStore } from '../../store/CallerStore';
import { createKpiLog, isOnStartPage } from '../../helper/helper';

export const ACTIVATE_CONVERSATION = 'ACTIVATE_CONVERSATION';
export const DEACTIVATE_CONVERSATION = 'DEACTIVATE_CONVERSATION';

export const ACTIVATE_SCREEN_SHARING = 'ACTIVATE_SCREEN_SHARING';
export const DEACTIVATE_SCREEN_SHARING = 'DEACTIVATE_SCREEN_SHARING';

export const ADD_CONVERSATION_NAME = 'ADD_CONFERENCE_NAME';
export const ADD_GOODBYE_TEXT = 'ADD_GOODBYE_TEXT';
export const ADD_GOODBYE_LOGO = 'ADD_GOODBYE_LOGO';

export const ADD_STREAM_INFO = 'ADD_STREAM_INFO';
export const REMOVE_STREAM_INFO = 'REMOVE_STREAM_INFO';

export const CLOSE_CONFERENCE_USERS_PANEL = 'CLOSE_CONFERENCE_USERS_PANEL';
export const OPEN_CONFERENCE_USERS_PANEL = 'OPEN_CONFERENCE_USERS_PANEL';

export const MUTE_MIC = 'MUTE_MIC';
export const UNMUTE_MIC = 'UNMUTE_MIC';

export const SET_IS_DISPATCHER = 'SET_IS_DISPATCHER';
export const SET_LEAVE_CONFERENCE = 'SET_LEAVE_CONFERENCE';

export const RESET_CONFERENCING = 'RESET_CONFERENCING';

/*** Conference actions - DISPATCHER ***/

// MIC - Dispatcher

const muteMicDispatcher = () => {
    return { type: MUTE_MIC };
};

export const muteMicDispatcherDispatch = () => {
    dispatcherStore.muteMic();

    if (!isOnStartPage()) createKpiLog('stateAudioMic', 'muted');

    store.dispatch(muteMicDispatcher());
};

const unmuteMicDispatcher = () => {
    return { type: UNMUTE_MIC };
};

export const unmuteMicDispatcherDispatch = () => {
    dispatcherStore.unmuteMic();

    createKpiLog('stateAudioMic', 'unmuted');

    store.dispatch(unmuteMicDispatcher());
};

// CONVERSATION

const activateConversation = () => {
    return { type: ACTIVATE_CONVERSATION };
};

export const activateConversationDispatcherDispatch = () => {
    conferenceStore.toggleConversationStatus(true);

    // TODO: KPI log goes here

    store.dispatch(activateConversation());
};

const deactivateConversation = () => {
    return { type: DEACTIVATE_CONVERSATION };
};

export const deactivateConversationDispatcherDispatch = () => {
    conferenceStore.toggleConversationStatus(false);

    // TODO: KPI log goes here

    store.dispatch(deactivateConversation());
};

// SCREEN SHARING

// Dispatcher

const activateScreenSharingDispatcher = () => {
    return { type: ACTIVATE_SCREEN_SHARING };
};

export const activateScreenSharingDispatcherDispatch = () => {
    dispatcherStore.startScreenSharing();
    createKpiLog('stateScreenshareFeature', 'activated');

    store.dispatch(activateScreenSharingDispatcher());
};

const deactivateScreenSharingDispatcher = () => {
    return { type: DEACTIVATE_SCREEN_SHARING };
};

export const deactivateScreenSharingDispatcherDispatch = () => {
    dispatcherStore.stopScreenSharing();
    createKpiLog('stateScreenshareFeature', 'deactivated');

    store.dispatch(deactivateScreenSharingDispatcher());
};

// Conference users

const activateScreenSharing = () => {
    return { type: ACTIVATE_SCREEN_SHARING };
};

export const activateScreenSharingDispatch = () => {
    // TODO: KPI log goes here

    store.dispatch(activateScreenSharing());
};

const deactivateScreenSharing = () => {
    return { type: DEACTIVATE_SCREEN_SHARING };
};

export const deactivateScreenSharingDispatch = () => {
    // TODO: KPI log goes here
    store.dispatch(deactivateScreenSharing());
};

const addConversationName = conversationName => {
    return { type: ADD_CONVERSATION_NAME, conversationName };
};

export const addConversationNameDispatcherDispatch = conversationName => {
    store.dispatch(addConversationName(conversationName));
};

export const addConversationNameDispatcherAndSend = conversationName => {
    conferenceStore.sendDispatcherConversationName(conversationName);
    addConversationNameDispatcherDispatch(conversationName);
};

/*** Conference actions - PARTICIPANT ***/

// MIC - Participant

const muteMicContact = () => {
    return { type: MUTE_MIC };
};

export const muteMicContactDispatch = () => {
    conferenceStore.muteMic();

    // TODO: KPI log goes here

    store.dispatch(muteMicContact());
};

const unmuteMicContact = () => {
    return { type: UNMUTE_MIC };
};

export const unmuteMicContactDispatch = () => {
    conferenceStore.unmuteMic();

    // TODO: KPI log goes here

    store.dispatch(unmuteMicContact());
};

// MIC - Caller

const muteMicCaller = () => {
    return { type: MUTE_MIC };
};

export const muteMicCallerDispatch = () => {
    callerStore.muteMic();

    // TODO: KPI log goes here

    store.dispatch(muteMicCaller());
};

const unmuteMicCaller = () => {
    return { type: UNMUTE_MIC };
};

export const unmuteMicCallerDispatch = () => {
    callerStore.unmuteMic();

    // TODO: KPI log goes here

    store.dispatch(unmuteMicCaller());
};

// CONFERENCE USER PANEL

const openConferenceUsersPanel = () => {
    return { type: OPEN_CONFERENCE_USERS_PANEL };
};

export const openConferenceUsersPanelDispatch = () => {
    // TODO: KPI log goes here

    store.dispatch(openConferenceUsersPanel());
};

const closeConferenceUsersPanel = () => {
    return { type: CLOSE_CONFERENCE_USERS_PANEL };
};

export const closeConferenceUsersPanelDispatch = () => {
    // TODO: KPI log goes here

    store.dispatch(closeConferenceUsersPanel());
};

// STREAM INFO

const addStreamInfo = streamInfo => {
    return {
        streamInfo,
        type: ADD_STREAM_INFO,
    };
};

export const dispatchAddStreamInfo = streamInfo => {
    store.dispatch(addStreamInfo(streamInfo));
};

const removeStreamInfo = streamId => {
    return {
        streamId,
        type: REMOVE_STREAM_INFO,
    };
};

export const dispatchRemoveStreamInfo = streamId => {
    store.dispatch(removeStreamInfo(streamId));
};

export const resetConferencing = () => {
    return {
        type: RESET_CONFERENCING,
    };
};

export const resetConferencingDispatch = () => {
    store.dispatch(resetConferencing());
};

export const setIsDispatcher = () => {
    return {
        type: SET_IS_DISPATCHER,
    };
};

export const setIsDispatcherDispatch = () => {
    store.dispatch(setIsDispatcher());
};

const setIsLeavingConference = () => {
    return { type: SET_LEAVE_CONFERENCE };
};

export const setIsLeavingConferenceDispatch = () => {
    // TODO: KPI log goes here

    store.dispatch(setIsLeavingConference());
};

const addConferenceGoodbyeText = goodbyeText => {
    return {
        type: ADD_GOODBYE_TEXT,
        goodbyeText,
    };
};

export const addConferenceGoodbyeTextDispatch = goodbyeText => {
    store.dispatch(addConferenceGoodbyeText(goodbyeText));
};

export const addConferenceGoodbyeLogo = ({ logo }) => {
    return {
        type: ADD_GOODBYE_LOGO,
        logo,
    };
};

export const addConferenceGoodbyeLogoDispatch = ({ logo }) => {
    store.dispatch(addConferenceGoodbyeLogo({ logo }));
};
