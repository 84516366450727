import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { replaceText } from '../../helper/helper';
import { connect } from 'react-redux';
import './NotesPanel.scss';
import { activateNotesDispatch, deactivateNotesDispatch } from '../../redux/actions/application';
import { dispatchAddSessionNotes } from '../../redux/actions/session';

const NotesPanel = ({ texts, notesIsActive, notes, callerPageLoaded }) => {
    const inputRef = useRef(null);
    let inputTimeout = useRef();

    const [input, setNotes] = useState('');

    const updateNotes = () => {
        const notesInputValue = inputRef.current.value;

        if (isNotesValid(notesInputValue)) {
            setNotes(notesInputValue);
        }
    };

    const updateInput = () => {
        setNotes(inputRef.current.value);
    };

    const isNotesValid = input => {
        return input.length >= 0 && input.length > 5000;
    };

    useEffect(() => {
        if (!notesIsActive && input.length > 0) {
            activateNotesDispatch();
        } else if (notesIsActive && input.length === 0) {
            deactivateNotesDispatch();
        }
    });

    useEffect(() => {
        if (notesIsActive && notes !== input) {
            clearTimeout(inputTimeout.current);
            inputTimeout.current = setTimeout(() => {
                dispatchAddSessionNotes(input);
            }, 1000);
        }
    });

    useEffect(() => {
        clearTimeout(inputTimeout.current);
    }, []);

    return (
        <div className="notes-panel">
            <textarea
                id="input"
                type="text"
                ref={inputRef}
                value={input}
                disabled={!callerPageLoaded}
                onChange={updateInput}
                placeholder={replaceText(texts, 'notes.placeholder')}
                onKeyUp={updateNotes}
                maxLength={5000}></textarea>
            <div className="notes-panel__hint-seperator">
                <span>{5000 - input.length} / 5000</span>
            </div>
        </div>
    );
};

// PropTypes for this Component
NotesPanel.propTypes = {
    texts: PropTypes.any,
    notesIsActive: PropTypes.bool,
    notes: PropTypes.string,
    callerPageLoaded: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        notesIsActive: state.application.notesIsActive,
        notes: state.session.notes,
        callerPageLoaded: state.application.callerPageLoaded,
    };
};

export default connect(mapStateToProps)(NotesPanel);
