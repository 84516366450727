import React from 'react';
import PropTypes from 'prop-types';
import './ModalOverlay.scss';
import { replaceText } from '../../helper/helper';
import { connect } from 'react-redux';

const ModalOverlay = ({ modalIsVisible, closeHandler, children, texts }) => {
    let classes = `modalOverlay ${modalIsVisible && 'modalOverlay--isVisible'}`;

    return (
        <div className={classes}>
            <div className="modalOverlay__content">
                <div className="modalOverlay__close" onClick={closeHandler}>
                    {replaceText(texts, 'modal.close')}
                </div>
                <span>{children}</span>
            </div>
        </div>
    );
};

// PropTypes for this Component
ModalOverlay.propTypes = {
    modalIsVisible: PropTypes.bool,
    closeHandler: PropTypes.func,
    children: PropTypes.any,
    texts: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(ModalOverlay);
