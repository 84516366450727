import { PING_INTERVAL, DEBUG, C_LOST_DURATION, C_INIT, DISPATCHER_USER_HANGUP_TIMEOUT, DISPLAY_ONLY_IN_SESSION, RESET_TOGGLE_THRESHOLD } from '../config';
import { errorLog, timeLog } from '../helper/logging';
import { createBystander, getApizeeKey, sendSMSAPI } from '../api/backendApi';
import { addLogDispatch } from '../redux/actions/logs';
import parse from 'platform';
import { conversationErrorHandling, sessionErrorHandling } from '../helper/rtcErrorHandling';
import { connectionEndedDispatch, connectionEstablishedDispatch, connectionLostDispatch } from '../redux/actions/connection';
import reduxStore from '../redux/store/index';
import { addSessionImageDispatch, addSessionInfosDispatch, dispatchAllowPhotoPermisison, dispatchDisallowPhotoPermisison } from '../redux/actions/session';
import { convertBlobToBase64, createErrorLog, createKpiLog, isOnStartPage } from '../helper/helper';
import {
    activateAudioStreamDispatcherDispatch,
    activateChatDispatcherDispatch,
    activateVideoDispatcherDispatch,
    deactivateAudioStreamDispatcherDispatch,
    deactivateCallerChat,
    deactivateDrawDispatcherDispatch,
    deactivatePointerDispatcherDispatch,
    deactivateSnapshotDispatch,
    deactivateVideoDispatcherDispatch,
    disableDrawDispatch,
    disablePointerDispatch,
    disableSnapshotDispatch,
    dispatchCallerFileIsBusy,
    dispatchCallerFileTransferEnded,
    dispatchCallerFileTransferStarted,
    dispatchCallerPageLoaded,
    muteAudioDispatch,
    muteCallerMicrophoneDispatcherDispatch,
    unmuteAudioDispatch,
    unmuteCallerMicrophoneDispatcherDispatch,
} from '../redux/actions/application';
import {
    dispatchAddPointsCaller,
    dispatchAllowPainting,
    dispatchDeletePaintCaller,
    dispatchDisallowPainting,
    dispatchDisallowPaintingDispatcher,
} from '../redux/actions/paint';
import {
    addConversationNameDispatcherDispatch,
    deactivateScreenSharingDispatcherDispatch,
    muteMicDispatcherDispatch,
    unmuteMicDispatcherDispatch,
} from '../redux/actions/conferencing';
import {
    createUserDisplayName,
    dispatcherStreamHandlers,
    enterConversation,
    getURLParams,
    loadEventListenersDispatcher,
    unloadEventListenersDispatcher,
} from '../helper/rtcFlowHandling';
import {
    dispatchAddDispatcherAudioStream,
    dispatchAddDispatcherBidiStream,
    dispatchAddDispatcherStream,
    dispatchRemoveDispatcherAudioStream,
    dispatchRemoveDispatcherBidiStream,
} from '../redux/actions/stream';
import { ONLY_AUDIO, ONLY_BIDI_VIDEO, SCREENSHARING } from '../redux/reducers/streams';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../redux/actions/notifications';
import {
    denyAudioStreamPermissionDispatch,
    denyScreenSharePermissionDispatch,
    grantAudioStreamPermissionDispatch,
    grantScreenSharePermissionDispatch,
} from '../redux/actions/permissions';

/**
 * DispatcherStore
 * contains all the functions needed to setup the dispatcher webrtc connection and communication
 */

class DispatcherStore {
    connected = false;
    connectedSession = null;
    phone = null;
    apiRTC = null;
    sender = null;
    bystanderToken = null;
    messages = [];
    newCallCallbacks = [];
    closeCallCallbacks = [];
    callbackOnIncoming = null;
    callerStream = null;
    call = null;
    userId = null;
    sessionId = null;
    bystanderUrlFragment;
    translationError = false;
    isAndroid = false;
    isFirefox = false;
    isIOS = false;
    osMajorVersion = null;
    heartbeatInterval = null;
    pongMissed = 0;
    pongAccepted = 0;
    type = 'dispatcher';
    token = null;
    failedLogins = 0;

    userAgent = null;
    callerId = null;

    connectedConversation = null;
    conversationName = null;

    ongoingStream = null;
    bidiStream = null;
    blurredStream = null;
    audioStream = null;
    screenSharingStream = null;

    subscribedStreams = {};

    previousCameraId = null;
    streamRetryRequests = 0;

    stoppedViaBrowserButton = false;
    checkIfPageLoadedInterval = null;

    // not tested - external functionality
    /**
     * init the webrtc session event listeners and execute callback on incoming call
     * @param {function} callbackOnIncoming
     */
    initWebRTC = async callbackOnIncoming => {
        await store.createAndJoinConversation();
        callbackOnIncoming();
        this.callbackOnIncoming = callbackOnIncoming;
    };

    // not tested - external functionality
    /**
     * register a new useragent with apiRTC
     * @param {object} token
     * @returns {Promise}
     */
    login = async ({ token }) => {
        const apiKey = await getApizeeKey(store.type);

        store.userAgent = new this.apiRTC.UserAgent({
            uri: 'apzkey:' + apiKey,
        });
        this.token = token;

        return new Promise(function (resolve, reject) {
            store.userAgent
                .register({
                    cloudUrl: 'https://hds.apizee.com',
                    token: token,
                })
                .then(session => {
                    if (DEBUG) addLogDispatch(['user agent session registered']);
                    sessionErrorHandling(session, this);

                    store.connectedSession = session;
                    store.connected = true;
                    store.userId = session.getId();
                    resolve(session.getId());
                    store.setupMessageListener();
                    store.setupFileListener();

                    timeLog('authSession');

                    createKpiLog('infoDispatcherLoginSuccess');
                })
                .catch(error => {
                    if (DEBUG) addLogDispatch(['Registration error', error]);
                    reject(error);

                    store.failedLogins = store.failedLogins + 1;
                    const additionalStates = {
                        0: store.failedLogins,
                    };

                    createErrorLog('infoDispatcherLoginFail', '', '', additionalStates);
                });
        });
    };

    /**
     * Decode token
     */
    getUserFromToken() {
        var base64Url = this.token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        let parsedToken = JSON.parse(jsonPayload);

        return parsedToken.sub;
    }

    /**
     * setup all webrtc contact message eventlisteners
     */
    setupMessageListener() {
        this.connectedSession.removeListener('contactMessage');
        this.connectedSession.on('contactMessage', this.handleContactMessage);
    }

    /**
     * sets up the file invitation listener
     */
    setupFileListener() {
        this.connectedSession.on('fileTransferInvitation', invitation => {
            invitation
                .accept()
                .then(async fileObj => {
                    const base64File = await convertBlobToBase64(fileObj.file);
                    const timestamp = new Date().getTime();
                    this.sendFileTransferEnded();

                    console.log(fileObj);

                    dispatchCallerFileTransferEnded(timestamp, fileObj.type);

                    addSessionImageDispatch({
                        image: base64File,
                        type: fileObj.type,
                        time: timestamp,
                    });
                })
                .catch(function (error) {
                    if (DEBUG) addLogDispatch([`Error receiving file invitation - ${error}`]);

                    errorLog({
                        message: 'HD Send Image error receiving file invitation',
                        error: error,
                        eventId: 'HD_IMAGE_FILE_INVITATION_ERROR',
                    });
                });
        });
    }

    createAndJoinConversation() {
        var conversationOptions = {
            moderationEnabled: true,
            moderator: true,
        };
        var createStreamOptions = {};
        createStreamOptions.constraints = {
            audio: false,
            video: false,
        };

        createUserDisplayName(store, 'dispatcher');

        const { callerId, conversationName } = getURLParams();
        store.callerId = callerId;
        store.conversationName = conversationName;

        enterConversation(store, conversationOptions);
        addConversationNameDispatcherDispatch(conversationName);
        store.connectedConversation.join();
        loadEventListenersDispatcher();
        conversationErrorHandling(store.connectedConversation, store);
    }

    handleSendInvitation = () => {
        var contacts = store.connectedConversation.getContacts();
        var keys = Object.keys(contacts);
        var newContact = null;

        newContact = contacts[keys[keys.length - 1]].getUsername();
        console.log(contacts[keys[keys.length - 1]].getUsername());

        var invitationOptions = {
            expirationTime: 30,
        };

        if (newContact !== null) {
            store.connectedConversation.sendInvitation(newContact, invitationOptions);
        }
    };

    stopScreenSharing = () => {
        if (store.screenSharingStream !== null) {
            store.connectedConversation.unpublish(store.screenSharingStream);
            if (!store.stoppedViaBrowserButton) {
                store.screenSharingStream.release();
            }
            store.screenSharingStream = null;
            store.stoppedViaBrowserButton = false;
            createKpiLog('infoScreenshare', 'unpublished');
        }
    };

    // Share screen in active conversation
    startScreenSharing = () => {
        store.apiRTC.Stream.createDisplayMediaStream(SCREENSHARING, false)
            .then(stream => {
                store.screenSharingStream = stream;
                dispatchAddDispatcherStream(stream);
                store.connectedConversation.publish(stream);
                dispatcherStreamHandlers(stream);
                // For first activation, log permission request
                if (!reduxStore.getState().permissions.screenSharingPermission) {
                    createKpiLog('permissionScreenshare', 'granted');
                    grantScreenSharePermissionDispatch();
                }
                createKpiLog('infoScreenshare', 'published');
            })
            .catch(function (err) {
                addNotificationAndShowDispatch('error.scr_shr_err', 'error', DISPLAY_ONLY_IN_SESSION);
                deactivateScreenSharingDispatcherDispatch();
                // Log permission request denial
                if (reduxStore.getState().permissions.screenSharingPermission || reduxStore.getState().permissions.screenSharingPermission === null) {
                    createKpiLog('permissionScreenshare', 'denied');
                    denyScreenSharePermissionDispatch();
                }
            });
    };

    startBidi = () => {
        const streamOptions = {
            ...ONLY_BIDI_VIDEO,
        };

        return new Promise((resolve, reject) => {
            store.userAgent
                .createStream(streamOptions)
                .then(stream => {
                    setTimeout(() => {
                        // Artificial delay to avoid triggering browser autoplay prevention
                        store.bidiStream = stream;
                        dispatchAddDispatcherBidiStream(stream);
                        stream.removeFromDiv('bidiContainer__inner', 'bidiStream');
                        stream.addInDiv('bidiContainer__inner', 'bidiStream', {}, true);
                        store.connectedConversation.publish(store.bidiStream);
                        return resolve();
                    }, 1000);
                })
                .catch(err => {
                    console.log(err);
                    addNotificationAndShowDispatch(err, 'error', DISPLAY_ONLY_IN_SESSION);
                    return reject();
                });
        });
    };

    startBidiBlurred = () => {
        const streamOptions = {
            ...ONLY_BIDI_VIDEO,
        };

        return new Promise((resolve, reject) => {
            store.userAgent
                .createStream(streamOptions)
                .then(stream => {
                    store.bidiStream = stream;
                    stream
                        .applyVideoProcessor('blur')
                        .then(blurredStream => {
                            // Artificial delay to avoid triggering browser autoplay prevention
                            setTimeout(() => {
                                store.blurredStream = blurredStream;
                                store.bidiStream.removeFromDiv('bidiContainer__inner', 'bidiStream');
                                blurredStream.addInDiv('bidiContainer__inner', 'bidiStream', {}, true);
                                store.connectedConversation.publish(blurredStream);
                                return resolve();
                            }, 1000);
                        })
                        .catch(err => {
                            console.log(err);
                            return reject();
                        });
                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
    };

    stopBidi = () => {
        if (store.bidiStream !== null) {
            store.bidiStream.release();
            store.bidiStream.removeFromDiv('bidiContainer__inner', 'bidiStream');
            store.connectedConversation.unpublish(store.bidiStream);
        }
        store.bidiStream = null;

        if (store.blurredStream !== null) {
            store.blurredStream.release();
            store.blurredStream.removeFromDiv('bidiContainer__inner', 'bidiStream');
            store.connectedConversation.unpublish(store.blurredStream);
        }
        store.blurredStream = null;

        dispatchRemoveDispatcherBidiStream();
    };

    handleDispatcherAudioStream(isActive) {
        // none active
        if (!reduxStore.getState().application.conferencingIsActive && !reduxStore.getState().application.audioStreamIsActive) {
            // activating audio if neither conferencing or audio are activated should publish a new audio stream
            if (isActive) {
                store.createAudioStream();
                if (reduxStore.getState().application.audioIsMuted) unmuteAudioDispatch();
                if (reduxStore.getState().conferencing.micIsMuted) unmuteMicDispatcherDispatch();
            }
        }

        // both active
        if (reduxStore.getState().application.conferencingIsActive && reduxStore.getState().application.audioStreamIsActive) {
            // deactivating audio while conferencing and audio are activated should leave a published audio stream in the conversation
            if (!isActive) {
                // do nothing
                return;
            }
        }

        // only conferencing active
        if (reduxStore.getState().application.conferencingIsActive && !reduxStore.getState().application.audioStreamIsActive) {
            // activating audio while conferencing is activated should do nothing if a published audio stream already exists
            if (isActive) {
                // do nothing unless dispatcher audio stream not active
                if (!reduxStore.getState().streams.dispatcherAudioStream) {
                    store.createAudioStream();
                    if (reduxStore.getState().application.audioIsMuted) unmuteAudioDispatch();
                    if (reduxStore.getState().conferencing.micIsMuted) unmuteMicDispatcherDispatch();
                }
                return;
            }
        }

        // only audio active
        if (!reduxStore.getState().application.conferencingIsActive && reduxStore.getState().application.audioStreamIsActive) {
            // deactivating audio while only audio is activated should unpublish the audio stream from the conversation
            if (!isActive) {
                store.removeAudioStream();
                if (!reduxStore.getState().conferencing.micIsMuted) muteMicDispatcherDispatch();
                if (!reduxStore.getState().application.audioIsMuted) muteAudioDispatch();
            }
        }
    }

    removeAudioStream() {
        if (reduxStore.getState().streams.dispatcherAudioStream) {
            store.connectedConversation.unpublish(reduxStore.getState().streams.dispatcherAudioStream);
            reduxStore.getState().streams.dispatcherAudioStream.release();
            dispatchRemoveDispatcherAudioStream();
            createKpiLog('infoAudioStream', 'unpublished');
        }
    }

    createAudioStream() {
        if (reduxStore.getState().streams.dispatcherAudioStream === null) {
            const streamOptions = { ...ONLY_AUDIO };
            store.userAgent
                .createStream(streamOptions)
                .then(stream => {
                    store.audioStream = stream;
                    store.connectedConversation.publish(stream);
                    dispatchAddDispatcherAudioStream(stream);
                    if (!reduxStore.getState().permissions.audioStreamPermission) {
                        createKpiLog('permissionAudioStream', 'granted');
                        grantAudioStreamPermissionDispatch();
                    }
                    createKpiLog('infoAudioStream', 'published');
                })
                .catch(() => {
                    addNotificationAndShowDispatch('error.mic.acc', 'error', DISPLAY_ONLY_IN_SESSION);
                    if (reduxStore.getState().permissions.audioStreamPermission === true || reduxStore.getState().permissions.audioStreamPermission === null) {
                        createKpiLog('permissionAudioStream', 'denied');
                        denyAudioStreamPermissionDispatch();
                    }
                });
        }
    }

    // Leave active conversation

    leaveConference() {
        if (store.connectedConversation !== null) {
            store.connectedConversation
                .leave()
                .then(() => {
                    if (store.screenSharingStream !== null) {
                        store.connectedConversation.unpublish(store.screenSharingStream);
                        store.screenSharingStream.release();
                    }

                    store.screenSharingStream = null;
                    store.connectedConversation.destroy();
                    unloadEventListenersDispatcher();
                    store.connectedConversation = null;
                })
                .catch(err => {
                    console.error('Conversation leave error', err);
                });
        }

        if (store.bidiStream !== null) {
            store.bidiStream.release();
            store.bidiStream = null;
        }

        store.name = null;
        store.subscribedStreams = {};
    }

    // Renders participants in connectedConversation

    renderUserList() {
        const { callerId } = getURLParams();
        var contacts = store.connectedConversation.getContacts();
        var div = document.getElementById('active-users');
        if (div !== null) {
            div.innerHTML = '';
        }
        var keys = Object.keys(contacts);
        // Reverse array to render list in order of which conference users join
        keys.reverse();
        if (div !== null) {
            for (const element of keys) {
                if (contacts[element].getUsername() !== callerId) {
                    div.innerHTML += '<div>' + contacts[element].getUsername() + '</div>';
                }
            }
        }
    }

    muteMic() {
        // store.connectedConversation.unpublish(store.audioStream);
        if (store.audioStream !== null) {
            store.audioStream.disableAudio();
        }
    }

    unmuteMic() {
        // store.connectedConversation.publish(store.audioStream);
        if (store.audioStream !== null) {
            store.audioStream.enableAudio();
        }
    }

    handleContactMessage = e => {
        //Display message in UI
        const message = JSON.parse(e.content);
        if (message && message.data === 'system') {
            const infos = parse.parse(message.browserVersion1b);
            this.isAndroid = infos.os.toString().toLowerCase().indexOf('android') !== -1;
            this.isIOS = infos.os.toString().toLowerCase().indexOf('ios') !== -1;
            this.osMajorVersion = parseInt(infos.os.version.split('.')[0], 10);
            this.isFirefox = infos.name.toString().toLowerCase().indexOf('firefox') !== -1;

            addSessionInfosDispatch({
                osName: infos.os.family,
                osVersion: infos.os.version,
                browser: `${infos.name} - ${infos.version}`,
            });

            if (DEBUG) addLogDispatch([`OS Infos - android: ${this.isAndroid} - iOS: ${this.isIOS} - version: ${this.osMajorVersion}`]);
        }
        if (message && message.data === 'heartbeat - pong') {
            this.pongMissed = 0;
            this.pongAccepted += 1;
        }

        if (message && message.data === 'hdFileCallerIsBusy') {
            dispatchCallerFileIsBusy();
        }

        if (message && message.data === 'hdFileTransferStarted') {
            dispatchCallerFileTransferStarted();
        }

        if (message && message.data === 'callerPaintPoints') {
            dispatchAddPointsCaller(message.points);
        }

        if (message && message.data === 'allowPainting') {
            message.state ? dispatchAllowPainting() : dispatchDisallowPainting();
        }

        if (message && message.data === 'deletePaintPointsCaller') {
            dispatchDeletePaintCaller();
        }

        if (message && message.data === 'photoPermission') {
            message.permission ? dispatchAllowPhotoPermisison() : dispatchDisallowPhotoPermisison();
        }

        // Retry stream capture if promise doesn't resolve on caller side
        if (message && message.data === 'requestStreamRetry') {
            if (store.streamRetryRequests < 1) {
                setTimeout(() => {
                    activateVideoDispatcherDispatch();
                }, 2000);
                store.streamRetryRequests = 1;
            } else if (store.streamRetryRequests === 1) {
                addNotificationAndShowDispatch('Problem streaming selected camera id. Please select a different id', 'error', DISPLAY_ONLY_IN_SESSION); // TODO: Language texts
            }
        }

        // If different camera id selected, reset stream retries
        if (message && message.data === 'checkIfStreamResolved') {
            if (store.streamRetryRequests === 1 && this.previousCameraId !== message.id) {
                store.streamRetryRequests = 0;
                this.previousCameraId = message.id;
            } else {
                this.previousCameraId = message.id;
            }
        }

        if (message && message.data === 'callerMutedMic') {
            muteCallerMicrophoneDispatcherDispatch();
        }

        if (message && message.data === 'callerUnmutedMic') {
            unmuteCallerMicrophoneDispatcherDispatch();
        }

        if (message && message.data === 'callerLoadedPage') {
            if (
                reduxStore.getState().application.videoIsActive ||
                reduxStore.getState().application.gpsIsActive ||
                reduxStore.getState().application.chatIsActive ||
                reduxStore.getState().application.audioStreamIsActive
            ) {
                // addNotificationAndShowDispatch('Caller refreshed browser tab', 'info', DISPLAY_ONLY_IN_SESSION); // TODO translate properly
            }
            if (reduxStore.getState().application.videoIsActive) {
                deactivateVideoDispatcherDispatch();
                deactivateSnapshotDispatch();
                disableSnapshotDispatch();
                deactivatePointerDispatcherDispatch();
                disablePointerDispatch();
                disableDrawDispatch();
                deactivateDrawDispatcherDispatch();
                dispatchDisallowPaintingDispatcher();
                addNotificationAndShowDispatch('VIDEO STREAM LOST', 'info'); // TODO translate properly
            }
            if (reduxStore.getState().application.chatIsActive) {
                // deactivateChatDispatcherDispatch();
                activateChatDispatcherDispatch();
            }
            if (reduxStore.getState().application.audioStreamIsActive) {
                activateAudioStreamDispatcherDispatch();
                addNotificationAndShowDispatch('info.aud_lst', 'info', DISPLAY_ONLY_IN_SESSION);
            }

            if (!isOnStartPage()) {
                dispatchCallerPageLoaded();
                const confirmationMessage = {
                    data: 'receivedCallerPageLoaded',
                };

                this.sendMessage(confirmationMessage);
            }
        }

        if (message && message.data === 'pageNotInFocus') {
            // console.log('pageNotInFocus received');
            // if (reduxStore.getState().session.osName === 'Android') {
            //     addNotificationAndShowDispatch('info.tab_foc_lst', 'error', DISPLAY_ONLY_IN_SESSION);
            //     connectionUnstableDispatch();
            //     dispatchCallerPageNotLoaded();
            //     if (reduxStore.getState().application.audioStreamIsActive) {
            //         deactivateAudioStreamDispatcherDispatch();
            //     }
            // }
        }

        if (message && message.data === 'pageInFocus') {
            // console.log('pageInFocus received');
            // if (reduxStore.getState().session.osName === 'Android') {
            //     addNotificationAndShowDispatch('info.tab_foc_rgd', 'info', DISPLAY_ONLY_IN_SESSION);
            //     connectionStableDispatch();
            //     dispatchCallerPageLoaded();
            // }
        }

        if (message && message.data === 'joinRequestMessage') {
            if (reduxStore.getState().application.conferencingIsActive) {
                const responseMessage = {
                    data: 'joinRequestIsGranted',
                };

                this.sendMessageToAllConferenceUsers(responseMessage);
                if (reduxStore.getState().conferencing.screenSharingIsActive) this.sendScreenSharingToggled(true);
            } else {
                const responseMessage = {
                    data: 'joinRequestIsDeclined',
                };

                this.sendMessageToAllConferenceUsers(responseMessage);
            }
        }
    };

    // not tested - external functionality
    /**
     * create a bystander user
     * @param {string} phoneNumber
     */
    async createBystander(phoneNumber) {
        store.bystanderToken = await createBystander(phoneNumber).then(result => {
            if (result) return result.token;
        });
        return store.bystanderToken;
    }

    // not tested - external functionality
    /**
     * set a bystander token
     * @param {string} token
     */
    setBystanderToken(token) {
        store.bystanderToken = token;
        return store.bystanderToken;
    }

    //
    establishHeartbeat() {
        clearInterval(this.heartbeatInterval);
        this.pongMissed = 0;
        this.pongAccepted = 0;

        this.heartbeatInterval = setInterval(() => {
            this.pongMissed += 1; // starts at 1

            this.sendPing();
            this.handlePong();
        }, PING_INTERVAL);
    }

    sendPing() {
        const message = {
            data: 'heartbeat - ping',
        };

        this.sendMessage(message, true);
    }

    handlePong() {
        const missedThreshold = (C_LOST_DURATION + PING_INTERVAL) / PING_INTERVAL; // 4
        const acceptedThreshold = C_LOST_DURATION / PING_INTERVAL; // 3
        const resetToggleThreshold = (RESET_TOGGLE_THRESHOLD + PING_INTERVAL) / PING_INTERVAL; // 10
        const connectionStore = reduxStore.getState().connection;

        if (this.pongMissed >= missedThreshold) {
            this.pongAccepted = 0;
            if (connectionStore.isConnected && connectionStore.status === C_INIT) {
                connectionLostDispatch();
                createKpiLog('infoHeartbeatLost');
            }
        }

        if (this.pongMissed >= resetToggleThreshold) {
            if (reduxStore.getState().application.audioStreamIsActive) deactivateAudioStreamDispatcherDispatch();
            if (reduxStore.getState().application.chatIsActive) deactivateCallerChat();
            if (reduxStore.getState().application.videoIsActive) deactivateVideoDispatcherDispatch();
            if (reduxStore.getState().application.snapshotIsActive) deactivateSnapshotDispatch();
            if (!reduxStore.getState().application.snapshotIsDisabled) disableSnapshotDispatch();
            if (reduxStore.getState().application.pointerIsActive) deactivatePointerDispatcherDispatch();
            if (!reduxStore.getState().application.pointerIsDisabled) disablePointerDispatch();
            if (!reduxStore.getState().application.drawIsDisabled) disableDrawDispatch();
            if (reduxStore.getState().application.drawIsActive) deactivateDrawDispatcherDispatch();
            if (reduxStore.getState().paint.isPaintingAllowed) dispatchDisallowPaintingDispatcher();
        }

        if (this.pongAccepted >= acceptedThreshold) {
            if (!reduxStore.getState().connection.isConnected) {
                connectionEstablishedDispatch();
                createKpiLog('infoHeartbeatRegained');

                if (reduxStore.getState().notifications.currentNotifications.length > 0) {
                    reduxStore.getState().notifications.currentNotifications.forEach(notification => {
                        if (notification.message === 'error.lg_evnt') {
                            hideAndRemoveNotificationsDispatch('error');
                        }
                    });
                }
            }
        }
    }

    // not tested - external functionality
    /**
     * send a sms
     * @param {boolean} dryRun
     */
    async sendSMS(dryRun = false, isResendSMS = false) {
        const data = {
            token: this.bystanderToken,
            bystanderId: this.userId,
            phoneNumber: this.phone,
            dryRun: dryRun,
            isResendSMS: isResendSMS,
        };

        return await sendSMSAPI(data);
    }

    // not tested - external functionality
    /**
     * send a message via webrtc
     * @param {object} message2Send
     * @param {boolean} ping - is heartbeat ping
     */
    sendMessage(message2Send, ping = false) {
        const message = JSON.stringify(message2Send);
        let intervalCount = 5;
        let interval;

        const send = () => {
            if (DEBUG) addLogDispatch(['senderObject', { ...this.sender }]);
            this.sender
                .sendMessage(message)
                .then(function () {
                    if (DEBUG) addLogDispatch(['message send', message]);
                })
                .catch(err => {
                    if (DEBUG) addLogDispatch(['message send error', message, err]);
                    if (!ping) {
                        errorLog({
                            message: `Error sending message via rtc - dispatcher - ${message}`,
                            error: err,
                            eventId: 'MESSAGE_SEND',
                        });
                    }
                });
        };

        if (this.sender) {
            send();
            return;
        }

        interval = window.setInterval(() => {
            if (DEBUG) addLogDispatch(['sendMessageInterval']);
            if (this.sender) {
                send();
                clearInterval(interval);
            } else {
                if (intervalCount > 0) {
                    intervalCount -= 1;
                } else {
                    clearInterval(interval);
                    if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                }
            }
        }, 200);
    }

    sendMessageToConferenceUser(message2Send, conferenceUser) {
        const message = JSON.stringify(message2Send);
        let intervalCount = 5;
        let interval;

        const send = () => {
            if (DEBUG) addLogDispatch(['senderObject', { ...conferenceUser }]);
            conferenceUser
                .sendMessage(message)
                .then(function () {
                    if (DEBUG) addLogDispatch(['message send', message]);
                })
                .catch(err => {
                    if (DEBUG) addLogDispatch(['message send error', message, err]);
                    // if (!ping) {
                    //     errorLog({
                    //         message: `Error sending message via rtc - dispatcher - ${message}`,
                    //         error: err,
                    //         eventId: 'MESSAGE_SEND',
                    //     });
                    // }
                });
        };

        if (conferenceUser) {
            send();
            return;
        }

        interval = window.setInterval(() => {
            if (DEBUG) addLogDispatch(['sendMessageInterval']);
            if (this.sender) {
                send();
                clearInterval(interval);
            } else {
                if (intervalCount > 0) {
                    intervalCount -= 1;
                } else {
                    clearInterval(interval);
                    if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                }
            }
        }, 200);
    }

    sendMessageToAllConferenceUsers(message2Send) {
        const { callerId } = getURLParams();
        const message = JSON.stringify(message2Send);
        let intervalCount = 5;
        let interval;
        let contacts = store.connectedConversation.getContacts();
        let keys = Object.keys(contacts);

        const send = () => {
            for (const element of keys) {
                if (contacts[element].userData.username !== callerId) {
                    if (DEBUG) addLogDispatch(['senderObject', { ...contacts[element] }]);
                    contacts[element]
                        .sendMessage(message)
                        .then(function () {
                            if (DEBUG) addLogDispatch(['message send', message]);
                        })
                        .catch(err => {
                            if (DEBUG) addLogDispatch(['message send error', message, err]);
                        });
                }
            }
        };

        if (contacts) {
            send();
            return;
        }

        interval = window.setInterval(() => {
            if (DEBUG) addLogDispatch(['sendMessageInterval']);
            if (this.sender) {
                send();
                clearInterval(interval);
            } else {
                if (intervalCount > 0) {
                    intervalCount -= 1;
                } else {
                    clearInterval(interval);
                    if (DEBUG) addLogDispatch(['message could not be send -> no sender']);
                }
            }
        }, 200);
    }

    // tested
    /**
     * add a new callback to call accepted event
     * @param {function} callback
     */
    addNewCallCallback(callback) {
        this.newCallCallbacks.push(callback);
    }

    // tested
    /**
     * add a new callback to call ended event
     * @param {function} callback
     */
    addCloseCallCallback(callback) {
        this.closeCallCallbacks.push(callback);
    }

    // tested
    /**
     * clear all call callbacks
     */
    clearCallCallbacks() {
        this.newCallCallbacks = [];
        this.closeCallCallbacks = [];
    }

    // tested
    /**
     * logout from current session
     */
    logout() {
        this.closeSession();
        if (this.connectedSession) {
            this.connectedSession.disconnect();
            this.connectedSession = null;
        }
        this.connected = false;
    }

    // not tested - trivial and extarnal
    /**
     * logout after unload event
     */
    unloadHandler() {
        this.connectedSession.disconnect();
        this.connectedSession = null;

        // For conference
        // store.connectedSession.disconnect();
        // store.connectedSession = null;
    }

    async sendDispatcherLeftToCaller() {
        const message = {
            data: 'dispatcherLeft',
        };

        this.sendMessage(message, true);
    }

    sendDispatcherLeftToConferenceUsers() {
        const message = {
            data: 'dispatcherLeft',
        };

        this.sendMessageToAllConferenceUsers(message);
    }

    async toggleMicrophone(activeState) {
        const message = {
            data: 'toggleMicrophone',
            state: activeState,
        };

        this.sendMessage(message);
    }

    // tested
    /**
     * close the session
     */
    async closeSession() {
        await this.sendDispatcherLeftToCaller();

        this.closeCallCallbacks.forEach(currentCloseCallCallback => {
            if (typeof currentCloseCallCallback == 'function') {
                currentCloseCallCallback();
            }
        });

        setTimeout(() => {
            if (this.connectedConversation) {
                this.connectedConversation.leave();
                this.handleDispatcherAudioStream(false);
            }
            this.phone = null;
            this.sender = null;
            this.sessionId = null;
            this.bystanderToken = null;
            clearInterval(this.heartbeatInterval);
            connectionEndedDispatch();
        }, DISPATCHER_USER_HANGUP_TIMEOUT);
    }

    // tested
    /**
     * init a new session
     * @param {string} phone
     */
    initSession(phone) {
        this.phone = phone;
        this.sessionId = Math.random().toString().substring(2);
    }

    // tested
    /**
     * toggle gps
     * @param {boolean} activeState
     */
    toggleGPS(activeState) {
        const message = {
            data: 'toggleGPS',
            state: activeState,
        };
        this.sendMessage(message);
    }

    // tested
    /**
     * toggle video
     * @param {boolean} activeState
     */
    toggleVideo(activeState) {
        const message = {
            data: 'toggleVideo',
            state: activeState,
            id: reduxStore.getState().application.deviceId,
        };

        this.sendMessage(message);
    }

    // tested
    /**
     * toggle chat
     * @param {boolean} activeState
     */
    toggleChat(activeState) {
        const message = {
            data: 'toggleChat',
            state: activeState,
        };
        this.sendMessage(message);
    }

    /**
     * toggle caller audio
     * @param {boolean} isActive
     */
    toggleAudioStream(isActive) {
        this.handleDispatcherAudioStream(isActive);

        const message = {
            data: 'toggleAudioStream',
            state: isActive,
        };
        this.sendMessage(message);
    }

    // tested
    /**
     * toggle snapshot
     * @param {boolean} activeState
     */
    toggleSnapshot(activeState) {
        const message = {
            data: 'toggleSnapshot',
            state: activeState,
        };
        this.sendMessage(message);
    }

    // not tested
    /**
     * toggle pointer
     * @param {boolean} activeState
     */
    togglePointer(activeState) {
        const message = {
            data: 'togglePointer',
            state: activeState,
        };
        this.sendMessage(message);
    }

    // not tested
    /**
     * send pointer position
     * @param {object} position
     */
    sendPointerPosition(position) {
        const message = {
            data: 'pointerPosition',
            state: position,
        };
        this.sendMessage(message);
    }

    // not tested
    /**
     * toggle hdsend
     * @param {boolean} activeState
     */
    toggleHDSend(activeState) {
        const message = {
            data: 'toggleHDSend',
            state: activeState,
        };
        this.sendMessage(message);
    }

    // not tested
    /**
     * toggle hdsend
     * @param {boolean} activeState
     */
    sendScreenSharingToggled(activeState) {
        const message = {
            data: 'screenSharingToggled',
            state: activeState,
        };

        this.sendMessageToAllConferenceUsers(message);
    }

    sendMuteAllMicrophones() {
        const message = {
            data: 'toggleMicrophone',
        };

        this.sendMessageToAllConferenceUsers(message);
    }

    sendMuteMicrophone(conferenceUser) {
        const message = {
            data: 'toggleMicrophone',
        };

        this.sendMessageToConferenceUser(message, conferenceUser);
    }

    sendBidiIsDeactivated() {
        const message = {
            data: 'bidiIsDeactivated',
        };

        this.sendMessage(message);
    }

    // not tested
    /**
     * send transfer ended message
     */
    sendFileTransferEnded() {
        const message = {
            data: 'hdFileTransferEnded',
        };
        this.sendMessage(message);
    }

    /**
     * sends the last new points
     * @param {string} points
     */
    sendDispatcherPaintPoints(points) {
        const message = {
            data: 'dispatcherPaintPoints',
            points,
        };
        this.sendMessage(message);
    }

    /**
     * sends the undo message for the last painted points
     */
    sendUndoPaintPoints() {
        const message = {
            data: 'undoLastPaintPoints',
        };
        this.sendMessage(message);
    }

    /**
     * sends the delete message for all painted points
     */
    sendDeletePaintPoints() {
        const message = {
            data: 'deleteAllPaintPoints',
        };
        this.sendMessage(message);
    }

    /**
     * toggle draw
     * @param {boolean} activeState
     */
    toggleDraw(activeState) {
        const message = {
            data: 'toggleDraw',
            state: activeState,
        };
        this.sendMessage(message);
    }

    /**
     * allowPainting
     * @param {boolean} activeState
     */
    sendAllowPainting(activeState) {
        const message = {
            data: 'allowPainting',
            state: activeState,
        };
        this.sendMessage(message);
    }

    // TODO: Create toggle state for screen sharing
    // to be tested...
    /**
     * toggle conferencing
     * @param {boolean} activeState
     */
    toggleConferencing(activeState) {
        const message = {
            data: 'toggleConferencing',
            state: activeState,
        };
        this.sendMessage(message);
    }
}

export let store = new DispatcherStore();
