import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';
import { hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';

import { ERRORS_VISIBILITY, INFO_VISIBILITY, TIMESTAMP_OPTIONS, WARNING_VISIBILITY } from '../../config';
import { replaceText } from '../../helper/helper';

import './NotificationDisplay.scss';
import {
    // setDataDisclaimerAcceptedDispatch,
    setHdPhotoDisclaimerClickedDispatch,
    // setSnapshotDisclaimerAcceptedDispatch,
} from '../../redux/actions/disclaimers';

/**
 * Shows all error messages passed to the redux error store
 */

class NotificationDisplay extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentNotification: null,
        };
    }
    _timer = { info: null, error: null };

    handleNotificationUpdates(type, previousNotifications, currentNotifications, duration) {
        const previous = previousNotifications.filter(notification => notification.type === type);
        const current = currentNotifications.filter(notification => notification.type === type);

        if (JSON.stringify(previous) !== JSON.stringify(current) && current.length > 0) {
            clearTimeout(this._timer[type]);
            this._timer[type] = setTimeout(() => {
                hideAndRemoveNotificationsDispatch(type);
            }, duration);
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.currentNotifications) !== JSON.stringify(this.props.currentNotifications)) {
            this.handleNotificationUpdates('info', prevProps.currentNotifications, this.props.currentNotifications, INFO_VISIBILITY);
            this.handleNotificationUpdates('warning', prevProps.currentNotifications, this.props.currentNotifications, WARNING_VISIBILITY);
            this.handleNotificationUpdates('error', prevProps.currentNotifications, this.props.currentNotifications, ERRORS_VISIBILITY);
        }
    }

    render() {
        const infos = this.props.currentNotifications.filter(notification => notification.type === 'info');
        const warnings = this.props.currentNotifications.filter(notification => notification.type === 'warning');
        const errors = this.props.currentNotifications.filter(notification => notification.type === 'error');

        const classes = clsx('notificationDisplay', {
            'notificationDisplay--active': this.props.showInfos || this.props.showErrors || this.props.showWarnings,
        });

        return (
            <div className={classes}>
                <Notifications texts={this.props.texts} notifications={errors} type="error" />
                <Notifications texts={this.props.texts} notifications={infos} type="hint" />
                <Notifications texts={this.props.texts} notifications={warnings} type="warning" />
            </div>
        );
    }
}

// PropTypes for this Component
NotificationDisplay.propTypes = {
    currentNotifications: PropTypes.arrayOf(PropTypes.any),
    showErrors: PropTypes.bool,
    showInfos: PropTypes.bool,
    showWarnings: PropTypes.bool,
    texts: PropTypes.any,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        currentNotifications: state.notifications.currentNotifications,
        showErrors: state.notifications.showErrors,
        showInfos: state.notifications.showInfos,
        showWarnings: state.notifications.showWarnings,
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(NotificationDisplay);

const Notifications = ({ notifications, type, texts }) => {
    const classes = clsx(`notificationDisplay__wrapper alert alert--${type}`, {
        'notificationDisplay__wrapper--inactive': notifications.length === 0,
    });
    return (
        <div className={classes}>
            <div className="notificationDisplay__messages">
                {notifications.map(notification => {
                    return (
                        <p key={notification.id}>
                            {type !== 'warning' && <span>{new Date(notification.timestamp).toLocaleDateString(navigator.language, TIMESTAMP_OPTIONS)}</span>}
                            <span>{replaceText(texts, notification.message)}</span>
                        </p>
                    );
                })}
            </div>
            <div
                className="notificationDisplay__close"
                onClick={() => {
                    hideAndRemoveNotificationsDispatch(type);
                    notifications.map(notification => {
                        if (notification.message === 'warning.hdphoto.notice') {
                            setHdPhotoDisclaimerClickedDispatch();
                        }
                        return null;
                    });
                }}></div>
        </div>
    );
};

Notifications.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.any),
    type: PropTypes.string,
    texts: PropTypes.any,
};
