import React from 'react';

export default function Blur() {
    const theme = require('../../themes/' + process.env.REACT_APP_THEME);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="23.719">
            <defs>
                <clipPath id="a">
                    <path data-name="Path 3089" d="M0 0h33v-23.719H0Z" fill="none" />
                </clipPath>
            </defs>
            <g data-name="Group 5003">
                <g data-name="Group 5002" clipPath="url(#a)" transform="translate(0 23.719)">
                    <g data-name="Group 4991">
                        <path
                            data-name="Path 3078"
                            d="m1.085-14.82 7.9-7.9"
                            fill="none"
                            stroke={theme.cssVariables.primary}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 4992">
                        <path
                            data-name="Path 3079"
                            d="m1.335-2.82 19.9-19.9"
                            fill="none"
                            stroke={theme.cssVariables.primary}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 4993">
                        <path
                            data-name="Path 3080"
                            d="m11.966-1 19.592-19.592"
                            fill="none"
                            stroke={theme.cssVariables.primary}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 4994">
                        <path
                            data-name="Path 3081"
                            d="m1-8.61 14.109-14.109"
                            fill="none"
                            stroke={theme.cssVariables.primary}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 4995">
                        <path
                            data-name="Path 3082"
                            d="M5.841-1 27.56-22.719"
                            fill="none"
                            stroke={theme.cssVariables.primary}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 4996">
                        <path
                            data-name="Path 3083"
                            d="m18.091-1 13.82-13.82"
                            fill="none"
                            stroke={theme.cssVariables.primary}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 4997">
                        <path
                            data-name="Path 3084"
                            d="m24.216-1 7.65-7.65"
                            fill="none"
                            stroke={theme.cssVariables.primary}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 4998">
                        <path
                            data-name="Path 3085"
                            d="M16.463-11.254a3.82 3.82 0 0 0 3.82-3.82 3.821 3.821 0 0 0-3.82-3.821 3.821 3.821 0 0 0-3.82 3.821 3.82 3.82 0 0 0 3.82 3.82"
                            fill={theme.cssVariables.primary}
                        />
                    </g>
                    <g data-name="Group 4999">
                        <path
                            data-name="Path 3086"
                            d="M16.463-11.254a3.82 3.82 0 0 0 3.82-3.82 3.821 3.821 0 0 0-3.82-3.821 3.821 3.821 0 0 0-3.82 3.821 3.82 3.82 0 0 0 3.82 3.82Z"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="bevel"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 5000">
                        <path
                            data-name="Path 3087"
                            d="M22.752-1.416v-5.847a3.766 3.766 0 0 0-3.774-3.753h-5.032a3.766 3.766 0 0 0-3.774 3.753v5.847a.418.418 0 0 0 .418.418"
                            fill={theme.cssVariables.primary}
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="bevel"
                            strokeWidth="2"
                        />
                    </g>
                    <g data-name="Group 5001">
                        <path
                            data-name="Path 3088"
                            d="M4.1-22.719h24.8a3.1 3.1 0 0 1 3.1 3.1v15.516a3.1 3.1 0 0 1-3.1 3.1H4.1a3.1 3.1 0 0 1-3.1-3.1v-15.516a3.1 3.1 0 0 1 3.1-3.1Z"
                            fill="none"
                            stroke={theme.cssVariables.primary}
                            strokeWidth="2"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
