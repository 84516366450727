import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { store as dispatcherStore } from '../../store/DispatcherStore';
import './ResendSMS.scss';
import { replaceText } from '../../helper/helper';
import { RESENDSMS_BUTTON_TIMEOUT } from '../../config';
import Loading from '../Icons/LoadingSpinner';

export const ResendSMSComponent = ({ texts, isHidden }) => {
    const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);
    let classes = `resend-sms ${isHidden && 'resend-sms__isHidden'}`;

    const resendSMS = () => {
        setResendButtonIsDisabled(true);
        setTimeout(() => {
            setResendButtonIsDisabled(false);
        }, RESENDSMS_BUTTON_TIMEOUT);
        dispatcherStore.sendSMS(false, true);
    };

    return (
        <div className={classes}>
            {!resendButtonIsDisabled ? (
                <button className="btn btn--primary" onClick={resendSMS}>
                    <span>{replaceText(texts, 'resendsms.button')}</span>
                </button>
            ) : (
                <div className="resend-sms__text-wrapper">
                    <span>{replaceText(texts, 'resendsms.sent')}</span>
                    <Loading />
                </div>
            )}
        </div>
    );
};

// PropTypes for this Component
ResendSMSComponent.propTypes = {
    texts: PropTypes.object,
    isHidden: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const ResendSMS = connect(mapStateToProps)(ResendSMSComponent);
