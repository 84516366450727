import packageInformation from '../package.json';

// version
export const CURRENT_VERSION = packageInformation.version;
// language - dispatcher
export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE;
// language - caller
export const DEFAULT_LANGUAGE_CALLER = 'en';

// HELPDESK
export const CUSTOM_HELPDESK = process.env.REACT_APP_CUSTOM_HELPDESK;

export const HELPDESK_LINK = 'https://helpdesk.emergencyeye.de';

// helpdesk os links - map useragent strings to helpdesk os
export const HELPDESK_OS = {
    ios: ['ipad', 'iphone', 'ios', 'ipados'],
    android: ['android'],
};
// helpdesk version links for each os
export const HELPDESK_OS_VERSIONS = {
    android: ['6', '7', '7-1', '8', '8-1', '9', '10', '11'],
    ios: ['12-2', '12-3', '12-4', '13-0', '13-4', '13-5', '14'],
};
// helpdesk supported browser
export const HELPDESK_BROWSER = ['firefox', 'chrome', 'samsung', 'safari', 'dolphin'];

// Chat text blocks
export const CHAT_TEXT_BLOCKS_DEFAULTS = {
    de: [
        'Wo sind Sie?',
        'Bitte bestätigen Sie den Zugriff auf Kamera und Ortung!',
        'Bitte filmen Sie den Ort!',
        'Was ist passiert?',
        'Ist das ein medizinischer Notfall?',
        'Gibt es Verletzte?',
        'Gibt es jemand der Ihnen helfen kann?',
        'Hilfe ist unterwegs!',
    ],
    fr: [
        'Où êtes-vous?',
        'Veuillez confirmer l’accès à la caméra et à la localisation.',
        'Filmez le lieu s’il vous plaît.',
        'Qu’est-ce qui s’est passé?',
        'Est-ce que c’est une urgence médicale?',
        'Est-ce qu’il y a des blessés?',
        'Est-ce qu’il y a quelqu’un qui peut vous aider?',
        'Secours en route.',
    ],
    en: [
        'Where are you?',
        'Please confirm the access to the camera and location!',
        'Please film the place',
        'What happened?',
        'Is it a medical emergency?',
        'Is anyone hurt?',
        'Is there anyone who can help you?',
        'Help is on the way!',
    ],
    it: [
        'Dove sono loro?',
        "Conferma l'accesso alla videocamera e alla posizione!",
        'Per favore filma il posto!',
        'Quello che è successo?',
        "È un'emergenza medica?",
        "C'è qualcuno che si sente male?",
        "C'è qualcuno che può aiutarti?",
        "L'aiuto è in arrivo!",
    ],
};
export const CALLER_LANGUAGES = [
    'de',
    'en',
    'fr',
    'es',
    'ru',
    'nl',
    'lv',
    'he',
    'uk',
    'ar',
    'tr',
    'it',
    'pl',
    'pt',
    'bg',
    'cs',
    'hu',
    'ja',
    'ro',
    'sl',
    'sk',
    'sv',
    'zh',
    'hr',
    'sr',
    'da',
];

export const CHAT_LANGUAGES = {
    de: [
        'ar',
        'bg',
        'zh',
        'da',
        'de',
        'en',
        'fr',
        'he',
        'nl',
        'it',
        'ja',
        'hr',
        'lv',
        'pl',
        'pt',
        'ro',
        'ru',
        'sv',
        'sr',
        'sk',
        'sl',
        'es',
        'cs',
        'tr',
        'uk',
        'hu',
    ],
    fr: ['de', 'en', 'bg', 'zh', 'da', 'es', 'fr', 'hu', 'it', 'ja', 'nl', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'sv', 'cs'],
    it: ['bg', 'cs', 'zh', 'da', 'fr', 'ja', 'en', 'it', 'nl', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'es', 'sv', 'de', 'hu'],
    en: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'fr', 'de', 'hu', 'it', 'ja', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv'],
};

export const SUPPORTED_LANGUAGES = ['de', 'fr', 'it', 'en'];

// camera label keywords
export const FRONT_CAMERA_DETECTION_KEYWORDS = [
    'front',
    'vorne',
    'voor',
    'avant',
    'posteriore',
    'frontale',
    'frontal',
    'priekšējā',
    'przednia',
    'Фронтальная',
    'أمامية',
    'Фронтальна',
    'ön',
    'קדמית',
];
export const BACK_CAMERA_DETECTION_KEYWORDS = [
    'back',
    'rear',
    'rück',
    'achter',
    'rug',
    'arrière',
    'anteriore',
    'trasera',
    'traseira',
    'aizmugurējā',
    'tylna',
    'Задняя',
    'تحريك',
    'повернення',
    'taşıma',
    'הפוכה',
];

// translation
export const TRANSLATION_TIME_WARNING = 10000;

// info
export const INFO_VISIBILITY = 5000;

// warning
export const WARNING_VISIBILITY = 3600000;

// errors
export const ERRORS_VISIBILITY = 20000;

// polling
export const POLLING_CHAT_INTERVAL = 2000;
export const POLLING_CHAT_RETRY = 30000;
export const POLLING_INTERVAL = 1000;
export const POLLING_RETRY = 30000;
export const HEALTH_POLLING_INTERVAL = 60000;
export const HEALTH_POLLING_RETRY = 300000;
export const SYSTEM_MESSAGES_POLLING_INTERVAL = 60000;
export const SYSTEM_MESSAGES_POLLING_RETRY = 300000;
export const VERSION_POLLING_INTERVAL = 60000;
export const VERSION_POLLING_RETRY = 300000;

// session end caller timeout
export const SESSION_END_TIMEOUT = 30000;

// enable DEBUG logs
export const DEBUG = (function () {
    let debug = process.env.REACT_APP_DEBUG === 'true' ? true : false;

    return debug;
})();

// timestamp defaults - creates dd.mm.yyyy hh:mm:ss
export const TIMESTAMP_OPTIONS = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
};

// gps
export const GPS_INTERVAL = 1000;

// camera
export const DEVICE_INFO_INTERVAL = 1000;

// battery
export const BATTERY_INFO_INTERVAL = 10000;

// reload
export const RELOAD_TIMER_IN_S = 60;

// token expire
export const TOKEN_EXPIRE_INTERVAL = 3600000; // each hour
export const TOKEN_EXPIRE_REFRESH_IN_S = 43200; // before 12 hours

// apiRTC script timeout
export const API_RTC_TIMEOUT = 10000;
export const API_RTC_TIMEOUT_CALLER = 30000;

// caller cookie expire
export const CALLER_COOKIE_EXPIRE = 1 / 8; // 8th fraction of 1 day = 3 hours

// pointer blinking
export const POINTER_BLINKING_DURATION = 4000;

// connection states
export const C_WAIT = 'connection_waiting';
export const C_INIT = 'connection_established';
export const C_LOST = 'connection_lost';

// heartbeat
export const PING_INTERVAL = 1000;
export const C_LOST_DURATION = 3000;
export const RESET_TOGGLE_THRESHOLD = 19000;
export const CALLER_THRESHOLD = 30000;

// disconnect
export const DISCONNECT_RETRIES_THRESHOLD = 5;

// geoSMS
export const GEOSMS_SUCCESS_TIMEOUT = 1500;

// session download timer
export const SESSION_DOWNLOAD_TIMEOUT = 1000 * 60 * 3; // 3 minutes
export const SESSION_DOWNLOAD_FILENAME = 'session';
export const SESSION_DOWNLOAD_LOG_FILTER = ['INFO_GPS_COORDS'];

// hangup timeout
export const DISPATCHER_USER_HANGUP_TIMEOUT = 100;

// country detection

export const PHONE_COUNTRIES = [
    {
        prefix: '001',
        country: 'country.us',
    },
    {
        prefix: '0030',
        country: 'country.gr',
    },
    {
        prefix: '0031',
        country: 'country.nl',
    },
    {
        prefix: '0032',
        country: 'country.be',
    },
    {
        prefix: '0033',
        country: 'country.fr',
    },
    {
        prefix: '0034',
        country: 'country.es',
    },
    {
        prefix: '0039',
        country: 'country.it',
    },
    {
        prefix: '00358',
        country: 'country.fi',
    },
    {
        prefix: '0041',
        country: 'country.ch',
    },
    {
        prefix: '0043',
        country: 'country.at',
    },
    {
        prefix: '0044',
        country: 'country.en',
    },
    {
        prefix: '0045',
        country: 'country.dk',
    },
    {
        prefix: '0046',
        country: 'country.se',
    },
    {
        prefix: '0047',
        country: 'country.no',
    },
    {
        prefix: '0048',
        country: 'country.pl',
    },
    {
        prefix: '0049',
        country: 'country.de',
    },
    {
        prefix: '007',
        country: 'country.ru',
    },
    {
        prefix: '0081',
        country: 'country.jp',
    },
    {
        prefix: '0086',
        country: 'country.cn',
    },
    {
        prefix: '0090',
        country: 'country.tk',
    },
    {
        prefix: '00972',
        country: 'country.il',
    },
    {
        prefix: '00385',
        country: 'country.hr',
    },
    {
        prefix: '00420',
        country: 'country.cz',
    },
    {
        prefix: '00353',
        country: 'country.ie',
    },
    {
        prefix: '00380',
        country: 'country.uk',
    },
    {
        prefix: '0040',
        country: 'country.ro',
    },
    {
        prefix: '0036',
        country: 'country.hu',
    },
    {
        prefix: '00352',
        country: 'country.lx',
    },
    {
        prefix: '00359',
        country: 'country.bg',
    },
    {
        prefix: '00381',
        country: 'country.sr',
    },
    {
        prefix: '00386',
        country: 'country.si',
    },
    {
        prefix: '00370',
        country: 'country.lt',
    },
    {
        prefix: '00421',
        country: 'country.sk',
    },
    {
        prefix: '00216',
        country: 'country.tn',
    },
    {
        prefix: '00971',
        country: 'country.ae',
    },
    {
        prefix: '00966',
        country: 'country.sa',
    },
    {
        prefix: '00965',
        country: 'country.kw',
    },
    {
        prefix: '00963',
        country: 'country.sy',
    },
    {
        prefix: '00212',
        country: 'country.ma',
    },
    {
        prefix: '0061',
        country: 'country.au',
    },
    {
        prefix: '0066',
        country: 'country.th',
    },
    {
        prefix: '00357',
        country: 'country.cy',
    },
    {
        prefix: '0027',
        country: 'country.za',
    },
    {
        prefix: '0091',

        country: 'country.in',
    },

    {
        prefix: '0055',

        country: 'country.br',
    },

    {
        prefix: '00234',

        country: 'country.ng',
    },
];

// WMS Map Layer
export const WMS_TILES = process.env.REACT_APP_WMS_TILES_URL;

// HD Image
export const IMAGE_TYPE_ENDINGS = {
    'image/jpg': 'jpeg',
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'image/heif': 'heif',
    'image/heic': 'heic',
    'image/aae': 'aae',
};

export const IMAGE_TARGET = {
    png: 'png',
    jpg: 'jpeg',
    jpeg: 'jpeg',
};

export const MAX_IMAGE_FILE_SIZE = 20971520; // 20 MB in byte (binary);

// snapshot filename
export const IMAGE_NAME = process.env.REACT_APP_IMAGE_NAME;

// snapshot format
export const IMAGE_FORMAT = 'PNG';

// busy caller hint
export const FILE_SELECT_BUSY_CALLER_HINT = 600000;

// orientation chat scrolling
export const ORIENTATION_SCROLLING_TIMEOUT = 250;

// photo limit
export const PHOTO_LIMIT = 50;

// deactivate resend SMS button delay (in milliseconds)
export const RESENDSMS_BUTTON_TIMEOUT = 6000;

// image conversion
export const ALLOW_IMAGE_CONVERSION = false;

// session connected display setting
export const DISPLAY_ONLY_IN_SESSION = true;

// session inactivity timeout before caller joins (in seconds)
export const TIME_UNTIL_INACTIVITY_ALERT = 180;

// country code used for placeholder texts
export const PLACEHOLDER_COUNTY_CODE = process.env.REACT_APP_PLACEHOLDER_COUNTRY_CODE || '49';
