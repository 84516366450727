import React from 'react';

export const Copy = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path
                data-name="Path 3083"
                d="M4 1.6h8V12a.8.8 0 0 0 .8.8.8.8 0 0 0 .8-.8V1.6A1.6 1.6 0 0 0 12 0H4a.8.8 0 0 0-.8.8.8.8 0 0 0 .8.8ZM0 4.8v9.6A1.6 1.6 0 0 0 1.6 16h7.2a1.6 1.6 0 0 0 1.6-1.6V4.8a1.6 1.6 0 0 0-1.6-1.6H1.6A1.6 1.6 0 0 0 0 4.8Z"
            />
        </svg>
    );
};
